(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PlanoDestaqueDetailController', PlanoDestaqueDetailController);

    PlanoDestaqueDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PlanoDestaque', '$sce'];

    function PlanoDestaqueDetailController($scope, $rootScope, $stateParams, previousState, entity, PlanoDestaque, $sce) {
        var vm = this;

        vm.planoDestaque = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:planoDestaqueUpdate', function(event, result) {
            vm.planoDestaque = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
