(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ValorLoteEnvioMensagemUsuarioDetailController', ValorLoteEnvioMensagemUsuarioDetailController);

    ValorLoteEnvioMensagemUsuarioDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ValorLoteEnvioMensagemUsuario', '$sce'];

    function ValorLoteEnvioMensagemUsuarioDetailController($scope, $rootScope, $stateParams, previousState, entity, ValorLoteEnvioMensagemUsuario, $sce) {
        var vm = this;

        vm.valorLoteEnvioMensagemUsuario = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:valorLoteEnvioMensagemUsuarioUpdate', function(event, result) {
            vm.valorLoteEnvioMensagemUsuario = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
