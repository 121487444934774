(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilEditStatusController', PerfilEditStatusController);

    PerfilEditStatusController.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Pessoa', 'TipoStatus', '$uibModalInstance'];

    function PerfilEditStatusController($rootScope, $scope, Principal, $state, Pessoa, TipoStatus, $uibModalInstance) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.arquivo = {};

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });


        getAccount();
        loadStatus();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
            };

        }

        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
        }


        function save() {
            vm.isSaving = true;
            Pessoa.updatePerfil(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.isEditing = false;
            $uibModalInstance.close(result);
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
