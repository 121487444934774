(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ArquivoController', ArquivoController);

    ArquivoController.$inject = ['$scope', '$sce', 'Principal', 'Pessoa', '$state', 'entity', 'Upload', '$window', '$uibModalInstance'];

    function ArquivoController($scope, $sce, Principal, Pessoa, $state, entity, Upload, $window, $uibModalInstance) {
        var vm = this;
        vm.arquivo = entity;

        vm.cancel = cancel;
        vm.baixarAnexo = baixarAnexo;


        function cancel() {
            $uibModalInstance.dismiss('close');
        }

        function carregarFromBase64() {
            if (vm.arquivo.tipo === 'LINK_S3') {
                vm.fileURL = vm.arquivo.bytes;
            } else {
                var byteCharacters = atob(vm.arquivo.bytes);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {type: vm.arquivo.contentType});
                vm.file = blob
                vm.fileURL = window.URL.createObjectURL(vm.file);
            }
            vm.conteudo = $sce.trustAsResourceUrl(vm.fileURL);
        }

        carregarFromBase64();

        function baixarAnexo() {
            var fileName = vm.arquivo.nome;

            console.log("vai baixar o doc ", vm.fileURL)


            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            a.href = vm.fileURL;
            a.download = fileName;
            a.click();


        }


    }
})();
