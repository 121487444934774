(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('FormacaoAcademica', FormacaoAcademica);

    FormacaoAcademica.$inject = ['$resource', 'DateUtils'];

    function FormacaoAcademica($resource, DateUtils) {
        var resourceUrl = 'api/formacao-academicas/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getAllByUserId': {url:'api/formacao-academicas-by-user/:id' ,method: 'GET', isArray: true},
            'getTipoFormacao': {
                url: 'api/tipo-formacao/',
                method: 'GET', isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.inicio = DateUtils.convertLocalDateFromServer(data.inicio);
                        data.fim = DateUtils.convertLocalDateFromServer(data.fim);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.inicio = DateUtils.convertLocalDateToServer(data.inicio_tela);
                    data.fim = DateUtils.convertLocalDateToServer(data.fim_tela);
                    return angular.toJson(data);
                }
            },
            'addAudio': {
                url:'api/formacao-academica-audio',
                method: 'PUT',
                transformRequest: function (data) {
                    data.inicio = DateUtils.convertLocalDateToServer(data.inicio_tela);
                    data.fim = DateUtils.convertLocalDateToServer(data.fim_tela);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.inicio = DateUtils.convertLocalDateToServer(data.inicio_tela);
                    data.fim = DateUtils.convertLocalDateToServer(data.fim_tela);
                    return angular.toJson(data);
                }
            },
            'saveComAnexo': {
                url: "api/formacao-academica-anexo",
                method: 'POST',
                transformRequest: function (data) {
                    data.formacaoAcademica.inicio = DateUtils.convertLocalDateToServer(data.formacaoAcademica.inicio_tela);
                    data.formacaoAcademica.fim = DateUtils.convertLocalDateToServer(data.formacaoAcademica.fim_tela);
                    return angular.toJson(data);
                }
            },
            'updateComAnexo': {
                url: "api/formacao-academica-anexo",
                method: 'PUT',
                transformRequest: function (data) {
                    data.formacaoAcademica.inicio = DateUtils.convertLocalDateToServer(data.formacaoAcademica.inicio_tela);
                    data.formacaoAcademica.fim = DateUtils.convertLocalDateToServer(data.formacaoAcademica.fim_tela);
                    return angular.toJson(data);
                }
            },
        });
    }
})();
