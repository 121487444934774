(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VendaController', VendaController);

    VendaController.$inject = ['$scope', '$state', '$filter', 'Venda', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService'];

    function VendaController($scope, $state, $filter, Venda, ParseLinks,
                             AlertService, pagingParams, paginationConstants, ConfirmService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.pagar = pagar;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function pagar(venda) {
            ConfirmService.swal({
                    title: "Atenção",
                    text: "Você tem certeza que deseja realizar o pagamento manual?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#1acfb0",
                    confirmButtonText: "Pagar o valor de " + $filter('currency')(venda.valorTotal),
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Venda.pagar({id: venda.id}, loadAll);
                    }
                })

        }

        vm.lineOptions = {
            scaleShowGridLines: true,
            scaleGridLineColor: "rgba(0,0,0,.05)",
            scaleGridLineWidth: 1,
            bezierCurve: true,
            bezierCurveTension: 0.4,
            pointDot: true,
            pointDotRadius: 4,
            pointDotStrokeWidth: 1,
            pointHitDetectionRadius: 20,
            datasetStroke: true,
            datasetStrokeWidth: 2,
            datasetFill: true
        };



        function getUltimasVendas() {
            Venda.getUltimasVendas({}, function (data) {
                vm.ultimasVendas = data;
                vm.periodos = [];
                vm.valores = [[],[]];
                angular.forEach(vm.ultimasVendas, function (venda) {
                    vm.periodos.push(venda.mes + '/' + venda.ano);
                    vm.valores[0].push(venda.valor);
                });

            })
        }

        getUltimasVendas();

        function loadAll() {

            Venda.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                filtro: vm.searchQuery ? vm.searchQuery : ""
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'criadoEm') {
                    result.push('criadoEm,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vendas = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
