(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('BuscarPessoaSimplesController', BuscarPessoaSimplesController);

    BuscarPessoaSimplesController.$inject = ['$timeout', '$scope', '$stateParams', 'pagingParams', 'Empregador', 'ParseLinks', 'ConfirmService',
        'AtributoGenerico', 'FormacaoAcademica', 'TipoStatus', '$uibModal', 'Pessoa', '$location'];

    function BuscarPessoaSimplesController($timeout, $scope, $stateParams, pagingParams, Empregador, ParseLinks, ConfirmService,
        AtributoGenerico, FormacaoAcademica, TipoStatus, $uibModal, Pessoa, $location) {
        var vm = this;
        vm.page = 1;
        vm.allItemsPerPage = [10, 25, 50, 100, 250, 500];
        vm.itemsPerPage = vm.allItemsPerPage[0];

        vm.pessoasSelecionadas = [];

        vm.transition = transition;
        vm.verPerfilPremium = verPerfilPremium;
        vm.calculateAge = calculateAge;
        vm.consultarAvaliacoes = consultarAvaliacoes;
        vm.carregarPessoas = carregarPessoas;
        vm.limparFiltros = limparFiltros;
        vm.adicionarOrdem = adicionarOrdem;
        vm.limparOrdem = limparOrdem;
        vm.verPareceres = verPareceres;
        vm.imprimir = imprimir;

        vm.selecionarPessoa = selecionarPessoa;
        vm.avancarVarios = avancarVarios;
        vm.voltarVarios = voltarVarios;
        vm.selecionarSituacao = selecionarSituacao;
        vm.getCompetenciasAvaliadas = carregarCompetenciasAvaliadas;
        vm.adicionarRemoverCompetencia = adicionarRemoverCompetencia;
        vm.adicionarRemoverTodasCompetencia = adicionarRemoverTodasCompetencia;
        vm.getValorCompetenciaPessoa = getValorCompetenciaPessoa;
        vm.filtrarCompetencias = pagingParams.filtrarCompetencias && pagingParams.filtrarCompetencias === 'true';

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        function imprimir(pessoa) {
            Pessoa.imprimirSimples(pessoa.pessoaId);
        }

        vm.ordens = [
            {descricao: 'Nome', value: 'nome'},
            {
                descricao: 'Cidade',
                value: 'premium'
            },
            {descricao: 'Ultima Alteracao', value: '-atualizadoEm'},
            {descricao: 'Disponibilidade', value: 'situacao'},
            {descricao: 'Idade', value: '-nascimento'}];

        vm.order = ['premium', '-atualizadoEm'];

        function adicionarOrdem(ordem) {
            if (vm.order.indexOf(ordem.value) >= 0) {
                vm.order.splice(vm.order.indexOf(ordem.value), 1);
            } else {
                vm.order.push(ordem.value);
            }
        }

        function getValorCompetenciaPessoa(pessoa, competencia) {
            for (var i = 0; i < pessoa.resultadoCompetencias.length; i++) {
                var comp = pessoa.resultadoCompetencias[i];
                if (comp.id == competencia) {
                    return comp;
                }
            }
            return {};
        }

        function adicionarRemoverTodasCompetencia(agrupador) {
            agrupador.selecionada = !agrupador.selecionada;

            for (var i = 0; i < agrupador.competencias.length; i++) {
                var compentenciasAvaliada = agrupador.competencias[i];
                adicionarRemoverCompetencia(compentenciasAvaliada);
            }
            carregarPessoas();
        }

        function adicionarRemoverCompetencia(competencia) {
            competencia.selecionada = !competencia.selecionada;
            if (vm.filtros.competencias.indexOf(competencia.id) >= 0) {
                vm.filtros.competencias.splice(vm.filtros.competencias.indexOf(competencia.id), 1);
            } else {
                vm.filtros.competencias.push(competencia.id);
            }
        }

        function limparOrdem() {
            vm.order = [];
        }

        function limparFiltros() {
            vm.filtros = {
                apenasInteressados: pagingParams.apenasInteressados,
                apenasComprados: pagingParams.apenasComprados,
                empregadorId: pagingParams.empregadorId,
                cursoId: pagingParams.cursoId,
                situacao: null,
                competencias: []
            };
        }

        function carregarCompetenciasAvaliadas() {
            Pessoa.getCompetenciasAvaliadas({}, function (data) {
                vm.compentenciasAvaliadas = data;
            });
        }

        function carregarPessoas() {
            vm.carregando = true;
            vm.filtros.classificacao = null;
            vm.pessoasSelecionadas = [];
            Pessoa.contarPessoasPorSituacao(vm.filtros, function (data) {
                initSituacoes(data);

                if (!vm.situacao) {
                    vm.situacao = vm.situacoes[1];
                }
                vm.filtros.page = vm.page - 1;
                vm.filtros.pageSize = vm.itemsPerPage;
                vm.filtros.classificacao = vm.situacao.nome;
                Pessoa.getPessoasFiltroSimples(vm.filtros, onSuccess, onError);
            });

        }

        function onError() {
            vm.carregando = false;
        }

        function buscarSaldo() {
            Empregador.getSaldo({}, function (data) {
                vm.saldo = data.saldo;
            });
        }

        function buscarPlanoPagamentoPremium() {
            vm.temPlanoPagamentoPremium = false;
            Empregador.temPlanoPagamentoPremium({idEmpregador: pagingParams.empregadorId}, function (data) {
                vm.temPlanoPagamentoPremium = data.qntPlanoPagamentoPremium;
            });
        }

        function selecionarPessoa(pessoa) {
            pessoa.selecionada = !pessoa.selecionada;
            if (vm.pessoasSelecionadas.indexOf(pessoa) >= 0) {
                vm.pessoasSelecionadas.splice(vm.pessoasSelecionadas.indexOf(pessoa), 1);
            } else {
                vm.pessoasSelecionadas.push(pessoa);
            }
        }

        function avancarVarios() {
            vm.proximoCodigo = vm.situacao.codigo + 1;
            for (var i = 0; i < vm.pessoasSelecionadas.length; i++) {
                var pessoa = vm.pessoasSelecionadas[i];
                pessoa.classificacao = vm.situacoes[vm.situacao.codigo + 1].nome;
                savePessoasSimples(pessoa);
            }
            vm.situacao = vm.situacoes[vm.situacao.codigo + 1];
            carregarPessoas();
        }

        function savePessoasSimples(pessoaVaga) {
            Empregador.savePessoaEmpresaSimples(pessoaVaga);
        }

        function voltarVarios() {
            vm.proximoCodigo = vm.situacao.codigo - 1;
            for (var i = 0; i < vm.pessoasSelecionadas.length; i++) {
                var pessoa = vm.pessoasSelecionadas[i];
                pessoa.classificacao = vm.situacoes[vm.situacao.codigo - 1].nome;
                savePessoasSimples(pessoa);
            }
            vm.situacao = vm.situacoes[vm.situacao.codigo - 1];
            carregarPessoas();
        }

        function selecionarSituacao(situacao) {
            vm.situacao = situacao;
            carregarPessoas();
        }

        buscarSaldo();
        buscarPlanoPagamentoPremium();


        function onSuccess(data, headers) {
            vm.queryCount = vm.totalItems;
            vm.pessoas = data;
            for (var j = 0; j < vm.situacoes.length; j++) {
                var situacao = vm.situacoes[j];
                for (var i = 0; i < vm.pessoas.length; i++) {
                    var pessoa = vm.pessoas[i];
                    if (!pessoa.classificacao) {
                        pessoa.classificacao = 'CANDIDATO';
                    }
                    if (pessoa.classificacao == situacao.nome) {
                        situacao.pessoas.push(pessoa);
                    }
                }
                if (vm.situacao.nome == situacao.nome) {
                    vm.situacao = situacao;
                }

            }
            if (vm.filtros.pcd) {
                vm.filtrouPcd = true;
            }
            vm.carregando = false;
        }


        function transition() {
            carregarPessoas();
        }


        function verPareceres(pessoa) {
            $uibModal.open({
                templateUrl: 'app/entities/empregador/parecer.html',
                controller: 'ParecerPessoaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    user: ['Pessoa', function (Pessoa) {
                        return Pessoa.getUserByPessoaId({id: pessoa.pessoaId}).$promise;
                    }],
                    empregadorId: function () {
                        return pagingParams.empregadorId;
                    }
                }
            });
        }

        function verPerfilPremium(pessoa) {
            if (pessoa.premium) {
                window.open(' #/completo/' + pessoa.pessoaId, '_blank');
            } else {
                ConfirmService.confirm({
                        title: "Perfil Premium",
                        text: "Deseja adquirir o perfil completo de " + pessoa.nome + "? Ao confirmar será debitado o valor de 1 crédito do seu saldo.",
                        confirm: function () {
                            Empregador.comprarPerfil({id: pessoa.pessoaId}, function (data) {
                                if (data && data.id) {
                                    carregarVagas();
                                    buscarSaldo();
                                    window.open(' #/completo/' + pessoa.pessoaId, '_blank');
                                } else {
                                    alert("Não foi possível ver o perfil premium, verifique seu saldo");
                                }
                            }, function (error) {
                                alert("Não foi possível ver o perfil premium, verifique seu saldo");
                            });
                        },
                        cancel: function () {
                        }
                    }
                );
            }
        }

        function calculateAge(birthday) {
            if (!birthday) {
                return;
            }
            return new Date(new Date() - new Date(birthday)).getFullYear() - 1970;
        }


        function consultarAvaliacoes(pessoa) {
            $uibModal.open({
                templateUrl: 'app/entities/questionario/questionario-pessoa.html',
                controller: 'QuestionarioPessoaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    pessoaId: ['PlanoCredito', function (PlanoCredito) {
                        //return User.get({login: $stateParams.login});
                        return pessoa.pessoaId;
                    }]
                }
            });
        }

        function loadStatus(callback) {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
                callback();
            });
        }

        function loadFormacao() {
            FormacaoAcademica.getTipoFormacao({}, function (data) {
                vm.tiposFormacao = data;
            });
        }

        function criarSituacao(codigo, nome, descricao) {
            return {
                codigo: codigo,
                nome: nome,
                descricao: descricao,
                total: 0,
                pessoas: []
            };
        }

        function initSituacoes(situacoesDaBase) {
            vm.situacoes = [
                criarSituacao(0, 'DESCLASSIFICADO', 'Desclassificado(s)'),
                criarSituacao(1, 'CANDIDATO', 'Inscrito(s)'),
                criarSituacao(2, 'AVALIACAO', 'Avaliação'),
                criarSituacao(3, 'CLASSIFICADO', 'Classificado(s)'),
                criarSituacao(4, 'APROVADO', 'Aprovado(s)'),
                criarSituacao(5, 'FORMALIZACAO', 'Formalização'),
                criarSituacao(6, 'CONTRATADO', 'Contratado(s)'),
                criarSituacao(7, 'DESISTENTE', 'Desistente(s)'),
            ];

            for (var i = 0; i < situacoesDaBase.length; i++) {
                var sit = situacoesDaBase[i];
                for (var j = 0; j < vm.situacoes.length; j++) {
                    var sit2 = vm.situacoes[j];
                    if (sit2.nome == sit.classificacao) {
                        sit2.total = sit.total;
                    }
                }
            }
        }


        loadStatus(carregarTudo);


        function carregarTudo() {
            loadFormacao();
            limparFiltros();

            if (vm.filtrarCompetencias) {
                carregarCompetenciasAvaliadas();// aqui ja carrega as pessoas filtrando
            } else {
                carregarPessoas();
            }
        }
    }
})();
