(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('TutorialDialogController', TutorialDialogController);

    TutorialDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity'];

    function TutorialDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity) {
        var vm = this;
        console.log(" >>>>>>>>>> ", entity)
        vm.tutorial = entity;
        vm.clear = clear;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


    }
})();
