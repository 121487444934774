(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PainelFinannceiroController', PainelFinannceiroController);

    PainelFinannceiroController.$inject = ['$scope', '$state', '$sce'];

    function PainelFinannceiroController($scope, $state, $sce) {
        var vm = this;
        vm.paineis = [
            {
                id: "a437ae9e-1c5c-4e65-b8ec-e5aeae9b7460",
                title: "Desempenho das Empresas",
                type: "question",
            },
            {
                id: "27120c34-5291-4c4f-bae5-0c65e45e528b",
                title: "Vendas",
                type: "question",
            },
            {
                id: "41feb212-e330-4d68-93db-aac34214953f",
                title: "Comissões",
                type: "question",
            },
            {
                id: "99e6f980-6810-41a6-8301-1bd3db6f042e",
                title: "Consumo de Créditos",
                type: "question",
            },
            {
                id: "cae24682-2859-44a9-967b-4916d44915ac",
                title: "Acumulo de Créditos",
                type: "question",
            },
            {
                id: "2c97e5c6-56ed-4ec1-81ae-faf249a10236",
                title: "Entrada de Usuários",
                type: "question",
            },
        ];

        vm.painel = vm.paineis[0];
    }

    angular.module('mySkillsPersonalApp')
        .filter('urlDashBoard', urlDashBoard);

    urlDashBoard.$inject = ['$sce'];

    function urlDashBoard($sce) {
        return function (painel) {
            return $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/' + painel.type + '/' + painel.id);
        };
    }

})();
