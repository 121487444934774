(function () {
        'use strict';

        angular
            .module('mySkillsPersonalApp')
            .controller('QueryResultController', QueryResultController);

        QueryResultController.$inject = ['$timeout', '$state', '$scope', '$stateParams',
            'query', '$location', 'QueryService', 'title', '$uibModalInstance', 'onClickRow', '$uibModal'];

        function QueryResultController($timeout, $state, $scope, $stateParams,
            query, $location, QueryService, title, $uibModalInstance, onClickRow, $uibModal) {
            var vm = this;
            vm.clear = clear;
            vm.ok = ok;
            vm.click = onClickRow;
            vm.load = load;
            vm.carregando = false;
            vm.exportToFile = exportToFile;
            vm.queryParam = query;
            vm.searchParam = '';
            vm.page = vm.queryParam.page + 1;

            function clear() {
                $uibModalInstance.dismiss('close');
            }

            function ok(result) {
                $uibModalInstance.close(result);
            }

            function prepareParams() {
                var param = {};
                vm.queryParam.page = vm.page - 1;
                angular.copy(vm.queryParam, param);
                param.params.push({name: 'searchParam', value: vm.searchParam});
                return param;
            }

            function load() {
                var param = prepareParams();
                vm.carregando = true;
                QueryService.execute(param, function (data) {
                    vm.query = data;
                    vm.carregando = false;
                }, function (error) {
                    vm.carregando = false;
                });
            }

            function exportToFile() {
                var param = prepareParams();
                vm.carregando = true;
                QueryService.export(param, function (arquivo) {
                    var byteCharacters = atob(arquivo.bytes);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], {type: arquivo.contentType});
                    var file = blob;
                    vm.fileURL = window.URL.createObjectURL(file);

                    var fileName = arquivo.nome;


                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    a.href = vm.fileURL;
                    a.download = fileName;
                    a.click();
                    vm.carregando = false;
                });
            }

            vm.load();

            vm.title = title;

            vm.pageSizes = [10, 25, 50, 100];
            vm.pageSize = vm.pageSizes[0];


        }
    }

)
();
