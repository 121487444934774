(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('TermoAceiteController', TermoAceiteController);

    TermoAceiteController.$inject = ['$scope', 'Principal', 'Termo', '$state', '$window', '$uibModalInstance', '$sce'];

    function TermoAceiteController($scope, Principal, Termo, comissao, $window, $uibModalInstance, $sce) {
        var vm = this;
        vm.clear = clear;
        vm.ok = ok;
        vm.trustedHtml = trustedHtml;

        Termo.query({
            page: 0,
            size: vm.itemsPerPage,
        }, function (data) {
            vm.termos = data;
            vm.termo = data[0];
        });

        function ok() {
            $uibModalInstance.close({aceite: true});
        }

        function clear() {
            $uibModalInstance.close({aceite: false});
        }

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

    }
})();
