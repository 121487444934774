(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PlanoDestaqueDialogController', PlanoDestaqueDialogController);

    PlanoDestaqueDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'PlanoDestaque'];

    function PlanoDestaqueDialogController($timeout, $scope, $stateParams, $state, entity, PlanoDestaque) {
        var vm = this;

        vm.planoDestaque = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("plano-destaque")
        }

        function save() {
            vm.isSaving = true;
            if (vm.planoDestaque.id !== null) {
                PlanoDestaque.update(vm.planoDestaque, onSaveSuccess, onSaveError);
            } else {
                PlanoDestaque.save(vm.planoDestaque, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:planoDestaqueUpdate', result);
            $state.go("plano-destaque");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
