(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('SocialRegisterController', SocialRegisterController);

    SocialRegisterController.$inject = ['$filter', '$stateParams', '$timeout'];

    function SocialRegisterController($filter, $stateParams, $timeout) {
        var vm = this;

        vm.success = $stateParams.success;
        vm.error = !vm.success;
        vm.provider = $stateParams.provider;
        vm.providerLabel = $filter('capitalize')(vm.provider);
        vm.success = $stateParams.success;


        $timeout(function () {
            if (vm.success) {
                var result = document.getElementsByClassName("jh-btn-social");
                console.log(result);
                var wrappedResult = angular.element(result);
                console.log(wrappedResult);
                wrappedResult.click();
            }
        }, 2000);

    }
})();
