(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('AvaliacaoController', AvaliacaoController);

    AvaliacaoController.$inject = ['$state', '$filter', '$uibModal', '$location', 'Principal', 'Questionario', 'Pessoa', '$window', '$timeout'];

    function AvaliacaoController($state, $filter, $uibModal, $location, Principal, Questionario, Pessoa, $window, $timeout) {
        var vm = this;
        vm.location = $location.absUrl();
        vm.getStatus = getStatus;

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated;

            Pessoa.getPerfilByUser({}, function (pessoa) {
                vm.pessoa = pessoa;
            });

        });

        vm.imprimir = imprimir;
        vm.carregarApuracao = carregarApuracao;
        vm.vincularPrimeiraAvaliacao = vincularPrimeiraAvaliacao;
        vm.montarRadarGeral = montarRadarGeral;
        vm.openUrlBoleto = openUrlBoleto;
        vm.copyBarCode = copyBarCode;
        vm.avaliacoes = [];
        vm.apuracao = null;
        vm.avaliacao = null;
        vm.apuracoes = [];
        vm.todasCompetencias = [];
        vm.loading = false;
        vm.top = 10;
        vm.temAlgumaAvaliacaoSemVaga = false;
        vm.avaliacoesGratis = 0;
        vm.avaliacoesPremium = 0;
        vm.copiado = 'Copiar Código Barras';

        function getStatus(name) {
            switch (name) {
                case 'PROCESSING':
                    return 'Processando'
                case 'AUTHORIZED':
                    return "Autorizado"
                case 'PAID':
                    return "Pago"
                case 'REFUNDED':
                    return 'Reembolsado'
                case 'WAITING_PAYMENT':
                    return 'Aguardando Pagamento'
                case 'PENDING_REFUND':
                    return 'Aguardando Reembolso'
                case 'REFUSED':
                    return 'Recusado'
                case 'CHARGEDBACK':
                    return 'Chargeback'
                default:
                    return null;
            }
        }

        function vincularPrimeiraAvaliacao() {
            Questionario.vincularPrimeiraAvaliacao({}, function (sucesso) {
                console.log("Deu certo", sucesso);
                carregarInformacoes();
            }, function (error) {
                console.log("NÃO Deu certo", error);
            });
        }

        function carregarInformacoes() {
            vm.loading = true;
            vm.avaliacaoGeral = {apuracao: {}};
            zerarRadar(vm.avaliacaoGeral.apuracao);
            Questionario.getResultadoInternoCorporateAvaliacao({}, function (sucesso) {
                vm.avaliacoes = sucesso;
                for (var i = 0; i < vm.avaliacoes.length; i++) {
                    var avaliacao = vm.avaliacoes[i];
                    avaliacao.questionarios = avaliacao.etapas.filter(function (e) {
                        return e.tiposhort === 'Q'
                    });
                    avaliacao.entrevistas = avaliacao.etapas.filter(function (e) {
                        return e.tiposhort === 'E'
                    });
                    if (avaliacao.etapas_realizadas && avaliacao.etapas_realizadas > 0) {
                        carregarApuracao(avaliacao);
                    }
                    if (avaliacao.venda) {
                        vm.avaliacoesPremium++;
                        vm.mostrarPremium = true;
                    } else {
                        if (!avaliacao.situacao_vaga) {
                            vm.temAlgumaAvaliacaoSemVaga = true;
                        }
                        vm.avaliacoesGratis++;
                    }
                }
                vm.loading = false;


            }, function (erro) {
                vm.loading = false;
                console.log("ERRO: ", erro);
            });
        }

        function zerarRadar(apuracao) {
            apuracao.radarCorporate = {
                labels: [],
                data: [
                    [],
                    [],
                    []
                ],
                colours: [
                    {
                        backgroundColor: 'rgba(255,51,0,0.5)',
                        borderColor: 'rgba(255,51,0,0.5)',
                        pointBackgroundColor: 'rgba(255,51,0,0.5)',
                        pointHoverBorderColor: 'rgba(255,51,0,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,102,255,0.5)',
                        pointBackgroundColor: 'rgba(0,102,255,0.5)',
                        pointHoverBorderColor: 'rgba(0,102,255,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,0,0,0)',
                        pointBackgroundColor: 'rgba(0,0,0,0)',
                        pointHoverBorderColor: 'rgba(0,0,0,0)'
                    }
                ]
            };
        }

        function init(apuracao) {
            zerarRadar(apuracao);
            angular.forEach(apuracao.competencias, function (competencia) {
                vm.todasCompetencias.push(competencia);
            });
            vm.todasCompetencias.sort(function (a, b) {
                if (b.valor > a.valor) {
                    return 1;
                }
                if (b.valor < a.valor) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });

            angular.forEach(apuracao.competencias, function (competencia) {
                competencia.valor = Math.round(competencia.valor);
                apuracao.radarCorporate.labels.push(competencia.nome);
                apuracao.radarCorporate.data[0].push(competencia.valor);
                apuracao.radarCorporate.data[1].push(100);
                apuracao.radarCorporate.data[2].push(1);
            });
            montarRadarGeral();
        }

        function montarRadarGeral() {

            var topCompetencias = vm.todasCompetencias.slice(0, vm.top + 1);
            vm.avaliacaoGeral.apuracao.radarCorporate.labels = [];
            vm.avaliacaoGeral.apuracao.radarCorporate.data = [
                [],
                [],
                []
            ];
            zerarRadar(vm.avaliacaoGeral.apuracao);
            angular.forEach(topCompetencias, function (competencia) {
                competencia.valor = Math.round(competencia.valor);
                vm.avaliacaoGeral.apuracao.radarCorporate.labels.push(competencia.nome + " (" + competencia.valor + "%)");
                vm.avaliacaoGeral.apuracao.radarCorporate.data[0].push(competencia.valor);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[1].push(100);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[2].push(1);
            });
        }

        function carregarApuracao(avaliacao) {
            Questionario.getResultadoApuracao({avaliacaoId: avaliacao.id}, function (sucesso) {
                avaliacao.apuracao = sucesso;
                init(avaliacao.apuracao);
            }, function (erro) {
                console.log("ERRO: ", erro);
            });
        }


        function imprimir() {
            Questionario.imprimir(vm, $filter, $state);
        }

        carregarInformacoes();

        vm.openModal = function (avaliacao) {
            vm.modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'apuracao-modal.tmpl.html',
                controller: 'ResultadoApuracaoController',
                controllerAs: 'vm',
                windowClass: 'modal-grande',
                resolve: {
                    avaliacao: avaliacao
                }
            });

        }

        function openUrlBoleto(url) {
            $window.open(url, '_blank');   // may alse try $window
        }

        function copyBarCode(barCode) {

            var text_to_share = barCode;

            // create temp element
            var copyElement = document.createElement("span");
            copyElement.appendChild(document.createTextNode(text_to_share));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();
            vm.copiado = 'Copiado!';


            $timeout(function () {
                vm.copiado = 'Copiar Código Barras';
            }, 5000);


        }

    }


    angular
        .module('mySkillsPersonalApp')
        .controller('ResultadoApuracaoController', ResultadoApuracaoController);

    ResultadoApuracaoController.$inject = ['$uibModalInstance', 'avaliacao'];

    function ResultadoApuracaoController($uibModalInstance, avaliacao) {
        var vm = this;
        vm.avaliacao = avaliacao;


        vm.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();




