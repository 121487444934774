(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('QuestionarioController', QuestionarioController);

    QuestionarioController.$inject = ['$state', 'entity', '$sce', 'Questionario', 'ConfirmService', 'Principal', 'Pessoa'];

    function QuestionarioController($state, entity, $sce, Questionario, ConfirmService, Principal, Pessoa) {
        var vm = this;
        vm.questionario = entity;
        vm.index = 0;
        vm.trustAsHtml = trustAsHtml;
        vm.continuar = continuar;
        vm.finalizar = finalizar;
        vm.nome = null;

        Principal.identity().then(function (account) {
            if (account) {
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.account = account;
            }
            chegou();
        });


        function trustAsHtml(texto) {
            return $sce.trustAsHtml(texto);
        }

        function chegou() {
            if (vm.isAuthenticated) {
                Pessoa.getPerfilByUser({}, function (pessoa) {
                    vm.pessoa = pessoa;
                    vm.nome = pessoa.nome

                    ConfirmService.swal({
                        title: "Bem vindo!",
                        text: vm.nome + ", leia as instruções para começar a sua auto avalização",
                        timer: 3000,
                        showConfirmButton: false
                    });
                })
            } else {
                ConfirmService.swal({
                        title: "Olá, seja bem vindo!",
                        text: "Antes de começar, precisamos saber seu nome ",
                        type: "input",
                        closeOnConfirm: false,
                        animation: "slide-from-top",
                        inputPlaceholder: "Digite seu nome completo"
                    },
                    function (inputValue) {
                        if (!inputValue) {
                            ConfirmService.showInputError("O Nome é obrigatório.");
                            return false;
                        }
                        if (inputValue === false) {
                            ConfirmService.showInputError("O Nome é obrigatório.");
                            return false;
                        }
                        if (inputValue === "") {
                            ConfirmService.showInputError("O Nome é obrigatório.");
                            return false
                        }
                        vm.nome = inputValue;
                        ConfirmService.swal({
                            title: "Quase lá!",
                            text: inputValue + ", leia as instruções para começar a sua autoavaliação",
                            timer: 3000,
                            showConfirmButton: false
                        });
                    });
            }
        }


        function continuar() {
            if (vm.questionario.questoes.length >= vm.index) {
                vm.questao = vm.questionario.questoes[vm.index];
                vm.index = vm.index + 1;
            }
        }

        function finalizar() {

            if (vm.isAuthenticated && vm.account && vm.account.email) {
                criarResultadoAndSalvar(vm.account.email);
            } else {
                ConfirmService.swal({
                        title: "Parabéns! você terminou sua avaliação!",
                        text: vm.nome + " para receber sua avaliação, digite seu e-mail no campo abaixo",
                        type: "input",
                        showCancelButton: true,
                        closeOnConfirm: false,
                        cancelButtonText: "Cancelar",
                        animation: "slide-from-top",
                        inputPlaceholder: "Email Profissional"
                    },
                    function (inputValue) {
                        if (inputValue === false) return false;

                        if (inputValue === "") {
                            ConfirmService.showInputError("O Email é obrigatório.");
                            return false
                        }
                        criarResultadoAndSalvar(inputValue);

                    });
            }
        }

        function criarResultadoAndSalvar(email) {
            var resultado = {relevancias: [], competencias: []};
            vm.relevancias = new Object();
            resultado.nome = vm.nome;
            angular.forEach(vm.questionario.questoes, function (questao) {

                if (!vm.relevancias[questao.relevancia]) {
                    vm.relevancias[questao.relevancia] = {
                        valor: 0,
                        quantidade: 0,
                        descricao: questao.relevancia,
                        divisao: 0
                    }
                }
                resultado.competencias.push({
                    competencia: questao.competencia,
                    valor: questao.alternativa.valor * 100 / (questao.alternativas.length - 1)
                });

                vm.relevancias[questao.relevancia].valor = vm.relevancias[questao.relevancia].valor + questao.alternativa.valor;
                vm.relevancias[questao.relevancia].quantidade = vm.relevancias[questao.relevancia].quantidade + 1;
                vm.relevancias[questao.relevancia].divisao = vm.relevancias[questao.relevancia].divisao + (questao.alternativas.length - 1);

            });


            angular.forEach(vm.relevancias, function (relevancia) {
                relevancia.divisao = relevancia.divisao / relevancia.quantidade;
                relevancia.valor = relevancia.valor / relevancia.quantidade;
                relevancia.valor = relevancia.valor * 100 / relevancia.divisao;
                resultado.relevancias.push({relevancia: relevancia.descricao, valor: relevancia.valor});
            });


            resultado.email = email;
            Questionario.save(resultado, function (result) {
                /*ConfirmService.swal("Legal!", "foi enviado no email " + inputValue, "success");*/
                ConfirmService.swal({
                        title: "Pronto!",
                        text: "Encaminhamos no seu email '" + email + "' o resultado da autoavaliação.",
                        type: "success",
                        confirmButtonText: "OK",
                        closeOnConfirm: true
                    },
                    function () {
                        if (vm.isAuthenticated) {
                            $state.go("avaliacao");
                        } else {
                            $state.go("register");
                        }
                    });
            }, function (error) {
                ConfirmService.swal({
                        title: "Ops!",
                        text: "O email '" + email + "' informado é inválido",
                        type: "error",
                        confirmButtonText: "OK",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            finalizar();
                        }
                    });
            });

        }
    }
})();
