(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ValorLoteEnvioMensagemUsuarioController', ValorLoteEnvioMensagemUsuarioController);

    ValorLoteEnvioMensagemUsuarioController.$inject = ['$scope', '$state', '$filter', 'ValorLoteEnvioMensagemUsuario', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService'];

    function ValorLoteEnvioMensagemUsuarioController($scope, $state, $filter, ValorLoteEnvioMensagemUsuario, ParseLinks,
                             AlertService, pagingParams, paginationConstants, ConfirmService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll() {

            ValorLoteEnvioMensagemUsuario.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                filtro: vm.searchQuery ? vm.searchQuery : ""
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.valorLoteEnvioMensagemUsuarios = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
