(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroEmpresaFiliadaController', ParceiroEmpresaFiliadaController);

    ParceiroEmpresaFiliadaController.$inject = ['$scope', 'Principal', 'Parceiro', 'Empregador', 'entity', '$window', '$uibModalInstance', 'ConfirmService'];

    function ParceiroEmpresaFiliadaController($scope, Principal, Parceiro, Empregador, entity, $window, $uibModalInstance, ConfirmService) {
        var vm = this;
        vm.clear = clear;
        vm.ok = ok;
        vm.parceiro = entity;


        function ok() {
            Empregador.filtrar({cnpj: vm.cnpjEmpregador}, function (response) {
                if (!response || response.length < 1) {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "Não foi possível encontrar uma empresa com o CNPJ: " + vm.cnpjEmpregador,
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                } else {

                    ConfirmService.confirm({
                            title: "Confirme a Operação",
                            text: "Tem certeza que deseja vincular a empresa " + response[0].nomeFantasia + " ao parceiro " + vm.parceiro.nome,
                            confirm: function () {
                                Empregador.vincularParceiro({
                                    idParceiro: vm.parceiro.id,
                                    idEmpregador: response[0].id
                                }, function () {
                                    $uibModalInstance.close(vm.parceiro);
                                });
                            },
                            cancel: function () {
                            }
                        }
                    );

                }
            }, function (error) {
                ConfirmService.confirm({
                    title: "Ops!",
                    text: "Não foi possível encontrar uma empresa com o CNPJ " + vm.cnpjDestinoTransferencia,
                        confirm: function () {
                        },
                        cancel: function () {
                        }
                    }
                );
            });


        }

        function clear() {
            $uibModalInstance.dismiss('close');

        }

    }
})();
