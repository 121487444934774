(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .factory('FormacaoAcademicaSearch', FormacaoAcademicaSearch);

    FormacaoAcademicaSearch.$inject = ['$resource'];

    function FormacaoAcademicaSearch($resource) {
        var resourceUrl =  'api/_search/formacao-academicas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
