(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ContatoController', ContatoController);

    ContatoController.$inject = ['$scope', '$state', 'Pessoa', 'BuscaCep', 'Email'];

    function ContatoController($scope, $state, Pessoa, BuscaCep, Email) {
        var vm = this;
        vm.carregando= true;
        Pessoa.getContato({}, findByLoginSuccess);

        function findByLoginSuccess(pessoa) {
            vm.pessoa = pessoa;
            vm.carregando= false;
        }


    }
})();
