(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('contato', {
                parent: 'home',
                url: '/contato',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/contato/contato.html',
                        controller: 'ContatoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('pessoa');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('email');
                        $translatePartialLoader.addPart('redeSocial');
                        $translatePartialLoader.addPart('tipoTelefone');
                        $translatePartialLoader.addPart('telefone');
                        return $translate.refresh();
                    }]
                }
            })
            .state('email.new', {
                parent: 'perfil',
                url: '/new-email',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/email/email-dialog.html',
                        controller: 'EmailDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    endereco: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('email.edit', {
                parent: 'perfil',
                url: '/{id}/edit-email',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/email/email-dialog.html',
                        controller: 'EmailDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Email', function (Email) {
                                return Email.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('email.delete', {
                parent: 'perfil',
                url: '/{id}/delete-email',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/email/email-delete-dialog.html',
                        controller: 'EmailDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Email', function (Email) {
                                return Email.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('telefone.new', {
                parent: 'perfil',
                url: '/new-telefone',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    $uibModal.open({
                        templateUrl: 'app/entities/telefone/telefone-dialog.html',
                        controller: 'TelefoneDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    numero: null,
                                    tipo: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('telefone.edit', {
                parent: 'perfil',
                url: '/{id}/edit-telefone',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/telefone/telefone-dialog.html',
                        controller: 'TelefoneDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Telefone', function (Telefone) {
                                return Telefone.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('telefone.delete', {
                parent: 'perfil',
                url: '/{id}/delete-telefone',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/telefone/telefone-delete-dialog.html',
                        controller: 'TelefoneDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Telefone', function (Telefone) {
                                return Telefone.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('endereco.new', {
                parent: 'perfil',
                url: '/new-endereco',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'perfil',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    $uibModal.open({
                        templateUrl: 'app/entities/endereco/endereco-dialog.html',
                        controller: 'EnderecoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    cep: null,
                                    logradouro: null,
                                    complemento: null,
                                    bairro: null,
                                    localidade: null,
                                    uf: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('endereco.edit', {
                parent: 'perfil',
                url: '/{id}/edit-contato',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/endereco/endereco-dialog.html',
                        controller: 'EnderecoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Endereco', function (Endereco) {
                                return Endereco.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('endereco.delete', {
                parent: 'perfil',
                url: '/{id}/delete-contato',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/endereco/endereco-delete-dialog.html',
                        controller: 'EnderecoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Endereco', function (Endereco) {
                                return Endereco.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('redesocial.new', {
                parent: 'perfil',
                url: '/new-rede-social',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redesocial/rede-social-dialog.html',
                        controller: 'RedeSocialDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    nome: null,
                                    url: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('redesocial.edit', {
                parent: 'perfil',
                url: '/{id}/edit-rede-social',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redesocial/rede-social-dialog.html',
                        controller: 'RedeSocialDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['RedeSocial', function (RedeSocial) {
                                return RedeSocial.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('redesocial.delete', {
                parent: 'perfil',
                url: '/{id}/delete-rede-social',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redesocial/rede-social-delete-dialog.html',
                        controller: 'RedeSocialDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['RedeSocial', function (RedeSocial) {
                                return RedeSocial.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            });
    }
})();
