(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('TermoDetailController', TermoDetailController);

    TermoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Termo', '$sce'];

    function TermoDetailController($scope, $rootScope, $stateParams, previousState, entity, Termo, $sce) {
        var vm = this;

        vm.termo = entity;
        vm.trustedHtml = trustedHtml;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:termoUpdate', function(event, result) {
            vm.termo = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }
    }
})();
