(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('DocumentoPessoalDetailController', DocumentoPessoalDetailController);

    DocumentoPessoalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DocumentoPessoal', 'PerfilProfissional', 'Empregador'];

    function DocumentoPessoalDetailController($scope, $rootScope, $stateParams, previousState, entity, DocumentoPessoal, PerfilProfissional, Empregador) {
        var vm = this;

        vm.documentoPessoal = entity;
        vm.documentoPessoal.inicio_tela = entity.inicio;
        vm.documentoPessoal.fim_tela = entity.fim_tela;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:documentoPessoalUpdate', function(event, result) {
            vm.documentoPessoal = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
