(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ImagemController', ImagemController);

    ImagemController.$inject = ['$scope', 'Principal', 'Pessoa', '$state', 'imagem', 'Upload', '$window', '$uibModalInstance'];

    function ImagemController($scope, Principal, Pessoa, $state, imagem, Upload, $window, $uibModalInstance) {
        var vm = this;

        vm.imagem = imagem;
        vm.clear = clear;


        function clear() {
            $uibModalInstance.dismiss('close');
        }


    }
})();
