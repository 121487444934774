(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('registro-parceiro-param', {
            parent: 'account',
            url: '/registro-parceiro/{idParceiro}',
            data: {
                authorities: [],
                pageTitle: 'register.title',
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registro-parceiro/registro-parceiro.html',
                    controller: 'RegistroParceiroController',
                    controllerAs: 'vm'
                }
            },
            params: {
                idParceiro: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        idParceiro: $stateParams.idParceiro
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    return $translate.refresh();
                }]
            }
        }).state('registro-parceiro', {
            parent: 'account',
            url: '/registro-parceiro',
            data: {
                authorities: [],
                pageTitle: 'register.title',
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/registro-parceiro/registro-parceiro.html',
                    controller: 'RegistroParceiroController',
                    controllerAs: 'vm'
                }
            },
            params: {
                idParceiro: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        idParceiro: $stateParams.idParceiro
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
