(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Vaga', Vaga);

    Vaga.$inject = ['$resource'];

    function Vaga($resource) {
        var resourceUrl = 'api/public/vagas/:id';

        var resurce = $resource(resourceUrl, {}, {
            'getAll': {method: 'GET', isArray: true},
            'query': {method: 'POST', isArray: true},
            'getAllSismples': {url: 'api/public/vagas-simples', method: 'POST', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }, 'count': {
                url: 'api/public/vagas-count',
                method: 'GET',
            },
            'getFuncoes': {
                method: 'GET',
                isArray: true,
                url: 'api/public/funcoes-vagas-publicas',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSetores': {
                method: 'GET',
                isArray: true,
                url: 'api/public/setores-vagas-publicas',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCidades': {
                method: 'GET',
                isArray: true,
                url: 'api/public/cidades-vagas-publicas',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getFormacoes': {
                method: 'GET',
                isArray: true,
                url: 'api/public/formacoes-vagas-publicas',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVagasUsuario': {
                method: 'GET',
                isArray: true,
                url: 'api/public/vagas-usuario',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVagasPessoa': {
                method: 'GET',
                isArray: true,
                url: 'api/public/vagas-pessoa/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVagasEmpregador': {
                method: 'GET',
                isArray: true,
                url: 'api/public/vagas-por-empregador/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'candidatarSe': {url: 'api/public/vagas-candidatar-se', method: 'POST'},
            'moverPessoa': {url: 'api/vagas-mover-pessoa', method: 'GET'},
            'deleteVagaUsuarioById': {url: 'api/public/vagas-usuario/:id', method: 'DELETE'}
        });

        function getImageVaga(vagaSetor) {
            var caminho = "content/images/";

            if (vagaSetor.toUpperCase() === "FARMACIAS") {
                caminho += "VAGA_FARMACIAS.jpg";
            } else if (vagaSetor.toUpperCase() === "LABORATORIO DE PINTURA") {
                caminho += "VAGA_LABORATORIO_DE_PINTURA.jpg";
            } else if (vagaSetor.toUpperCase() === "MONITORAMENTO ELETRONICO") {
                caminho += "VAGA_MONITORAMENTO_ELETRONICO.jpg";
            } else if (vagaSetor.toUpperCase() === "ATENDIMENTO" || vagaSetor.toUpperCase() === "ESCRITÓRIO DE ADVOCACIA" || vagaSetor.toUpperCase() === "JURIDICO") {
                caminho += "VAGA_ATENDIMENTO_OU_ESCRITORIO_DE_ADVOCACIA_OU_JURIDICO.jpg";
            } else if (vagaSetor.toUpperCase() === "TRANSPORTE E LOGISTICA" || vagaSetor.toUpperCase() === "TRANSPORTE RODOVIARIO") {
                caminho += "VAGA_TRANSPORTE_E_LOGISTICA_OU_TRANSPORTE_RODOVIARIO.jpg";
            } else if (vagaSetor.toUpperCase() === "EVENTOS") {
                caminho += "VAGA_EVENTOS.jpg";
            } else if (vagaSetor.toUpperCase() === "RECURSOS HUMANOS" || vagaSetor.toUpperCase() === "GESTÃO COM PESSOAS") {
                caminho += "VAGA_RECURSOS_HUMANOS_OU_GESTAO_COM_PESSOAS.jpg";
            } else if (vagaSetor.toUpperCase() === "INSTITUIÇÃO FINANCEIRA" || vagaSetor.toUpperCase() === "ESCRITÓRIO CONTÁBIL") {
                caminho += "VAGA_INSTITUICAO_FINANCEIRA_OU_ESCRITORIO_CONTABIL.jpg";
            } else if (vagaSetor.toUpperCase() === "EDUCAÇÃO E ENSINO") {
                caminho += "VAGA_EDUCACAO_E_ENSINO.jpg";
            } else if (vagaSetor.toUpperCase() === "TURISMO") {
                caminho += "VAGA_TURISMO.jpg";
            } else if (vagaSetor.toUpperCase() === "RESTAURANTES" || vagaSetor.toUpperCase() === "RAMO ALIMENTÍCIO") {
                caminho += "VAGA_RESTAURANTES_OU_RAMO_ALIMENTICIO.jpg";
            } else if (vagaSetor.toUpperCase() === "OPERACIONAL" || vagaSetor.toUpperCase() === "INDUSTRIA ALIMENTICIA") {
                caminho += "VAGA_OPERACIONAL_OU_INDUSTRIA_ALIMENTICIA.jpg";
            } else if (vagaSetor.toUpperCase() === "HOTELARIA") {
                caminho += "VAGA_HOTELARIA.jpg";
            } else if (vagaSetor.toUpperCase() === "VEICULOS" || vagaSetor.toUpperCase() === "AUTO PEÇAS") {
                caminho += "VAGA_VEICULOS_OU_AUTO_PECAS.jpg";
            } else if (vagaSetor.toUpperCase() === "SUPERMERCADOS" || vagaSetor.toUpperCase() === "VAREJO") {
                caminho += "VAGA_SUPERMERCADOS_OU_VAREJO.jpg";
            } else if (vagaSetor.toUpperCase() === "DEPARTAMENTO DE MEIO AMBIENTE/DEO" || vagaSetor.toUpperCase() === "AMBIENTAL") {
                caminho += "VAGA_DEPARTAMENTO_DE_MEIO_AMBIENTE_OU_AMBIENTAL.jpg";
            } else if (vagaSetor.toUpperCase() === "SHOPPING") {
                caminho += "VAGA_SHOPPING.jpg";
            } else if (vagaSetor.toUpperCase() === "PAISAGISMO") {
                caminho += "VAGA_PAISAGISMO.jpg";
            } else if (vagaSetor.toUpperCase() === "MOVELARIA") {
                caminho += "VAGA_MOVELARIA.jpg";
            } else if (vagaSetor.toUpperCase() === "ORGÃO PUBLICO" || vagaSetor.toUpperCase() === "ADMINISTRATIVO" || vagaSetor.toUpperCase() === "MARKETING") {
                caminho += "VAGA_ORGAO_PUBLICO_OU_ADMINISTRATIVO_OU_MARKETING.jpg";
            } else if (vagaSetor.toUpperCase() === "ACADÊMICO") {
                caminho += "VAGA_ACADEMICO.jpg";
            } else if (vagaSetor.toUpperCase() === "SUPORTE") {
                caminho += "VAGA_SUPORTE.jpg";
            } else if (vagaSetor.toUpperCase() === "TECNOLOGIA DA INFORMAÇÃO" || vagaSetor.toUpperCase() === "DESENVOLVIMENTO") {
                caminho += "VAGA_TECNOLOGIA_DA_INFORMACAO_OU_DESENVOLVIMENTO.jpg";
            } else if (vagaSetor.toUpperCase() === "VETERINÁRIA") {
                caminho += "VAGA_VETERINARIA.jpg";
            } else if (vagaSetor.toUpperCase() === "MODA") {
                caminho += "VAGA_MODA.jpg";
            } else if (vagaSetor.toUpperCase() === "INDUSTRIA NA CONFECÇÃO" || vagaSetor.toUpperCase() === "INDUSTRIA") {
                caminho += "VAGA_INDUSTRIA_NA_CONFECCAO_OU_INDUSTRIA.jpg";
            } else if (vagaSetor.toUpperCase() === "COMERCIAL") {
                caminho += "VAGA_COMERCIAL.jpg";
            } else if (vagaSetor.toUpperCase() === "COZINHA PROFISSIONAL") {
                caminho += "VAGA_COZINHA_PROFISSIONAL.jpg";
            } else if (vagaSetor.toUpperCase() === "SAÚDE" || vagaSetor.toUpperCase() === "HOSPITALAR") {
                caminho += "VAGA_SAUDE_OU_HOSPITALAR.jpg";
            } else if (vagaSetor.toUpperCase() === "ENGENHARIA E CONSTRUÇÃO CIVIL" || vagaSetor.toUpperCase() === "RESIDÊNCIA") {
                caminho += "VAGA_ENGENHARIA_E_CONSTRUCAO_CIVIL_OU_RESIDENCIA.jpg";
            } else if (vagaSetor.toUpperCase() === "LIVESEO" || vagaSetor.toUpperCase() === "COMUNICAÇÃO E MKT NA WEB") {
                caminho += "VAGA_LIVESEO_OU_COMUNICACAO_E_MKT_NA_WEB.jpg";
            } else if (vagaSetor.toUpperCase() === "VENDAS ATACADO") {
                caminho += "VAGA_VENDAS_ATACADO.jpg";
            } else if (vagaSetor.toUpperCase() === "COMPRAS" || vagaSetor.toUpperCase() === "QUALIDADE" || vagaSetor.toUpperCase() === "PRODUTOS E SERVIÇOS") {
                caminho += "VAGA_COMPRAS_OU_QUALIDADE_OU_PRODUTOS_E_SERVICOS.jpg";
            } else if (vagaSetor.toUpperCase() === "CLIMATIZAÇÃO E REFRIGERAÇÃO") {
                caminho += "VAGA_CLIMATIZACAO_E_REFRIGERACAO.jpg";
            } else if (vagaSetor.toUpperCase() === "AGRONEGOCIO") {
                caminho += "VAGA_AGRONEGOCIO.jpg";
            } else if (vagaSetor.toUpperCase() === "TRANSPORTE AÉREO") {
                caminho += "VAGA_TRANSPORTE_AEREO.jpg";
            } else if (vagaSetor.toUpperCase() === "POSTOS DE COMBUSTIVEIS") {
                caminho += "VAGA_POSTOS_DE_COMBUSTIVEIS.jpg";
            } else if (vagaSetor.toUpperCase() === "EDIFICIOS RESIDENCIAIS E COMERCIAIS") {
                caminho += "VAGA_EDIFICIOS_RESIDENCIAIS_E_COMERCIAIS.jpg";
            } else {
                caminho += "myskillsCabeca.png";
            }

            return caminho;
        }

        resurce.getImageVaga = getImageVaga;

        return resurce;

    }
})();
