(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VagaUsuarioController', VagaUsuarioController);

    VagaUsuarioController.$inject = ['$scope', '$state', 'Vaga', 'ConfirmService', '$timeout'];

    function VagaUsuarioController($scope, $state, Vaga, ConfirmService, $timeout) {
        var vm = this;
        vm.clear = clear;
        vm.vagasUsuario = Vaga.getVagasUsuario();
        vm.desmarcarVaga = desmarcarVaga;

        function clear() {
            $state.go("vaga", {}, {reload: true});
        }

        function goVagaUsuario() {
             $state.go("vaga-usuario", {}, {reload: true});
        }

        function desmarcarVaga(vagaId) {
            ConfirmService.swal({
                title: "Atenção",
                text: "Deseja desmarcar esta profissão?",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#18a689", confirmButtonText: "Sim",
                cancelButtonText: "Não",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        Vaga.deleteVagaUsuarioById({id: vagaId});
                        clear();

                        $timeout(function () {
                            goVagaUsuario();
                        }, 200);
                    }
                }
            );
        }
    }
})();
