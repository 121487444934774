(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('DocumentoPessoalDialogController', DocumentoPessoalDialogController);

    DocumentoPessoalDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DocumentoPessoal', 'PerfilProfissional', 'Empregador', 'Upload', 'cfpLoadingBar'];

    function DocumentoPessoalDialogController($http, $timeout, $scope, $stateParams, $uibModalInstance, entity, DocumentoPessoal, PerfilProfissional, Empregador, Upload, cfpLoadingBar) {
        var vm = this;

        vm.documentoPessoal = entity;
        if (entity.empregador) {
            vm.cnpj = entity.empregador.cnpj;
        }
        vm.clear = clear;
        vm.save = save;
        vm.file = null;
        vm.errorFile = null;
        vm.progresso = cfpLoadingBar;


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function montarArquivoESalvar(file) {
            //Convert o file blob em base64 com o url do broswer.
            Upload.base64DataUrl(file).then(function (url) {
                var arquivo = {};
                arquivo.nome = file.name;
                var arr = url.split(',');
                var base64 = arr[arr.length - 1];
                arquivo.bytes = base64;
                arquivo.contentType = file.type;
                arquivo.lastModified = new Date();
                vm.documentoPessoal.arquivo = arquivo;
                saveDocumento();
            });
        }

        function save() {
            vm.progresso = cfpLoadingBar;
            vm.isSaving = true;
            if (vm.file) {
                montarArquivoESalvar(vm.file);
            } else {
                saveDocumento();
            }
        }

        function saveDocumento() {
            if (vm.documentoPessoal.id !== null) {
                DocumentoPessoal.update(vm.documentoPessoal, onSaveSuccess, onSaveError);
            } else {
                DocumentoPessoal.save(vm.documentoPessoal, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:documentoPessoalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
