(function() {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('MapBox', MapBox);

    MapBox.$inject = ['$resource'];

    function MapBox ($resource) {
        //var resourceUrl =  'https://viacep.com.br/ws/:cep/json';
        var resourceUrl =  'https://api.mapbox.com';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
