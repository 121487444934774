(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilProfissionalDialogController', PerfilProfissionalDialogController);

    PerfilProfissionalDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PerfilProfissional', 'Pessoa', 'ExperienciaProfissional', 'FormacaoAcademica'];

    function PerfilProfissionalDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PerfilProfissional, Pessoa, ExperienciaProfissional, FormacaoAcademica) {
        var vm = this;

        vm.perfilProfissional = entity;
        vm.clear = clear;
        vm.save = save;
        vm.pessoas = Pessoa.query();
        vm.experienciaprofissionals = ExperienciaProfissional.query();
        vm.formacaoacademicas = FormacaoAcademica.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.perfilProfissional.id !== null) {
                PerfilProfissional.update(vm.perfilProfissional, onSaveSuccess, onSaveError);
            } else {
                PerfilProfissional.save(vm.perfilProfissional, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mySkillsPersonalApp:perfilProfissionalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
