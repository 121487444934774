(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorPessoaDashBoardController', EmpregadorPessoaDashBoardController);

    EmpregadorPessoaDashBoardController.$inject = ['$timeout', '$state', '$scope', '$stateParams',
        'entity', '$location', 'QueryService', '$uibModal', '$filter'];

    function EmpregadorPessoaDashBoardController($timeout, $state, $scope, $stateParams,
        entity, $location, QueryService, $uibModal, $filter) {
        var vmdp = this;

        vmdp.entity = entity;
        vmdp.idParceiro = entity.parceiro.id;

        vmdp.contadoresPessoas = {};

        vmdp.fim = new Date();
        vmdp.inicio = new Date();
        vmdp.inicio.setMonth(0);
        vmdp.inicio.setDate(1);
        vmdp.pageSizes = [10, 25, 50, 100];
        vmdp.pageSize = vmdp.pageSizes[0];


        function newGraficoPiePessoa(series, title) {
            var pie = {};
            pie.series = series;
            pie.options = {
                identificador: title,
                onClick: function (e) {
                    var element = this.getElementAtEvent(e);
                    if (element.length) {
                        if (element[0]._chart.config.options.identificador) {
                            for (var i = 0; i < vmdp.graficosPessoa.length; i++) {
                                var graficosPessoaElement = vmdp.graficosPessoa[i];
                                if (graficosPessoaElement.title == element[0]._chart.config.options.identificador) {
                                    vmdp.goToQuery(pie, graficosPessoaElement.lista[element[0]._index]);
                                }
                            }
                        }
                    }
                },
                legend: {display: true, position: 'bottom', fullWidth: true},
                hover: {
                    animationDuration: 1
                },
                tooltips: {
                    enabled: true,
                },
            };
            pie.labels = [];
            pie.data = [];
            pie.lista = [];
            pie.title = title;
            return pie;
        }

        function getDefaultParams() {
            return [
                {name: 'idParceiro', value: Number(vmdp.idParceiro)},
                {name: 'inicio', value: toJSONLocal(vmdp.inicio)},
                {name: 'fim', value: toJSONLocal(vmdp.fim)},
            ];
        }

        function buscarPessoasPorAgrupador(query, series, title, field, where) {
            var pie = newGraficoPiePessoa(series, title);
            pie.field = field;
            var params = getDefaultParams();
            QueryService.execute({
                queryName: query,
                params: params
            }, function (data) {
                pie.where = where;
                pie.paramDetail = field;
                pie.queryDetail = 'dashBoardTodasPessoasPorParceiro';
                var totalGeral = 0;
                for (var i = 0; i < data.data.length; i++) {
                    totalGeral += data.data[i].total;
                }
                for (var i = 0; i < data.data.length; i++) {
                    var percentual = $filter('number')(((data.data[i].total * 100)/totalGeral ), 2);
                    pie.labels.push(data.data[i][field] + ' (' + percentual + '%)');
                    pie.data.push(data.data[i].total);
                    data.data[i].percentual = percentual;
                }
                pie.lista = data.data;
                vmdp.graficosPessoa.push(pie);
            });
        }

        function buscarPessoasParaLista(lista, index) {
            QueryService.execute({
                queryName: lista.query,
                page: lista.page - 1,
                size: lista.pageSize,
                params: getDefaultParams()
            }, function (data) {
                lista.data = data.data;
                lista.columns = data.columns;
                lista.count = data.count;
                lista.reload = buscarPessoasParaLista;
                vmdp.listaPessoas[index] = lista;
            });
        }

        function toJSONLocal(date) {
            var local = new Date(date);
            local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            return local.toJSON().slice(0, 10);
        }


        vmdp.goToQuery = function (lista, data) {

            $uibModal.open({
                templateUrl: 'app/entities/dashboards/query-result.html',
                controller: 'QueryResultController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    query: function () {
                        return {
                            queryName: lista.queryDetail,
                            page: 0,
                            size: 50,
                            where: lista.where,
                            params: [
                                {name: 'idParceiro', value: Number(vmdp.idParceiro)},
                                {name: lista.paramDetail, value: data[lista.paramDetail]}
                            ]
                        };
                    },
                    onClickRow: function () {
                        return function (result) {
                            window.open('/#/perfil/' + result.id, '_blank').focus();
                        };
                    },
                    title: function () {
                        return data.title;
                    },
                }
            });
        };

        function buscarUsuariosPorMes() {
            vmdp.chartBarUsuario = {};
            vmdp.chartBarUsuario.labels = [];
            vmdp.chartBarUsuario.series = ['Cadastro de Usuários'];
            vmdp.chartBarUsuario.data = [
                []
            ];

            QueryService.execute({
                queryName: 'dashBoardCadastroDeUsuariosPorMesPorParceiro',
                params: [
                    {name: 'idParceiro', value: Number(vmdp.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmdp.inicio)},
                    {name: 'fim', value: toJSONLocal(vmdp.fim)},
                ]
            }, function (data) {
                for (var i = 0; i < data.data.length; i++) {
                    var periodo = data.data[i];
                    vmdp.chartBarUsuario.labels.push(periodo.mes + '/' + periodo.ano);
                    vmdp.chartBarUsuario.data[0].push(periodo.count);
                }
            });
        }


        function buscarAcessoPorMes() {
            vmdp.chartBarAcesso = {};
            vmdp.chartBarAcesso.labels = [];
            vmdp.chartBarAcesso.series = ['Acesso de Usuários'];
            vmdp.chartBarAcesso.data = [
                []
            ];

            QueryService.execute({
                queryName: 'dashBoardAcessoDeUsuariosPorMesPorParceiro',
                params: [
                    {name: 'idParceiro', value: Number(vmdp.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmdp.inicio)},
                    {name: 'fim', value: toJSONLocal(vmdp.fim)},
                ]
            }, function (data) {
                var dateFormat = 'yyyy-MM-dd';
                for (var i = 0; i < data.data.length; i++) {
                    var periodo = data.data[i];
                    vmdp.chartBarAcesso.labels.push($filter('date')(periodo.dia, dateFormat));
                    vmdp.chartBarAcesso.data[0].push(periodo.count);
                }
            });
        }

        function initPessoaList(title, query, queryDetail, param, where) {
            return {
                data: [],
                page: 1,
                pageSize: vmdp.pageSize,
                title: title,
                where: where,
                query: query,
                queryDetail: queryDetail,
                paramDetail: param,
                params: [
                    {name: 'idParceiro', value: Number(vmdp.idParceiro)}
                ],
            };
        }

        vmdp.filtrar = function () {


            buscarUsuariosPorMes();
            buscarAcessoPorMes();
            vmdp.graficosPessoa = [];
            vmdp.listaPessoas = [
                initPessoaList('Pessoas por faixa etária', 'dashBoardPessoasPorFaixaEtaria',
                    'dashBoardTodasPessoasPorParceiro', 'idade',
                    'and CASE \n' +
                    'WHEN nascimento is null then \'Não informado\' \n' +
                    'WHEN AGE(nascimento) < INTERVAL \'14 years\' THEN \'14 anos ou menos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'14 years\' AND INTERVAL \'15 years\' THEN \'14 a 15 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'16 years\' AND INTERVAL \'18 years\' THEN \'16 a 18 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'19 years\' AND INTERVAL \'24 years\' THEN \'19 a 24 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'25 years\' AND INTERVAL \'34 years\' THEN \'25 a 34 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'35 years\' AND INTERVAL \'44 years\' THEN \'35 a 44 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'45 years\' AND INTERVAL \'54 years\' THEN \'45 a 54 anos\' \n' +
                    'WHEN AGE(nascimento) BETWEEN INTERVAL \'55 years\' AND INTERVAL \'60 years\' THEN \'55 a 60 anos\' \n' +
                    'ELSE \'60 anos ou mais\' END = :idade '),
                initPessoaList('Pessoas por localidade', 'dashBoardPessoasPorCidade',
                    'dashBoardTodasPessoasPorParceiro', 'cidade',
                    'and (select lower(string_agg(endereco.localidade, \', \')) from endereco where endereco.pessoa_id = p.id) like lower(concat(\'%\', :cidade, \'%\'))'),
                initPessoaList('Pessoas por turno de trabalho', 'dashBoardPessoasPorPeriodoTrabalho',
                    'dashBoardTodasPessoasPorParceiro', 'periodo',
                    'and exists (select * from vinculo_atributo va where va.vinculo = p.id::varchar and va.atributo_id = 1922917 AND lower(va.valor)  =  lower(:periodo))'
                ),
                initPessoaList('Pessoas por PCD', 'dashBoardPessoasPorPCD',
                    'dashBoardTodasPessoasPorParceiro', 'pcd',
                    'and exists (select * from vinculo_atributo va inner join atributo_generico at on at.id = va.atributo_id where at.tipo_atributo = \'PCD\' and va.valor is not null and va.vinculo = p.id::varchar AND lower(coalesce(va.valor, \'Não informado\'))  =  lower(:pcd) )')
            ];
            buscarPessoasPorAgrupador('dashBoardPessoasPorStatus', ['Situação'], 'Pessoas por situação', 'situacao', ' and coalesce(status.descricao, \'Não Informado\')  = :situacao');
            buscarPessoasPorAgrupador('dashBoardPessoasPorEscolaridade', ['Escolaridade'], 'Pessoas por escolaridade', 'formacao',
                'and coalesce(INITCAP(replace(p.tipo_escolaridade, \'_\', \' \')), \'Não Informado\' ) = :formacao');
            buscarPessoasPorAgrupador('dashBoardPessoasPorGenero', ['Gênero'], 'Pessoas por gênero', 'genero', ' and coalesce(initcap(replace(p.genero, \'_\', \' \')), \'Nao Informado\') = :genero');

            buscarPessoasParaLista(vmdp.listaPessoas[0], 0);
            buscarPessoasParaLista(vmdp.listaPessoas[1], 1);
            buscarPessoasParaLista(vmdp.listaPessoas[2], 2);
            buscarPessoasParaLista(vmdp.listaPessoas[3], 3);
        };

        vmdp.calcularPercentual = function (quadro) {
            return quadro.percentualCrescimento = ((vmdp.contadoresPessoas[quadro.atual] - vmdp.contadoresPessoas[quadro.anterior]) / vmdp.contadoresPessoas[quadro.anterior]) * 100;
        };

        vmdp.filtrar();

    }
})();
