(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaDetailController', PessoaDetailController);

    PessoaDetailController.$inject = ['$window', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Pessoa',
        'FormacaoAcademica', 'ExperienciaProfissional', 'Mensagem', 'ConfirmService', 'Principal', 'Questionario', 'Vaga', 'DocumentoPessoal'];

    function PessoaDetailController($window, $scope, $rootScope, $state, $stateParams, previousState, entity, Pessoa,
                                    FormacaoAcademica, ExperienciaProfissional, Mensagem, ConfirmService, Principal, Questionario, Vaga, DocumentoPessoal) {
        var vm = this;

        vm.enviarMensagem = enviarMensagem;
        vm.abrirWhats = abrirWhats;
        vm.trocarAba = trocarAba;
        vm.pessoa = entity;

        vm.mostrarPerfil = true;

        vm.previousState = previousState.name;
        vm.labels = [];
        vm.series = ['Series A'];
        vm.data = [
            []
        ];

        vm.baixarAnexo = baixarAnexo;


        function carregarInformacoes() {
            FormacaoAcademica.getAllByUserId({id: vm.pessoa.user.id}, function (formacoes) {
                vm.formacoes = formacoes;
            });

            DocumentoPessoal.getAllByUserId({id: vm.pessoa.user.id}, function (documentos) {
                vm.documentos = documentos;
            });


            ExperienciaProfissional.getAllByUserId({id: vm.pessoa.user.id}, function (experiencias) {
                vm.experiencias = experiencias;
                angular.forEach(vm.experiencias, function (experiencia) {
                    vm.labels.push(experiencia.empregador ? experiencia.empregador.nomeFantasia : experiencia.cargo);
                    var timeDiff = Math.abs(new Date(experiencia.inicio).getTime() - (experiencia.fim ? new Date(experiencia.fim).getTime() : new Date()));
                    vm.data[0].push(Math.ceil(timeDiff / (1000 * 3600 * 24) / 30));

                })
            });
        }


        function baixarAnexo(arquivoId) {
            Pessoa.baixarAnexo(arquivoId);
        }

        function enviarMensagem() {
            Mensagem.save(vm.mensagem, function (mensagem) {
                $state.go("mensagem");
                ConfirmService.swal({
                    title: "Sua mensagem foi enviada!",
                    text: "Aguarde a resposta de " + vm.pessoa.nome,
                    timer: 3000,
                    showConfirmButton: false
                });
            });
            inicarMensagem();
        }


        function inicarMensagem() {
            vm.mensagem = {texto: "", destino: vm.pessoa.user, publicadoEm: new Date()};
        }


        function carregarInformacoesAvaliacoes() {
            Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
                if (result) {
                    vm.avaliacaoGeral = {apuracao: {}};
                    zerarRadar(vm.avaliacaoGeral.apuracao);
                    Questionario.getResultadoInternoCorporateAvaliacaoByPessoaId({id: vm.pessoa.id}, function (sucesso) {
                        console.log("SUCESSO AVALIACAO: ", sucesso);
                        vm.avaliacoes = sucesso;
                        for (var i = 0; i < vm.avaliacoes.length; i++) {
                            var avaliacao = vm.avaliacoes[i];
                            avaliacao.questionarios = avaliacao.etapas.filter(function (e) {
                                return e.tiposhort === 'Q'
                            });
                            avaliacao.entrevistas = avaliacao.etapas.filter(function (e) {
                                return e.tiposhort === 'E'
                            });
                            if (avaliacao.etapas_realizadas && avaliacao.etapas_realizadas > 0) {
                                carregarApuracao(avaliacao);
                            }
                        }


                    }, function (erro) {
                        console.log("ERRO: ", erro);
                    });
                }
            })

        }

        function carregarApuracao(avaliacao) {
            Questionario.getResultadoApuracao({avaliacaoId: avaliacao.id}, function (sucesso) {
                console.log("SUCESSO AVALIACAO: ", sucesso);
                avaliacao.apuracao = sucesso;
                init(avaliacao.apuracao);
            }, function (erro) {
                console.log("ERRO: ", erro);
            });
        }

        function zerarRadar(apuracao) {
            apuracao.radarCorporate = {
                labels: [],
                data: [
                    [],
                    [],
                    []
                ],
                colours: [
                    {
                        backgroundColor: 'rgba(255,51,0,0.5)',
                        borderColor: 'rgba(255,51,0,0.5)',
                        pointBackgroundColor: 'rgba(255,51,0,0.5)',
                        pointHoverBorderColor: 'rgba(255,51,0,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,102,255,0.5)',
                        pointBackgroundColor: 'rgba(0,102,255,0.5)',
                        pointHoverBorderColor: 'rgba(0,102,255,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,0,0,0)',
                        pointBackgroundColor: 'rgba(0,0,0,0)',
                        pointHoverBorderColor: 'rgba(0,0,0,0)'
                    }
                ]
            };
        }

        function init(apuracao) {
            zerarRadar(apuracao);
            angular.forEach(apuracao.competencias, function (competencia) {
                competencia.valor = Math.round(competencia.valor);
                apuracao.radarCorporate.labels.push(competencia.nome);
                apuracao.radarCorporate.data[0].push(competencia.valor);
                apuracao.radarCorporate.data[1].push(100);
                apuracao.radarCorporate.data[2].push(1);

                vm.avaliacaoGeral.apuracao.radarCorporate.labels.push(competencia.nome + " (" + competencia.valor + "%)");
                vm.avaliacaoGeral.apuracao.radarCorporate.data[0].push(competencia.valor);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[1].push(100);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[2].push(1);
            });

            vm.mostrarAvaliacoes = true;
        }


        function carregarVagas() {
            vm.vagas = Vaga.getVagasPessoa({id: vm.pessoa.id});
        }

        function trocarAba() {
            if (vm.mostrarPerfil) {
                vm.mostrarPerfil = false;
                carregarInformacoesAvaliacoes();
                carregarVagas();
            } else {
                vm.mostrarPerfil = true;
                vm.mostrarAvaliacoes = false;
            }
        }

        function abrirWhats(telefone) {
            $window.open(" https://wa.me/" + (telefone.numero.startsWith('55') ? telefone.numero : '55' + telefone.numero), "_blank")
        }


        inicarMensagem();
        carregarInformacoes();


    }
})();
