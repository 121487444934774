(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Parceiro', Parceiro);

    Parceiro.$inject = ['$resource', '$location'];

    function Parceiro($resource, $location) {
        var resourceUrl = 'api/parceiros/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }, 'getPorIdentificador': {
                url: 'api/parceiro-por-identificador/:identificador',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getParceirosPublicos': {
                url: 'api/parceiros-publicos',
                method: 'GET', isArray: true
            },
            'getParceirosPorSuperior': {
                url: 'api/parceiros-do-parceiro',
                method: 'GET', isArray: true
            },
            'getParceirosPorFilho': {
                url: 'api/parceiros-superiores',
                method: 'GET', isArray: true
            },
            'getParceirosHierarquia': {
                url: 'api/parceiros-hierarquia',
                method: 'GET', isArray: true
            },
            'getAdiministradoresParceiro': {
                url: 'api/admin-parceiros/:id',
                method: 'GET',
                isArray: true
            },
            'mudarSituacao': {
                url: 'api/mudar-situacao-parceiro',
                method: 'POST'
            },
            'atualizarComissao': {
                url: 'api/parceiro-comissao',
                method: 'PUT'
            }, 'atualizarSuperior': {
                url: 'api/parceiro-superior',
                method: 'PUT'
            },
            'adicionarParceiroAdministrador': {
                url: 'api/adicionar-administrador-parceiro',
                method: 'POST'
            },
            'removerParceiroAdministrador': {
                url: 'api/remover-administrador-parceiro',
                method: 'POST'
            },

            'criarParceiroPorUsuarioAutenciado': {
                url: 'api/criar-parceiro-por-usuario-autenticado',
                method: 'POST',
            },

            'getPublico': {
                url: 'api/parceiro-publico',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
