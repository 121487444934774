(function () {
    'use strict';

    var myApp = angular
        .module('mySkillsPersonalApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'angular-img-cropper',
            'ui.checkbox',
            'ui.utils.masks',
            'chart.js',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'oitozero.ngSweetAlert',
            'rzModule',
            'idf.br-filters',
            'angular-web-notification',
            'ui.tinymce',
            'slickCarousel',
            'color.picker',
            'ui.select',
            'ja.qr',
            'youtube-embed',
            'mapboxgl-directive'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
        mapboxgl.accessToken = 'pk.eyJ1IjoiZ3VzdGF2b3NjYXJwaW5pIiwiYSI6ImNreXcydGc4dTAyZGgycG44ZHVmYXphbWQifQ.qrAPcayDWkkAb_P7X5dWzQ';

    }





})();
