(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaVagaDialogController', PessoaVagaDialogController);

    PessoaVagaDialogController.$inject = ['$timeout', '$state', '$scope', '$stateParams', 'entity',
        'empregadorId', 'vagaEmpregadorId', 'Empregador', 'ParseLinks', 'ConfirmService', 'pagingParams',
        'AtributoGenerico', 'FormacaoAcademica', 'TipoStatus', '$uibModal', 'Pessoa', '$location', 'Vaga'];

    function PessoaVagaDialogController($timeout, $state, $scope, $stateParams, entity,
        empregadorId, vagaEmpregadorId, Empregador, ParseLinks, ConfirmService, pagingParams,
        AtributoGenerico, FormacaoAcademica, TipoStatus, $uibModal, Pessoa, $location, Vaga) {
        var vm = this;
        vm.page = 1;
        vm.allItemsPerPage = [10, 25, 50, 100, 250, 500];
        vm.itemsPerPage = 500;
        vm.totalItems = 0;

        vm.vagaEmpregadorId = vagaEmpregadorId;
        vm.empregadorId = empregadorId;
        vm.vaga = entity;
        vm.editaVaga = false;
        vm.atributos = [];
        vm.pessoasSelecionadas = [];
        vm.salvar = salvar;
        vm.transition = transition;
        vm.verPerfilPremium = verPerfilPremium;
        vm.calculateAge = calculateAge;
        vm.carregarVagas = carregarVagas;
        vm.savePessoasVaga = savePessoasVaga;
        vm.selecionarPessoa = selecionarPessoa;
        vm.avancarVarios = avancarVarios;
        vm.voltarVarios = voltarVarios;
        vm.alterarSituacaoPessoa = alterarSituacaoPessoa;
        vm.limparFiltros = limparFiltros;
        vm.adicionarOrdem = adicionarOrdem;
        vm.limparOrdem = limparOrdem;
        vm.addSaldo = addSaldo;
        vm.verAvaliacoes = verAvaliacoes;
        vm.verPareceres = verPareceres;
        vm.moverPessoa = moverPessoa;
        vm.carregarTodasVagas = carregarTodasVagas;
        vm.imprimir = imprimir;
        vm.copyToClipboard = copyToClipboard;

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        vm.ordens = [
            {descricao: 'Ultima Alteracao', value: '-atualizadoEm'},
            {descricao: 'Premium', value: '-premium'},
            {descricao: 'Nome', value: 'nome'},
            {descricao: 'Cidade', value: 'cidade'},
            {descricao: 'Disponibilidade', value: 'situacao'},
            {descricao: 'Idade', value: '-nascimento'},
            {descricao: 'Formação Acadêmica', value: 'ultimaGraduacao'},
            {descricao: 'Cargo', value: 'ultimoCargo'},
            {descricao: 'Ranking', value: 'ranking'},
        ];
        vm.order = ['-premium', '-atualizadoEm', 'cidade'];

        function adicionarOrdem(ordem) {
            if (vm.order.indexOf(ordem.value) >= 0) {
                vm.order.splice(vm.order.indexOf(ordem.value), 1);
            } else {
                vm.order.push(ordem.value);
            }
        }

        function limparOrdem() {
            vm.order = [];
        }

        function limparFiltros() {
            vm.filtros = {
                vagaCorporateId: vm.vaga.id, page: vm.page - 1, size: vm.itemsPerPage, empregadorId: vm.empregadorId,
                situacao: vm.tiposStatus && vm.tiposStatus[1] ? vm.tiposStatus[1].descricao : null
            };
        }


        function copyToClipboard(url) {
            url = "https://personal.myskills.com.br/#/vaga/detalhes/" + vm.vaga.id;

            if (window.clipboardData && window.clipboardData.setData) {
                // IE specific code path to prevent textarea being shown while dialog is visible.
                return clipboardData.setData("Text", url);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = url;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        }

        function salvar() {
            if (vm.vagaEmpregadorId) {
                var agrupador = {vinculo: vm.vagaEmpregadorId, tipo: 'VAGA', atributos: []};
                for (var i = 0; i < vm.atributos.length; i++) {
                    var atributo = vm.atributos[i];
                    agrupador.atributos.push({
                        atributo: atributo,
                        audio: atributo.audio,
                        tipoVinculo: agrupador.tipo,
                        vinculo: agrupador.vinculo,
                        valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                    });

                }
                AtributoGenerico.saveVinculos(agrupador, function (data) {
                    vm.editaVaga = false;
                });
            }
        }


        function selecionarPessoa(pessoa) {
            pessoa.selecionada = !pessoa.selecionada;
            if (vm.pessoasSelecionadas.indexOf(pessoa) >= 0) {
                vm.pessoasSelecionadas.splice(vm.pessoasSelecionadas.indexOf(pessoa), 1);
            } else {
                vm.pessoasSelecionadas.push(pessoa);
            }
        }

        function avancarVarios() {
            vm.proximoCodigo = vm.situacao.codigo + 1;
            for (var i = 0; i < vm.pessoasSelecionadas.length; i++) {
                var pessoa = vm.pessoasSelecionadas[i];
                pessoa.classificacao = vm.situacoes[vm.situacao.codigo + 1].nome;
                savePessoasVaga(pessoa);
            }
            carregarVagas();
        }

        function voltarVarios() {
            vm.proximoCodigo = vm.situacao.codigo - 1;
            for (var i = 0; i < vm.pessoasSelecionadas.length; i++) {
                var pessoa = vm.pessoasSelecionadas[i];
                pessoa.classificacao = vm.situacoes[vm.situacao.codigo - 1].nome;
                savePessoasVaga(pessoa);
            }
            carregarVagas();
        }

        function alterarSituacaoPessoa(pessoa, situacao) {
            pessoa.classificacao = situacao.nome;
            savePessoasVaga(pessoa);
            carregarVagas();
        }


        function carregarVagas() {
            vm.pessoasSelecionadas = [];
            vm.filtros.page = vm.page - 1;
            vm.filtros.pageSize = vm.itemsPerPage;
            Empregador.getPessoasVaga(vm.filtros, onSuccess);
        }


        function buscarSaldo() {
            Empregador.getSaldo({}, function (data) {
                vm.saldo = data.saldo;
            });
        }


        function initSituacoes() {
            vm.situacoes = [
                {
                    codigo: 0,
                    nome: 'DESCLASSIFICADO',
                    descricao: 'Desclassificado(s)',
                    pessoas: []
                },
                {codigo: 1, nome: 'CANDIDATO', descricao: 'Inscrito(s)', pessoas: []},
                {
                    codigo: 2,
                    nome: 'AVALIACAO',
                    descricao: 'Avaliação',
                    pessoas: []
                }, {codigo: 3, nome: 'CLASSIFICADO', descricao: 'Classificado(s)', pessoas: []},
                {
                    codigo: 4,
                    nome: 'APROVADO',
                    descricao: 'Aprovado(s)',
                    pessoas: []
                },
                {codigo: 5, nome: 'FORMALIZACAO', descricao: 'Formalização', pessoas: []},
                {codigo: 6, nome: 'CONTRATADO', descricao: 'Contratado(s)', pessoas: []},
                {
                    codigo: 7,
                    nome: 'DESISTENTE',
                    descricao: 'Desistente(s)',
                    pessoas: []
                }];
        }

        function onSuccess(data) {
            // vm.links = ParseLinks.parse(headers('link'));
            // vm.totalItems = headers('X-Total-Count');
            initSituacoes();

            vm.totalItems = data.total;
            vm.pessoas = data.pessoas;
            for (var i = 0; i < vm.pessoas.length; i++) {
                var pessoa = vm.pessoas[i];
                for (var j = 0; j < vm.situacoes.length; j++) {
                    var situacao = vm.situacoes[j];
                    if (pessoa.classificacao === situacao.nome) {
                        situacao.pessoas.push(pessoa);
                    }
                }

            }
            if (vm.filtros.pcd) {
                vm.filtrouPcd = true;
            }
            vm.situacao = vm.situacoes[vm.proximoCodigo ? vm.proximoCodigo : 1];
        }

        function savePessoasVaga(pessoaVaga) {
            Empregador.savePessoasVaga(pessoaVaga);
        }

        function transition() {
            carregarVagas();
        }


        function verPerfilPremium(pessoa) {
            if (pessoa.premium) {
                window.open(' #/completo/' + pessoa.pessoaId, '_blank');
            } else {

                Empregador.temPerfil({idPessoa: pessoa.pessoaId}, function (result2) {
                    if (result2 && result2.id) {
                        window.open(' #/completo/' + pessoa.pessoaId, '_blank');
                    } else {
                        ConfirmService.confirm({
                                title: "Perfil Premium",
                                text: "Deseja adquirir o perfil completo de " + pessoa.nome + "? Ao confirmar será debitado o valor de 1 crédito do seu saldo.",
                                confirm: function () {
                                    Empregador.comprarPerfil({id: pessoa.pessoaId}, function (data) {
                                        if (data && data.id) {
                                            carregarVagas();
                                            buscarSaldo();
                                            window.open(' #/completo/' + pessoa.pessoaId, '_blank');
                                        } else {
                                            alert("Não foi possível ver o perfil premium, verifique seu saldo");
                                        }
                                    }, function (error) {
                                        alert("Não foi possível ver o perfil premium, verifique seu saldo");
                                    });
                                },
                                cancel: function () {
                                }
                            }
                        );
                    }
                });


            }
        }

        function calculateAge(birthday) {
            if (!birthday) {
                return;
            }
            return new Date(new Date() - new Date(birthday)).getFullYear() - 1970;
        }

        function loadStatus(callBack) {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
                callBack();
            });
        }

        function loadFormacao() {
            FormacaoAcademica.getTipoFormacao({}, function (data) {
                vm.tiposFormacao = data;
            });
        }

        function addSaldo() {
            if (vm.empregadorId) {
                $uibModal.open({
                    templateUrl: 'app/entities/empregador/empregador-escolher-plano-dialog.html',
                    controller: 'EmpregadorEscolherPlanoController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['PlanoCredito', function (PlanoCredito) {
                            //return User.get({login: $stateParams.login});
                            return null;
                        }]
                    }
                }).result.then(function (plano) {
                    console.log("plano: ", plano);
                    $state.go('comprar-saldo', {id: plano.id}, {reload: true});

                }, function () {
                    //$state.go('^');
                    $state.go('comprar-saldo', null, {reload: true});
                });
            }
        }

        function verAvaliacoes(pessoa) {
            $uibModal.open({
                templateUrl: 'app/entities/questionario/questionario-pessoa.html',
                controller: 'QuestionarioPessoaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    pessoaId: ['PlanoCredito', function (PlanoCredito) {
                        //return User.get({login: $stateParams.login});
                        return pessoa.pessoaId;
                    }]
                }
            });
        }

        function imprimir(pessoa) {
            if (pessoa.quantidadeParecer) {
                ConfirmService.confirm({
                        title: "Imprimir",
                        text: "Quer adicionar os pareceres à impressão?",
                        confirmText: "Sim",
                        cancelText: "Não",
                        confirm: function () {
                            vm.carregando = true;
                            Pessoa.getUserByPessoaId({id: pessoa.pessoaId}, function (data) {
                                Empregador.getObservacoesPessoa({
                                    pessoaId: data.id,
                                    empregadorId: vm.empregadorId
                                }, function (data) {
                                    Pessoa.imprimir(pessoa, data);
                                    vm.carregando = false;
                                }, function (error) {
                                    vm.carregando = false;
                                });
                            });
                        },
                        cancel: function () {
                            Pessoa.imprimir(pessoa, []);
                        }
                    }
                );
            } else {
                Pessoa.imprimir(pessoa, []);
            }

        }

        function moverPessoa(pessoa, vaga) {
            vaga.pessoaId = pessoa.pessoaId;
            Vaga.moverPessoa({pessoaId: pessoa.pessoaId, vagaId: vaga.vagaCorporateId}, function () {
                $state.go("empregador-vaga", {
                    vagaEmpregadorId: vaga.vagaEmpregadorId,
                    id: vaga.vagaCorporateId,
                    empregadorId: vm.empregadorId
                });
            });

        }

        function carregarTodasVagas() {
            if (!vm.todasVagas) {
                vm.todasVagas = [];
                Empregador.getVagasEmpresa({
                    empregadorId: vm.empregadorId,
                    cidades: [],
                    sort: 'ev.vaga.titulo asc'
                }, function (data) {
                    for (var i = 0; i < data.length; i++) {
                        var vaga = data[i];
                        if (vaga.ativo && vaga.vagaEmpregadorId != vm.vagaEmpregadorId) {
                            vm.todasVagas.push(vaga);
                        }
                    }
                });
            }
        }

        function verPareceres(pessoa) {
            $uibModal.open({
                templateUrl: 'app/entities/empregador/parecer.html',
                controller: 'ParecerPessoaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    user: ['Pessoa', function (Pessoa) {
                        return Pessoa.getUserByPessoaId({id: pessoa.pessoaId}).$promise;
                    }],
                    empregadorId: function () {
                        return vm.empregadorId;
                    }
                }
            }).result.then(function () {
                vm.proximoCodigo = vm.situacao.codigo;
                carregarVagas();
            }, function () {
                vm.proximoCodigo = vm.situacao.codigo;
                carregarVagas();
            });

        }

        loadStatus(carregarTudo);

        function carregarTudo() {
            limparFiltros();
            if (pagingParams.cidades) {
                vm.filtros.cidade = pagingParams.cidades;
            }
            carregarVagas();
            buscarSaldo();
            loadFormacao();
        }


    }
})();
