(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$rootScope', '$http', '$cookies', '$translate', '$timeout', '$state', 'Auth',
        'LoginService', 'Principal', 'ConfirmService', 'pagingParams', 'Termo', '$uibModal', 'Pessoa', '$uibModalInstance', '$location', 'Parceiro'];

    function RegisterController($rootScope, $http, $cookies, $translate, $timeout, $state, Auth,
        LoginService, Principal, ConfirmService, pagingParams, Termo, $uibModal, Pessoa, $uibModalInstance, $location, Parceiro) {
        var vm = this;
        vm.authenticationError = {};
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.abreTermo = abreTermo;
        vm.existCpf = existCpf;
        vm.goLogin = goLogin;
        vm.requestResetPassword = requestResetPassword;
        vm.registerAccount = {};
        vm.registerAccount.emailConfirm = '';
        vm.success = null;
        vm.idParceiro = pagingParams.idParceiro;
        vm.carregarEstados = carregarEstados;
        vm.carregarCidades = carregarCidades;
        vm.isModal = $uibModalInstance != null;
        var host = $location.host();
        vm.subdomain = host.split('.')[0];
        vm.idParceiro = pagingParams.idParceiro;

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
                vm.idParceiro = vm.parceiro.id;
            });
        }
        carregarParceiro();

        Termo.query({
            page: 0,
            size: vm.itemsPerPage,
        }, function (data) {
            vm.termos = data;
        });

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated();
            if (vm.isAuthenticated) {
                $state.go('home');
            }
        });


        $timeout(function () {
            angular.element('#login').focus();
        });

        function abreTermo() {
            $uibModal.open({
                templateUrl: 'app/account/register/termos-aceite-dialog.html',
                controller: 'TermoAceiteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            }).result.then(function (result) {
                vm.concordou = result && result.aceite;
            }, function () {

            });
        }

        function carregarPaises() {
            return $http({
                url: '/api/todos-paises',
                method: 'GET'
            }).then(function (data) {
                vm.paises = data.data;
                for (var i = 0; i < vm.paises.length; i++) {
                    var pais = vm.paises[i];
                    if (pais.codigo == 'BRA') {
                        vm.brasil = pais;
                        vm.localidade = {pais: vm.brasil};
                        carregarEstados();
                        break;
                    }
                }
            });
        }

        carregarPaises();

        function carregarEstados() {
            return $http({
                url: '/api/todos-estados',
                method: 'GET'
            }).then(function (data) {
                vm.estados = data.data;
            });
        }

        function carregarCidades() {
            return $http({
                url: '/api/todas-cidades',
                method: 'GET',
                params: {parameter: vm.localidade.estado.sigla}
            }).then(function (data) {
                vm.cidades = data.data;
            });
        }

        function hideEmail(email) {
            var emailSplit = email.split("@");
            var size = Math.round(emailSplit[0].length / 2);
            var emailPartOne = emailSplit[0].slice(size);
            var emailPartTwo = emailSplit[1];
            var newEmail = "";

            for (var i = 1; i <= size; i++) {
                newEmail += "*";
            }

            newEmail += emailPartOne + "@" + emailPartTwo;
            return newEmail;
        }

        function existCpf() {

            if (vm.localidade.pais.codigo == 'BRA') {
                Pessoa.getPessoaByCpf(vm.registerAccount, getPessoaByCpf, register);

                function getPessoaByCpf(pessoa) {
                    if (pessoa === null) {
                        register();
                    } else {
                        lancarDialog('Este Cpf já está cadastrado através do e-mail: ' + hideEmail(pessoa.user.email));
                        $state.go('login');
                    }
                }
            } else {
                register();
            }
        }

        function register() {
            vm.registerAccount.langKey = $translate.use();

            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            vm.registerAccount.login = vm.registerAccount.email;
            if (vm.idParceiro) {
                vm.registerAccount.idParceiro = vm.idParceiro;
            }
            if (vm.localidade.cidade) {
                vm.registerAccount.cidade = vm.localidade.cidade.nome;
            }
            if (vm.localidade.estado) {
                vm.registerAccount.estado = vm.localidade.estado.nome;
            }
            if (vm.localidade.pais) {
                vm.registerAccount.pais = vm.localidade.pais.nome;
            }

            if (vm.registerAccount.email !== vm.registerAccount.emailConfirm) {

                ConfirmService.confirm({
                        title: "Não é possivel continuar!",
                        text: "Os emails informados não são iguais, por favor, verifique",
                        confirm: function () {
                        },
                        cancel: function () {
                        }
                    }
                );
                return;
            }

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';

                Auth.login({
                    username: vm.registerAccount.login,
                    password: vm.registerAccount.password,
                }).then(function () {
                    $cookies.putObject("authenticationError", false);
                    $rootScope.$broadcast('authenticationSuccess');
                    if (vm.isModal) {
                        $uibModalInstance.close({});
                    } else {
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                            $state.go(previousState.name, previousState.params);
                        } else {
                            $state.go('home');
                        }
                    }
                }).catch(function () {
                    $cookies.putObject("authenticationError", true);
                    $state.go('login');
                });

            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                    vm.errorEmailExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else {
                    vm.error = 'ERROR';
                    lancarDialog("Erro ao tentar salvar o cadastro");
                }
            });
        }

        function lancarDialog(texto) {
            ConfirmService.confirm({
                    title: "Atenção!",
                    text: texto,
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

        function goLogin() {
            $state.go('login');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();
