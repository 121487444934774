(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ExternoController', ExternoController);

    ExternoController.$inject = ['$state', 'LoginService', 'Principal'];

    function ExternoController($state, LoginService, Principal) {
        var vm = this;
        vm.login = login;
        function login() {
            LoginService.open();
        }

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated;
            if (vm.isAuthenticated) {
                $state.go('home');
            }
        });
    }
})();
