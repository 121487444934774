(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Produto', Produto);

    Produto.$inject = ['$resource', 'DateUtils'];

    function Produto($resource, DateUtils) {
        var resourceUrl = 'api/produtos/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'POST', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getProdutosUsuario': {
                method: 'GET',
                isArray: true,
                url: 'api/produtos-usuario',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getProdutosPessoa': {
                method: 'GET',
                isArray: true,
                url: 'api/produtos-pessoa/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getChaveCriptografia': {
                method: 'GET',
                url: 'api/chave-criptografia',
                transformRequest: function (data) {
                    return data;
                }
            },

            'iniciarRequisicaoVenda': {
                url: 'api/produto/registrar-venda',
                method: 'POST',
                transformRequest: function (data) {
                    data.criadoEm = DateUtils.convertLocalDateToServer(data.criadoEm);
                    return angular.toJson(data);
                }
            },

            'realizarPagamento': {
                url: 'api/realizar-pagamento',
                method: 'POST',
                transformRequest: function (data) {
                    data.criadoEm = DateUtils.convertLocalDateToServer(data.criadoEm);
                    return angular.toJson(data);
                }
            },

            'consultarQrCode': {
                url: 'api/consultar-qr-code/:id',
                method: 'GET'
            },

            'capturarVenda': {
                url: 'api/produto/capturar-venda',
                method: 'POST',
                transformRequest: function (data) {
                    data.criadoEm = DateUtils.convertLocalDateToServer(data.criadoEm);
                    return angular.toJson(data);
                }
            },
            'checarCupomDesconto': {
                url: 'api/produto/check-cupom-desconto',
                method: 'POST',
                transformRequest: function (data) {
                    data.validadeCupom = DateUtils.convertLocalDateToServer(data.validadeCupom);
                    return angular.toJson(data);
                }
            },
            'update': {method: 'PUT'},

        });
    }
})();
