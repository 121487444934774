(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('AtributoGenerico', AtributoGenerico);

    AtributoGenerico.$inject = ['$resource'];

    function AtributoGenerico($resource) {
        var resourceUrl = 'api/atributo-generico/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVinculos': {
                url: 'api/atributos-vinculo',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'saveVinculos': {
                url: 'api/atributos-vinculo',
                method: 'POST',
            },
            'update': {method: 'PUT'}
        });
    }
})();
