(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .run(adminRouterConfig);

    adminRouterConfig.$inject = ['$rootScope', '$location'];

    function adminRouterConfig($rootScope, $location) {
        $rootScope.$on("$routeChangeStart", function (event, next, current) {

            Principal.hasAuthority("ROLE_ADMIN")
                .then(function (result) {
                    if (result) {
                     console.log("È ADMIN");
                    } else {
                        console.log("NÂO È ADMIN");
                    }
                });
        });
    }
})();
