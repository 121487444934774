(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('InstituicaoEnsinoDeleteController',InstituicaoEnsinoDeleteController);

    InstituicaoEnsinoDeleteController.$inject = ['$uibModalInstance', 'entity', 'InstituicaoEnsino'];

    function InstituicaoEnsinoDeleteController($uibModalInstance, entity, InstituicaoEnsino) {
        var vm = this;

        vm.instituicaoEnsino = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InstituicaoEnsino.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
