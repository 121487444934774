(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorExtratoDialogController', EmpregadorExtratoDialogController);

    EmpregadorExtratoDialogController.$inject = ['$uibModalInstance', '$state', 'entity', 'Empregador', 'PlanoCredito'];

    function EmpregadorExtratoDialogController($uibModalInstance, $state, entity, Empregador, PlanoCredito) {
        var vm = this;

        vm.empregador = entity;
        vm.clear = clear;
        vm.imprimirMovimmentacao = imprimirMovimmentacao;
        vm.imprimirExtrato = imprimirExtrato;
        vm.diminuirDiasDoFiltro = diminuirDiasDoFiltro;
        vm.carregarMovimentacoes = carregarMovimentacoes;
        vm.movimentacoes = [];
        vm.dia = 30;
        vm.dias = [15, 30, 60, 90];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function diminuirDiasDoFiltro(dias) {
            vm.dia = dias;
            vm.fim = new Date();
            vm.inicio = new Date(new Date().setDate(new Date().getDate() - dias));
            carregarMovimentacoes();
        }

        diminuirDiasDoFiltro(90);

        function carregarMovimentacoes() {
            Empregador.getMovimentacoes({id: vm.empregador.id, inicio: vm.inicio, fim: vm.fim, page: 0, size: 50}, function (resp) {
                console.log("resp", resp);
                vm.movimentacoes = resp;
            }, function (error) {
                console.log("resp", error);
            });
        }

        function buscarSaldo() {
            if (vm.empregador.id) {
                Empregador.getSaldo({id: vm.empregador.user.id}, function (data) {
                    vm.saldo = data.saldo;
                });
            }
        }

        function imprimirMovimmentacao(id) {
            Empregador.imprimirMovimentacao({id: vm.empregador.id}, function (data) {
                console.log("data ", data);
                vm.arquivo = data;

                var fileName = vm.arquivo.nome;
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                var file = getBlobFromBase64(vm.arquivo);
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName + ".pdf";
                a.click();

            });
        }

        function imprimirExtrato() {
            Empregador.imprimirExtrato({id: vm.empregador.id, inicio: vm.inicio, fim: vm.fim, page: 0, size: 50}, function (data) {
                console.log("data ", data);
                vm.arquivo = data;

                var fileName = vm.arquivo.nome;
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                var file = getBlobFromBase64(vm.arquivo);
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName + ".pdf";
                a.click();

            });
        }

        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            return blob;
        }

        buscarSaldo();

    }
})();
