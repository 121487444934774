(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('SolicitacaoSaqueController', SolicitacaoSaqueController);

    SolicitacaoSaqueController.$inject = ['$scope', '$state', '$filter', 'Venda', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService'];

    function SolicitacaoSaqueController($scope, $state, $filter, Venda, ParseLinks,
                                        AlertService, pagingParams, paginationConstants, ConfirmService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.efetivar = efetivar;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function efetivar(solicitacaoSaque) {

            ConfirmService.swal({
                    title: "Atenção",
                    text: "Você tem certeza que deseja efetivar o saque?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1acfb0",
                    confirmButtonText: "Efetivar o valor de " + $filter('currency')(solicitacaoSaque.valor),
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Venda.efetivarSaque({id: solicitacaoSaque.id}, confirmSaque);
                    }
                })

        }

        function confirmSaque() {
            loadAll();
            ConfirmService.swal({
                title: "Efetivado",
                text: "O valor foi debitado nas transaçoes do parceiro",
                timer: 3000,
                type: "success",
                showConfirmButton: false,
            });
        }

        function loadAll() {

            Venda.getSolicitacoesSaque({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                filtro: vm.searchQuery ? vm.searchQuery : "",
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.solicitacoes = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
