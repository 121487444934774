(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroController', ParceiroController);

    ParceiroController.$inject = ['$scope', '$state', 'Parceiro', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService', '$uibModal'];

    function ParceiroController($scope, $state, Parceiro, ParseLinks, AlertService, pagingParams, paginationConstants, ConfirmService, $uibModal) {
        var vm = this;

        vm.definirComissao = definirComissao;
        vm.definirSuperior = definirSuperior;
        vm.vincularParceiro = vincularParceiro;
        vm.loadAll = loadAll;
        vm.alterarSituacaoParceiro = alterarSituacaoParceiro;

        loadAll();

        function loadAll() {
            Parceiro.getParceirosHierarquia({}, function (data) {
                vm.parceirosHierarquia = data;
            });
        }


        function lancarAlertaErro() {
            ConfirmService.confirm({
                    title: "Atenção!",
                    text: "Erro ao salvar o registro",
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

        function lancarAlertaConfirmacao() {
            ConfirmService.confirm({
                    title: "Salvo!",
                    text: "Registro salvo com sucesso",
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

        function definirComissao(parceiro) {
            $uibModal.open({
                templateUrl: 'app/entities/parceiro/parceiro-comissao-dialog.html',
                controller: 'ParceiroComissaoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return parceiro;
                    }
                }
            }).result.then(function (comissao) {
                parceiro.comissao = comissao;
                Parceiro.atualizarComissao(parceiro, function () {
                    lancarAlertaConfirmacao();
                    loadAll();
                }, function (error) {
                    lancarAlertaErro();
                    loadAll();
                });
            });
        }


        function definirSuperior(parceiro) {
            $uibModal.open({
                templateUrl: 'app/entities/parceiro/parceiro-superior-dialog.html',
                controller: 'ParceiroSuperiorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return parceiro;
                    }
                }
            }).result.then(function (parceiro) {
                Parceiro.atualizarSuperior(parceiro, function () {
                    lancarAlertaConfirmacao();
                    loadAll();
                }, function (error) {
                    lancarAlertaErro();
                    loadAll();
                });
            });
        }

        function vincularParceiro(parceiro) {
            $uibModal.open({
                templateUrl: 'app/entities/parceiro/parceiro-empresa-filiada.html',
                controller: 'ParceiroEmpresaFiliadaController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return parceiro;
                    }
                }
            }).result.then(function (parceiro) {
                Parceiro.atualizarSuperior(parceiro, function () {
                    lancarAlertaConfirmacao();
                    loadAll();
                }, function (error) {
                    lancarAlertaErro();
                    loadAll();
                });
            });
        }

        function alterarSituacaoParceiro(parceiro, situacao) {
            var parceiroDto = {id: parceiro.id, situacao: situacao};
            Parceiro.mudarSituacao(parceiroDto, function (resp) {
                parceiro.situacao = resp.situacao;
                lancarAlertaConfirmacao();
                loadAll();
            }, function (error) {
                lancarAlertaErro();
            });
        }
    }
})();
