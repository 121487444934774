(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PublicacaoController', PublicacaoController);

    PublicacaoController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Pessoa',
        'Publicacao', 'Upload', 'Email', 'AlertService', 'ParseLinks'];

    function PublicacaoController($scope, Principal, LoginService, $state, Pessoa,
                                  Publicacao, Upload, Email, AlertService, ParseLinks) {
        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.predicate = 'id';
        vm.publicar = publicar;
        vm.loadEmails = loadEmails;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.emailsSelecionados = [];
        vm.queryEmail = "";
        vm.page = 1;
        vm.itemsPerPage = 50;

        vm.novaPublicacao = "Compartilhe algo ...";


        vm.tinymceOptions = {
            selector: 'textarea#local-upload',
            plugins: 'link image code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | image code',
            images_upload_handler: function (blobInfo, success, failure) {
                console.log(blobInfo);
                upload(blobInfo, success);
            }
        };

        loadEmails();

        function loadEmails() {

            Email.query({
                endereco: vm.queryEmail,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.emails = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function upload(blobInfo, callback) {
            console.log(" iniciou uma chamada ", blobInfo.blob());
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: blobInfo.blob(),
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                callback(resp.data.conteudoFormatado);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                vm.admin = !!result;
            });


        function publicar() {
            console.log("vm.novaPublicacao", vm.novaPublicacao);
            Publicacao.save({
                titulo: vm.titulo,
                texto: vm.novaPublicacao,
                pessoa: {id: vm.pessoa.pessoaId},
                publicadoEm: new Date(),
                tipoPublicacao: vm.enviarPorEmail ? 'EMAIL_GERAL' : vm.enviarPorEmailParaAlguns ? 'EMAIL' : 'PESSOAL',
                emails: vm.emailsSelecionados
            }, function (data) {
                $state.go('home')
            });

        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account) {
                    Pessoa.getPerfilByUser({}, function (pessoa) {
                        vm.pessoa = pessoa;
                    })
                }
            });
        }

    }
})
();
