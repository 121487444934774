(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('RedeSocialController', RedeSocialController);

    RedeSocialController.$inject = ['$scope', '$state', 'RedeSocial', 'RedeSocialSearch'];

    function RedeSocialController ($scope, $state, RedeSocial, RedeSocialSearch) {
        var vm = this;
        
        vm.redeSocials = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            RedeSocial.query(function(result) {
                vm.redeSocials = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            RedeSocialSearch.query({query: vm.searchQuery}, function(result) {
                vm.redeSocials = result;
            });
        }    }
})();
