(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pessoa', {
                parent: 'home',
                url: '/pessoa?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.pessoa.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pessoa/pessoas.html',
                        controller: 'PessoaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pessoa');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pessoa-filtro', {
                parent: 'home',
                url: '/pessoa-filtro?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.pessoa.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pessoa-filtro/pessoas.html',
                        controller: 'PessoaFiltroController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pessoa');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pessoa-premium', {
                parent: 'app',
                url: '/completo/{id}',
                data: {
                    authorities: ['ROLE_EMPREGADOR', 'ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.pessoa.detail.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/layouts/navbar/navbar.html',
                        controller: 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/entities/pessoa/pessoa-detail.html',
                        controller: 'PessoaDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pessoa');
                        $translatePartialLoader.addPart('remuneracao');
                        $translatePartialLoader.addPart('motivoDesligamento');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Pessoa', function ($stateParams, Pessoa) {
                        return Pessoa.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('pessoa-detail', {
                parent: 'app',
                url: '/perfil/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.pessoa.detail.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/layouts/navbar/navbar.html',
                        controller: 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/entities/pessoa/pessoa-simples.html',
                        controller: 'PessoaSimplesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pessoa');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Pessoa', function ($stateParams, Pessoa) {
                        return Pessoa.getSimples({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
    }

})();
