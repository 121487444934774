(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('FormacaoAcademicaDetailController', FormacaoAcademicaDetailController);

    FormacaoAcademicaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FormacaoAcademica', 'PerfilProfissional', 'InstituicaoEnsino'];

    function FormacaoAcademicaDetailController($scope, $rootScope, $stateParams, previousState, entity, FormacaoAcademica, PerfilProfissional, InstituicaoEnsino) {
        var vm = this;

        vm.formacaoAcademica = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:formacaoAcademicaUpdate', function(event, result) {
            vm.formacaoAcademica = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
