(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Curso', Curso);

    Curso.$inject = ['$resource'];

    function Curso($resource) {
        var resourceUrl = 'api/cursos/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                url: 'api/cursos-parceiro/:id',
                method: 'GET', isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'participar': {method: 'POST', url: '/api/cursos/participar'},
            'participando': {method: 'GET', url: '/api/cursos/participando/:id'},
            'removerParticipacao': {method: 'GET', url: '/api/cursos/remover-participacao/:id'}
        });
    }
})();
