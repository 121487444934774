(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ResultadoController', ResultadoController);

    ResultadoController.$inject = ['$filter', '$state', 'entity', 'Principal', 'Questionario'];

    function ResultadoController($filter, $state, entity, Principal, Questionario) {
        var vm = this;
        vm.resultado = entity;
        vm.imprimir = imprimir;
        vm.gerandoPdf = false;

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated;
        });

        vm.colors = [
            {
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(255,51,0,0.5)',
                pointBackgroundColor: 'rgba(255,51,0,0.5)',
                pointHoverBorderColor: 'rgba(255,51,0,0.5)'
            },
            {
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,102,255,0.5)',
                pointBackgroundColor: 'rgba(0,102,255,0.5)',
                pointHoverBorderColor: 'rgba(0,102,255,0.5)'
            },
            {
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
                pointBackgroundColor: 'rgba(0,0,0,0)',
                pointHoverBorderColor: 'rgba(0,0,0,0)'
            }
        ];

        vm.colors2 = [
            {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderColor: 'rgba(255,255,255,1)'
            },
            {
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)'
            },
            {
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)'
            }
        ];
        vm.radar = {
            labels: [],
            data: [
                [],
                [],
                []
            ],
            colours: vm.colors
        };

        vm.radar2 = {
            labels: [],
            data: [
                [],
                [],
                []
            ],
            colours: vm.colors
        };

        vm.line = {
            labels: [],
            data: [
                [], [],
                []
            ],
            colours: vm.colors
        };

        vm.line2 = {
            labels: [],
            data: [
                [], [],
                []
            ],
            colours: vm.colors
        };

        vm.bar = {
            labels: [],
            data: [
                [], [],
                []
            ],
            colours: vm.colors2,
            series: ["Base", "Atual", "Domínio"]
        };

        vm.bar2 = {
            labels: [],
            data: [
                [], [],
                []
            ],
            colours: vm.colors2,
            series: ["Base", "Atual", "Domínio"]
        };


        angular.forEach(vm.resultado.competencias, function (competencia) {
            vm.radar.labels.push(competencia.competencia);
            vm.radar.data[0].push(competencia.valor);
            vm.radar.data[1].push(100);
            vm.radar.data[2].push(1);

            vm.line.labels.push(vm.resultado.competencias.indexOf(competencia) + 1);
            vm.line.data[0].push(competencia.valor);
            vm.line.data[1].push(100);
            // vm.line.data[2].push(1);

            vm.bar2.labels.push(competencia.competencia);
            vm.bar2.data[0].push(0);
            vm.bar2.data[1].push(competencia.valor);
            // vm.bar2.data[2].push(100);
        });
        vm.mediaGeral = 0.0;
        angular.forEach(vm.resultado.relevancias, function (relevancia) {
            vm.bar.labels.push(relevancia.relevancia);
            vm.bar.data[0].push(0);
            vm.bar.data[1].push(relevancia.valor);
            // vm.bar.data[2].push(100);

            vm.radar2.labels.push(relevancia.relevancia);
            vm.radar2.data[0].push(relevancia.valor);
            vm.radar2.data[1].push(100);
            vm.radar2.data[2].push(1);

            vm.line2.labels.push(relevancia.relevancia);
            vm.line2.data[0].push(relevancia.valor);
            vm.line2.data[1].push(100);
            vm.line2.data[2].push(1);

            vm.mediaGeral = vm.mediaGeral + relevancia.valor;

            angular.forEach(vm.resultado.pareceres, function (parecer) {
                if (parecer.relevancia === relevancia.relevancia) {
                    parecer.valor = relevancia.valor;
                    parecer.mediaGeral = relevancia.valor;
                    definirCoresIcones(parecer);
                }
            })
        });
        vm.mediaGeral = vm.mediaGeral / vm.resultado.relevancias.length;

        function definirCoresIcones(obj) {
            if (obj.mediaGeral <= 10) {
                obj.situacao = 'Fraco';
                obj.bg = 'red-bg';
                obj.icon = 'fa-thumbs-down';
            } else if (obj.mediaGeral <= 30) {
                obj.situacao = 'Baixo';
                obj.bg = 'red-bg';
                obj.icon = 'fa-thumbs-down';
            } else if (obj.mediaGeral <= 60) {
                obj.situacao = 'Mediano';
                obj.bg = 'yellow-bg';
                obj.icon = 'fa-thumbs-up';
            } else if (obj.mediaGeral <= 80) {
                obj.situacao = 'Aceitável';
                obj.bg = 'navy-bg';
                obj.icon = 'fa-thumbs-up';
            } else if (obj.mediaGeral <= 95) {
                obj.situacao = 'Ideal';
                obj.bg = 'lazur-bg';
                obj.icon = 'fa-star';
            } else if (obj.mediaGeral <= 100) {
                obj.situacao = 'Excelente';
                obj.bg = 'lazur-bg';
                obj.icon = 'fa-trophy';
            }
        }

        definirCoresIcones(vm);

        function imprimir() {
            Questionario.imprimir(vm, $filter, $state);
        }
    }


})();
