(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('TermoDialogController', TermoDialogController);

    TermoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'Termo'];

    function TermoDialogController($timeout, $scope, $stateParams, $state, entity, Termo) {
        var vm = this;

        vm.termo = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("termo")
        }

        function save() {
            vm.isSaving = true;
            if (vm.termo.id !== null) {
                Termo.update(vm.termo, onSaveSuccess, onSaveError);
            } else {
                Termo.save(vm.termo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:termoUpdate', result);
            $state.go("termo");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
