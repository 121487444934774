(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('FotoRegistroController', FotoRegistroController);

    FotoRegistroController.$inject = ['$scope', '$q', 'Principal', 'Pessoa', '$state', 'Upload', '$window', '$uibModalInstance', 'cropSize'];

    function FotoRegistroController($scope, $q, Principal, Pessoa, $state, Upload, $window, $uibModalInstance, cropSize) {
        var vm = this;
        vm.clear = clear;
        vm.submit = submit;
        vm.rotateImage = rotateImage;
        vm.ok = ok;
        vm.progressPercentage = null;
        vm.carregando = false;
        vm.cropSize = cropSize;
        vm.fileOriginal = null;
        vm.cropper = {sourceImage: null, croppedImage: null};
        vm.bounds = {left: 0, right: 0, top: 0, bottom: 0};



        function clear() {
            $uibModalInstance.dismiss('close');
        }


        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function rotateImage() {
            vm.carregando = true;
            convertImage(function () {
                vm.carregando = false;
            });
        }

        function convertImage(callback) {
            var img = new Image();
            img.src = vm.cropper.sourceImage;
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.drawImage(img, 0, 0);
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            vm.cropper.sourceImage = canvas.toDataURL();
            callback();
        }


        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:photoChange', result);
            vm.isSaving = false;
            clear();
        }


        function submit() {
            console.log("arquivo antes ", vm.fileOriginal);
            if (vm.fileOriginal) {
                ok();
            }
        };


        function ok() {
            vm.carregando = true;
            vm.mensagemAoUsuario = "Salvando ...";
            console.log(" iniciou uma chamada ", vm.cropper.croppedImage);
            console.log(" iniciou uma chamada ", vm.cropper.croppedImage);
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    // file: Upload.dataUrltoBlob(vm.cropper.croppedImage),
                    file: vm.fileOriginal
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                $uibModalInstance.close(resp);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
                vm.carregando = false;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });


        }
    }
})();
