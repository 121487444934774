(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PoliticaController', PoliticaController);

    PoliticaController.$inject = ['$state', 'LoginService', 'Principal'];

    function PoliticaController($state, LoginService, Principal) {
        var vm = this;

    }
})();
