(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('produto', {
                parent: 'home',
                url: '/produto',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/produto/produto.html',
                        controller: 'ProdutoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tipoFormacaoAcademica');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('produto-detail', {
                parent: 'produto',
                url: '/produto/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.produto.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/produto/produto-dialog.html',
                        controller: 'ProdutoDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Produto', function ($stateParams, Produto) {
                        return Produto.get({id: $stateParams.id}).$promise;
                    }],
                }
            })
            .state('produto-usuario', {
                parent: 'entity',
                url: '/produtos-usuario',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.produto.detail.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/produto/produto-usuario.html',
                        controller: 'ProdutoUsuarioController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    }).result.then(function () {
                        $state.go('produto', {}, {reload: false});
                    }, function () {
                        $state.go('produto');
                    });
                }]
            });
    }
})();
