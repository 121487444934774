(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('FormacaoAcademicaDialogController', FormacaoAcademicaDialogController);

    FormacaoAcademicaDialogController.$inject = ['$http', '$timeout', '$scope', '$uibModalInstance', 'entity', 'FormacaoAcademica', 'Upload', 'cfpLoadingBar'];

    function FormacaoAcademicaDialogController($http, $timeout, $scope, $uibModalInstance, entity, FormacaoAcademica, Upload, cfpLoadingBar) {
        var vm = this;

        vm.formacaoAcademica = entity;
        vm.formacaoAcademica.inicio_tela = entity.inicio;
        vm.formacaoAcademica.fim_tela = entity.fim;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.progresso = cfpLoadingBar;
        vm.getCidades = getCidades;

        if (entity.iscurso) {
            vm.formacaoAcademica.tipoFormacao = "CURSO";
        }

        vm.file = null;
        vm.format_date = 'dd/MM/yyyy';
        vm.errorFile = null;


        FormacaoAcademica.getTipoFormacao({}, function (data) {
            vm.tiposFormacao = [];
            vm.todosTiposFormacao = data;
            vm.tiposFormacao.push({tiposFormacao: data.slice(0, 5)});
            vm.tiposFormacao.push({tiposFormacao: data.slice(5, 9)});
            vm.tiposFormacao.push({tiposFormacao: data.slice(9, data.length)});
        });


        function montarArquivoESalvar(file) {
            //Convert o file blob em base64 com o url do broswer.
            Upload.base64DataUrl(file).then(function (url) {
                    var arquivo = {};
                    arquivo.nome = file.name;
                    var arr = url.split(',');
                    var base64 = arr[arr.length - 1];
                    arquivo.bytes = base64;
                    arquivo.contentType = file.type;
                    arquivo.lastModified = new Date();
                    saveFormacaoComAnexo(arquivo);
                }, function (resp) {
                    vm.progressPercentage = null;
                }, function (evt) {
                    vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }
            );
        }


        function save() {
            vm.progresso = cfpLoadingBar;
            vm.isSaving = true;
            if (vm.file) {
                montarArquivoESalvar(vm.file);
            } else {
                saveFormacao();
            }
        }


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveFormacao() {
            vm.isSaving = true;
            if (vm.formacaoAcademica.id !== null) {
                FormacaoAcademica.update(vm.formacaoAcademica, onSaveSuccess, onSaveError);
            } else {
                FormacaoAcademica.save(vm.formacaoAcademica, onSaveSuccess, onSaveError);
            }
        }

        function saveFormacaoComAnexo(arquivo) {
            var formacaoComArquivo = {"formacaoAcademica": vm.formacaoAcademica, "arquivo": arquivo};
            if (vm.formacaoAcademica.id !== null) {
                FormacaoAcademica.updateComAnexo(formacaoComArquivo, onSaveSuccess, onSaveError);
            } else {
                FormacaoAcademica.saveComAnexo(formacaoComArquivo, onSaveSuccess, onSaveError);
            }
        }


        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:formacaoAcademicaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.inicio = false;
        vm.datePickerOpenStatus.fim = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getCidades(param) {
            return $http.get('api/_search/cidades/', {
                params: {
                    query: param,
                    page: 0,
                    size: 10,
                }
            }).then(function (response) {
                return response.data.map(function (cidade) {
                    return cidade;
                });
            });
        }
    }
})();
