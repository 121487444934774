(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .factory('PessoaSearch', PessoaSearch);

    PessoaSearch.$inject = ['$resource'];

    function PessoaSearch($resource) {
        var resourceUrl = 'api/_search/pessoas/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'findByFiltro': {
                url: 'api/_search/pessoas-filtro',
                isArray: true,
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
        });
    }
})();
