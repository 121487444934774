(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ProdutoCarrinhoController', ProdutoCarrinhoController);

    ProdutoCarrinhoController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Produto', 'ConfirmService', 'Pessoa', 'BuscaCep', '$sce', '$window'];

    function ProdutoCarrinhoController($timeout, $scope, $state, $stateParams, entity, Produto, ConfirmService, Pessoa, BuscaCep, $sce, $window) {
        var vm = this;

        vm.produto = entity;
        vm.produto.valorOriginal = vm.produto.valor;
        vm.pessoa = {};
        vm.dadosPagamento = {};
        vm.endereco = null;
        vm.passo1 = true;
        vm.passo2 = false;
        vm.passo3 = false;
        vm.passo4 = false;

        vm.CHAVE_CRIPTOGRAFIA = '';

        vm.pagar = pagar;
        vm.trustedHtml = trustedHtml;
        vm.checkProdutoZerado = checkProdutoZerado;
        vm.redirecionarProdutoZerado = redirecionarProdutoZerado;
        vm.aplicarCupomDesconto = aplicarCupomDesconto;
        vm.cupom = {};

        function aplicarCupomDesconto() {
            vm.cupom.produtoId = vm.produto.id;
            //var cupom = {produtoId: vm.produto.id, cupom: 'CUPOM'};
            if (vm.cupom.cupom) {
                Produto.checarCupomDesconto(vm.cupom, function (resp) {
                    vm.cupom = resp;
                    if (resp.valido) {
                        var desconto = vm.produto.valor * ((resp.percentualDesconto ? resp.percentualDesconto : 0) / 100);
                        if (desconto && desconto > 0) {
                            vm.produto.valor = vm.produto.valor - desconto;
                        }
                    } else {
                        ConfirmService.swal({
                                title: "Cupom Inválido",
                                text: "Cupom inválido, o Cupom " + vm.cupom.cupom + " está vencido ou não foi encontrado.",
                                type: "error",
                                showCancelButton: false,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Ok",
                                cancelButtonText: "Voltar",
                                closeOnConfirm: false,
                                closeOnCancel: false
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    ConfirmService.close();
                                } else {
                                    ConfirmService.close();
                                }
                            });
                    }

                }, function (error) {
                    console.log("ERRO: ", error);
                });
            }

        }

        //aplicarCupomDesconto();

        function checkProdutoZerado() {
            if (vm.produto.valor === 0) {
                vm.dadosPagamento.tipoPagamento = 'BOLETO';
                vm.passo1 = false;
                vm.passo3 = false;
                vm.passo4 = true;
            }
        }

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        vm.buscaCep = buscaCep;

        getChaveCripoto();

        function buscaCep() {
            vm.isSaving = true;
            if (vm.endereco && vm.endereco.cep && vm.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.endereco.cep}, onBuscaCepSuccess, onSaveError);
            }
        }

        function onBuscaCepSuccess(result) {
            vm.endereco.logradouro = result.logradouro;
            vm.endereco.bairro = result.bairro;
            vm.endereco.localidade = result.localidade;
            vm.endereco.uf = result.uf;

            vm.isSaving = false;
        }

        function getChaveCripoto() {
            Produto.getChaveCriptografia({}, function (key) {
                console.log("key ", key);
                vm.CHAVE_CRIPTOGRAFIA = key.chave;
            }, function (error) {
                console.log(error);
            });
        }

        function getAccount() {
            vm.loading = true;
            Pessoa.getPerfilByUser({}, findByLoginSuccess, function (err) {
                vm.loading = false;
            });

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                Pessoa.getContato({}, function (contato) {
                    vm.contato = contato;
                    vm.dadosPagamento.cpfPagador = vm.contato.pessoa.cpf;
                    vm.dadosPagamento.nomePagador = vm.contato.pessoa.nome;
                    if (vm.contato.enderecos.length === 1) {
                        vm.endereco = vm.contato.enderecos[0];
                    } else {
                        vm.enderecos = vm.contato.enderecos;
                    }
                    vm.loading = true;
                    if (vm.contato.telefones && vm.contato.telefones.length > 0) {
                        vm.dadosPagamento.telefone = vm.contato.telefones[0].numero;
                    }
                    validarCampos(contato);
                    redirecionarProdutoZerado();
                }, function (error) {
                    vm.loading = false;
                    console.log(error);
                });
            }
        }

        getAccount();

        function validarCampos(contato) {
            if (vm.produto.valor !== 0) {
                if (contato != null && contato.pessoa.nascimento == null) {
                    lancarDialogCampoObrigatorio("Preencha corretamente a sua data de Nascimento");
                    return true;
                }
                if (vm.dadosPagamento.telefone == null) {
                    lancarDialogCampoObrigatorio("Adicione um telefone");
                    return true;
                }
                if (vm.endereco == null) {
                    lancarDialogCampoObrigatorio("Adicione um Endereço");
                    return true;
                }
            }
            return false;
        }

        function lancarDialogCampoObrigatorio(mensagem) {
            ConfirmService.swal({
                    title: "Antes de continuar é necessário preeencher algumas informações!",
                    text: mensagem,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Voltar",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        ConfirmService.close();
                    } else {
                        ConfirmService.close();
                    }
                });

        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function showDialogProdutoZerado(resp) {
            if (resp.valorTotal === 0) {
                ConfirmService.swal({
                        title: "Compra realizada com sucesso.",
                        text: "Estamos te encaminhado para as avaliações para o início do processo",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        cancelButtonText: "Voltar",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $state.go('avaliacao');
                            ConfirmService.close();
                        } else {
                            $state.go('avaliacao');
                            ConfirmService.close();
                        }
                    });
            }
        }


        function redirecionarProdutoZerado() {
            if (vm.produto.valor === 0) {
                checkProdutoZerado();
                pagar();
            } else {
                vm.loading = false;
            }
        }


        function pagar() {
            checkProdutoZerado();
            vm.loading = true;
            if (vm.produto.valor > 0 && validarCampos(vm.contato)) {
                vm.loading = false;
                return;
            }
            //salvar a venda com status iniciado.
            //realizar o checkout do pagamento com o pagar-me
            //salvar o token de retorno e gravar no pagamento
            //realizar a captura pelo BACKEND utilizando o token de retorno, valor e api key.

            var hoje = new Date();

            var telefones = vm.dadosPagamento.telefone;
            var fones = [];
            if (telefones) {
                fones.push("+55" + telefones);
            }


            var pagamento = {
                createToken: 'true',
                customerData: false,
                customer: {
                    external_id: vm.contato.pessoa.id + "",
                    name: vm.contato.pessoa.nome,
                    type: 'individual',
                    country: 'br',
                    email: vm.contato.pessoa.user.email,
                    documents: [
                        {
                            type: 'cpf',
                            number: vm.dadosPagamento.cpfPagador ? vm.dadosPagamento.cpfPagador.replace('.', '').replace('-', '') : '000000000000',
                        },
                    ],
                    phone_numbers: fones,
                    birthday: vm.contato.pessoa.nascimento ? formatDate(vm.contato.pessoa.nascimento) : null,
                },
                billing: {
                    name: vm.dadosPagamento.nomePagador,
                    address: vm.endereco ? {
                        country: 'br',
                        state: vm.endereco.uf,
                        city: vm.endereco.localidade,
                        neighborhood: vm.endereco.bairro,
                        street: vm.endereco.logradouro,
                        street_number: vm.endereco.numero,
                        zipcode: vm.endereco.cep.replace('.', '').replace('-', '')
                    } : {
                        country: 'br',
                        state: 'PR',
                        city: 'Maringá',
                        neighborhood: '',
                        street: '',
                        street_number: '',
                        zipcode: '87050000'
                    }
                },
                shipping: {
                    name: vm.contato.pessoa.nome,
                    fee: 0,
                    delivery_date: formatDate(new Date(hoje.setMonth(hoje.getMonth() + 1))),
                    expedited: true,
                    address: vm.endereco ? {
                        country: 'br',
                        state: vm.endereco.uf,
                        city: vm.endereco.localidade,
                        neighborhood: vm.endereco.bairro,
                        street: vm.endereco.logradouro,
                        street_number: vm.endereco.numero,
                        zipcode: vm.endereco.cep.replace('.', '').replace('-', '')
                    } : {
                        country: 'br',
                        state: 'PR',
                        city: 'Maringá',
                        neighborhood: '',
                        street: '',
                        street_number: '',
                        zipcode: '87050000'
                    }
                },
                items: [
                    {
                        id: vm.produto.id,
                        title: vm.produto.descricao.length > 255 ? vm.produto.descricao.substring(0, 254) : vm.produto.descricao,
                        unit_price: addZeroesAndRemoveComma(vm.produto.valor),
                        quantity: 1,
                        tangible: false
                    }
                ],
                payments: [
                    {
                        payment_method: "checkout",
                        amount: addZeroesAndRemoveComma(vm.produto.valor),
                        checkout: {
                            customer_editable: false,
                            skip_checkout_success_page: true,
                            accepted_payment_methods: ["credit_card", "boleto", "pix"],
                            success_url: "https://personal.myskills.com.br/#/home/avaliacoes",
                            boleto: {
                                bank: "033",
                                instructions: "Pagar até o vencimento",
                                due_at: "2020-07-25T00:00:00Z"
                            },
                            credit_card: {
                                capture: true,
                                statement_descriptor: "Desc na fatura",
                            },
                            pix: {
                                expires_in: 100000
                            },
                        }
                    }
                ]
            };


            var vendaDto = {
                pessoa: vm.pessoa,
                user: vm.contato.pessoa.user,
                criadoEm: new Date(),
                paymentMethod: vm.dadosPagamento.tipoPagamento,
                valorTotal: vm.produto.valor,
                situacao: 'WAITING_PAYMENT',
                json: JSON.stringify(pagamento),
                cupom: vm.cupom,
                itens: [
                    {produto: vm.produto, valor: vm.produto.valor}
                ]
            };

            Produto.iniciarRequisicaoVenda(vendaDto, onSucesso, onErro);

            function onSucesso(resp) {
                resp.cupom = vm.cupom;
                if (vm.produto.valor === 0) {
                    var captura = {venda: resp, token: null, payment_method: 'BOLETO'};
                    Produto.capturarVenda(captura, onSuccessVenda, onErro);
                } else {
                    var checkout = new PagarMeCheckout.Checkout({
                        encryption_key: vm.CHAVE_CRIPTOGRAFIA,
                        success: handleSuccess,
                        error: handleError
                    });
                    checkout.open(pagamento);
                    vm.loading = false;
                }

                function handleSuccess(data) {
                    resp.cupom = vm.cupom;
                    var captura = {
                        venda: resp,
                        token: data.token,
                        payment_method: data.payment_method.toUpperCase()
                    };
                    Produto.capturarVenda(captura, onSuccessVenda, onErro);
                    ConfirmService.swal({
                            title: data.payment_method == 'boleto' ? 'Aguardando o pagamento' : "Pagamento em processamento",
                            text: "Estamos processando seu pagamento, quando finalizado iremos liberar automaticamente sua avaliação para o início do processo",
                            type: "success",
                            showCancelButton: false,
                            confirmButtonText: data.payment_method == 'boleto' ? 'Ok - visualizar boleto!' : 'Ok',
                            cancelButtonText: "Voltar",
                            closeOnConfirm: false,
                            closeOnCancel: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $state.go('avaliacao');
                                ConfirmService.close();
                            } else {
                                $state.go('avaliacao');
                                ConfirmService.close();
                            }
                        });
                    //boleto? gerar o arquivo para boleto.
                    //cartao? só realizar a confirmação.
                }

                function handleError(data) {
                    SweetAlert.swal({
                            title: "Erro ao processar o pagamento",
                            text: "Encontramos um erro ao processar seu pagamento, verifique os campos informados e tente novamente. \n Detalhes do erro:" + data,
                            type: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Ok",
                            cancelButtonText: "Voltar",
                            closeOnConfirm: false,
                            closeOnCancel: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                //$state.go("avaliacoes");
                                SweetAlert.close();
                            } else {
                                //$state.go("avaliacoes");
                                SweetAlert.close();
                            }
                        });
                    vm.loading = false;
                }

                function onSuccessVenda(resp) {

                    showDialogProdutoZerado(resp);
                    if (resp.boletoUrl) {
                        $window.open(resp.boletoUrl, '_blank');
                    }
                    vm.loading = false;
                }


            }

            function onErro(resp) {
                alert("Erro ao salvar venda" + resp);
                vm.loading = false;
            }



        }


        function addZeroesAndRemoveComma(num) {
            num = new String(num);
// Convert input string to a number and store as a variable.
            var value = new Number(num);
            value = value.toFixed(2);
// Split the input string into two arrays containing integers/decimals
            /*            var res = num.split(".");
            // If there is no decimal point or only one decimal place found.
                        if (res.length == 1 || res[1].length < 3) {
            // Set the number to two decimal places
                            value = value.toFixed(2);
                        }
                        if (value != 0 && (res.length == 1 && res[1].length > 2) ) {
            // Set the number to two decimal places
                            value = value.toFixed(2);
                        }*/
// Return updated or original number.
            value = String(value).replace('.', '').replace('-', '');
            return value;
        }

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }

    }
})();
