(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('DocumentoPessoal', DocumentoPessoal);

    DocumentoPessoal.$inject = ['$resource', 'DateUtils'];

    function DocumentoPessoal($resource, DateUtils) {
        var resourceUrl = 'api/documento-pessoal/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getAllByUserId': {url: 'api/documento-pessoal-by-user/:id', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return angular.fromJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
