(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('MensagemController', MensagemController);

    MensagemController.$inject = ['$rootScope', '$scope', '$state', 'Mensagem', 'Pessoa', '$interval', '$location', '$anchorScroll'];

    function MensagemController($rootScope, $scope, $state, Mensagem, Pessoa, $interval, $location, $anchorScroll) {
        var vm = this;
        vm.mensagensAgrupadas = [];
        vm.mensagens = [];
        vm.loadAll = loadAll;
        vm.carregarMensagens = carregarMensagens;
        vm.enviarMensagem = enviarMensagem;
        vm.essaMensagemEhMinha = essaMensagemEhMinha;

        $rootScope.$on('mySkillsPersonalApp:mensagemUpdate', function () {
            loadAll();
            vm.mensagem = null;
        });


        loadAll();

        function loadAll() {
            Mensagem.getAgrupadas(function (result) {
                vm.mensagensAgrupadas = result;
                if (vm.mensagensAgrupadas && vm.mensagensAgrupadas.length > 0 && !vm.mensagem) {
                    vm.mensagem = vm.mensagensAgrupadas[0];
                    carregarMensagens(vm.mensagem);
                }
            });
        }

        function carregarMensagens(mensagem) {
            vm.mensagem = mensagem;
            Mensagem.getPorOrigem({id: vm.mensagem.idDestino}, function (data) {
                vm.mensagens = data;
                vm.mensagem.naoLidas = 0;
                $scope.$emit('mySkillsPersonalApp:mensagemUpdateNavBar');

            });
            $("#chat-activity-list").animate({scrollTop: $("#chat-activity-list").prop('scrollHeight')}, 500);
        }

        getAccount();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
            };

        }


        function enviarMensagem() {
            Mensagem.save({
                texto: vm.texto,
                destino: {id: vm.mensagem.idDestino},
                publicadoEm: new Date()
            }, function (mensagem) {
                console.log("Mensagem enviada", mensagem);
                carregarMensagens(vm.mensagem);
            });
            inicarMensagem();
        }


        function inicarMensagem() {
            vm.texto = "";
        }

        inicarMensagem();

        function essaMensagemEhMinha(mensagem) {
            return vm.mensagem.idOrigem === mensagem.origem.id;
        }

        $scope.$on('$destroy', function () {
            if (angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
        });

    }
})();
