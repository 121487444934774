(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('AudioController', AudioController);

    AudioController.$inject = ['$scope', '$q', 'Principal', 'Pessoa', '$state', 'Upload', '$window', '$uibModalInstance'];

    function AudioController($scope, $q, Principal, Pessoa, $state, Upload, $window, $uibModalInstance) {
        var vm = this;

        vm.startRecord = startRecord;
        vm.ok = ok;
        vm.clear = clear;
        vm.close = close;
        vm.playRecord = playRecord;
        vm.finishRecord = finishRecord;
        vm.uploadFile = uploadFile;
        vm.deleteRecord = deleteRecord;
        vm.progressPercentage = 0;
        vm.totalTime = 120000;
        vm.stopRecord = stopRecord;
        vm.mensagemAoUsuario = "Escutando ...";

        vm.fileUpload = null;
        vm.errorFile = null;

        navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
        }).then(function (stream) {
            vm.stream = stream;
            $scope.$apply();
        });

        function playRecord() {
            vm.audio.play();
        }

        function deleteRecord() {
            vm.progressPercentage = 0;
            vm.audioFile = null;
            vm.audioSalvo = null;
        }

        function countbar(int) {
            vm.progressPercentage += int;
            $scope.$apply();
            if (vm.progressPercentage < 100) {
                setTimeout(function () {
                    countbar(1);
                }, vm.totalTime / 100);
            }
        }

        function uploadFile() {
            ok();
        }

        function finishRecord(blob) {
            vm.audioFile = blob;
            ok();

        }

        function stopRecord() {
            vm.recorder.stopRecording(function () {
                vm.carregando = false;
                vm.progressPercentage = 100;
                var blob = vm.recorder.getBlob();
                finishRecord(blob);
            });
        }

        function comecaGravar(stream) {
            vm.mensagemAoUsuario = "Escutando ...";
            vm.recorder = RecordRTC(stream, {
                type: 'audio'
            });
            vm.recorder.startRecording();
            countbar(1);
            setTimeout(function () {
                stopRecord();
            }, vm.totalTime);
        }

        function startRecord() {
            vm.carregando = true;
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            }).then(comecaGravar);
        }

        function close() {
            $uibModalInstance.close(vm.audioSalvo);
        }

        function ok() {
            vm.carregando = true;
            vm.mensagemAoUsuario = "Salvando ...";
            console.log(" iniciou uma chamada ", vm.audioFile);
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: vm.audioFile,
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                vm.audioSalvo = resp.data.conteudoFormatado;
                vm.carregando = false;
                console.log("audio salvoo >>>>>>>>>.", vm.audioSalvo);

                vm.audio = document.getElementById('audio');
                vm.audio.muted = false;
                vm.audio.volume = 1;
                vm.audio.src = vm.audioSalvo;

            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
                vm.carregando = false;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });


        }

        function clear() {
            $uibModalInstance.dismiss('close');
        }
    }
})();
