(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'previousState', 'Auth', '$window', '$cookies', 'pagingParams', '$uibModalInstance', '$location', 'Principal', 'Parceiro'];

    function LoginController($rootScope, $state, $timeout, previousState, Auth, $window, $cookies, pagingParams, $uibModalInstance, $location, Principal, Parceiro) {
        var vm = this;

        vm.authenticationError = $cookies.getObject("authenticationError");
        $cookies.putObject("authenticationError", false);
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.mudarTipoPessoa = mudarTipoPessoa;
        vm.rememberMe = false;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.inLongin = false;
        vm.tipoLogin = pagingParams.tipoLogin != null ? pagingParams.tipoLogin : 'PESSOA';
        vm.parceiro = pagingParams.parceiro;
        vm.usuario = pagingParams.usuario;
        vm.redirecionar = pagingParams.redirecionar;
        vm.mostraLogin = false;

        vm.isModal = $uibModalInstance != null;
        var host = $location.host();
        vm.subdomain = host.split('.')[0];


        $timeout(function () {
            if (vm.parceiro && vm.usuario && vm.redirecionar) {
                vm.username = vm.usuario;
                vm.password = vm.parceiro;
                login();
            } else {
                vm.mostraLogin = true;
                angular.element('#username').focus();
            }
            $("body").addClass(vm.subdomain);
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: false
            };
            $cookies.putObject("authenticationError", false);
            $state.go('register');
        }

        function mudarTipoPessoa(tipo) {
            if (vm.isModal) {
                vm.tipoLogin = tipo;
            } else {
                $state.go('login', {tipoLogin: tipo});
            }

        }

        function login() {
            vm.inLongin = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: false
            }).then(function () {
                $cookies.putObject("authenticationError", false);
                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (vm.isModal) {
                    $uibModalInstance.close({});
                } else {

                    if (vm.redirecionar) {
                        $window.location.href = vm.redirecionar;
                    }

                        // else if (previousState) {
                        //     $state.go(previousState.name, previousState.params);
                        // }
                    //
                    else {
                        Principal.hasAuthority("ROLE_EMPREGADOR")
                            .then(function (result) {
                                if (result) {
                                    $state.go('empregador');
                                } else {
                                    $state.go('perfil');
                                }
                            });
                    }
                }
                vm.inLongin = false;
            }).catch(function () {
                vm.inLongin = false;
                $cookies.putObject("authenticationError", true);
                $window.location.reload();
            });
        }

        function register() {
            $state.go('pre-register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
            });
        }

        carregarParceiro();

    }
})();
