(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PlanoCreditoDialogController', PlanoCreditoDialogController);

    PlanoCreditoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'PlanoCredito'];

    function PlanoCreditoDialogController($timeout, $scope, $stateParams, $state, entity, PlanoCredito) {
        var vm = this;

        vm.planoCredito = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("plano-credito")
        }

        function save() {
            vm.isSaving = true;
            if (vm.planoCredito.id !== null) {
                PlanoCredito.update(vm.planoCredito, onSaveSuccess, onSaveError);
            } else {
                PlanoCredito.save(vm.planoCredito, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:planoCreditoUpdate', result);
            $state.go("plano-credito");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
