(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('formacao-academica', {
                parent: 'home',
                url: '/formacao-academica?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.formacaoAcademica.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/formacao-academica/formacao-academicas.html',
                        controller: 'FormacaoAcademicaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('formacaoAcademica');
                        $translatePartialLoader.addPart('cidade');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('formacao-academica-curso', {
                parent: 'perfil',
                url: '/nova-formacao-curso',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-dialog.html',
                        controller: 'FormacaoAcademicaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    inicio: null,
                                    fim: null,
                                    certificacao: false,
                                    internacional: false,
                                    id: null,
                                    iscurso: true
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('formacaoAcademica');
                                $translatePartialLoader.addPart('cidade');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('formacao-academica-detail', {
                parent: 'perfil',
                url: '/formacao-academica/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.formacaoAcademica.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-detail.html',
                        controller: 'FormacaoAcademicaDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('formacaoAcademica');
                        $translatePartialLoader.addPart('cidade');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FormacaoAcademica', function ($stateParams, FormacaoAcademica) {
                        return FormacaoAcademica.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'formacao-academica',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('formacao-academica-detail.edit', {
                parent: 'formacao-academica-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-dialog.html',
                        controller: 'FormacaoAcademicaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['FormacaoAcademica', function (FormacaoAcademica) {
                                return FormacaoAcademica.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('formacaoAcademica');
                                $translatePartialLoader.addPart('cidade');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('formacao-academica.new', {
                parent: 'perfil',
                url: '/nova-formacao',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-dialog.html',
                        controller: 'FormacaoAcademicaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    inicio: null,
                                    fim: null,
                                    certificacao: false,
                                    internacional: false,
                                    id: null,
                                    iscurso: false
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('formacaoAcademica');
                                $translatePartialLoader.addPart('cidade');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('formacao-academica.edit', {
                parent: 'perfil',
                url: '/editar-formacao/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-dialog.html',
                        controller: 'FormacaoAcademicaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['FormacaoAcademica', function (FormacaoAcademica) {
                                return FormacaoAcademica.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('formacaoAcademica');
                                $translatePartialLoader.addPart('cidade');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            })
            .state('formacao-academica.delete', {
                parent: 'perfil',
                url: '/remover-formacao/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/formacao-academica/formacao-academica-delete-dialog.html',
                        controller: 'FormacaoAcademicaDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['FormacaoAcademica', function (FormacaoAcademica) {
                                return FormacaoAcademica.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', null, {reload: true});
                    }, function () {
                        $state.go('perfil');
                    });
                }]
            });
    }

})();
