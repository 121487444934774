(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Questionario', Questionario);

    Questionario.$inject = ['$resource'];

    function Questionario($resource) {
        var resourceUrl = 'api/questionario/';

        var service = $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'PUT',
                url: 'api/questionario/resultado'
            },
            'getResultado': {
                method: 'GET',
                url: 'api/questionario/resultado/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getResultadoInterno': {
                method: 'GET',
                url: 'api/questionario/resultado',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getResultadoInternoCorporateAvaliacao': {
                method: 'GET',
                isArray: true,
                url: 'api/questionario/resultado-corporate-avaliacao',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getResultadoInternoCorporateAvaliacaoByPessoaId': {
                method: 'GET',
                isArray: true,
                url: 'api/questionario/resultado-corporate-avaliacao-by-pessoa-id/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getResultadoInternoCorporateAvaliacaoByMontagemId': {
                method: 'GET',
                isArray: true,
                url: 'api/questionario/resultado-corporate-avaliacao-by-montagem/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'vincularPrimeiraAvaliacao': {
                method: 'GET',
                url: 'api/questionario/vincular-primeira-avaliacao',
            },
            'getResultadoInternoCorporateApuracao': { //resultado da apuração
                method: 'GET',
                isArray: true,
                url: 'api/questionario/carregar-por-pessoa',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },

            'getResultadoApuracao': {
                method: 'GET',
                isArray: false,
                url: 'api/questionario/carregar/:avaliacaoId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });


        service.imprimir = imprimir;

        return service;
    }


    var styles = {
        header: {
            fontSize: 22,
            bold: true,
            alignment: 'center'
        },
        anotherStyle: {
            italic: true,
            alignment: 'right'
        },
        titulo: {
            italic: true,
            alignment: 'center',
            fontSize: 14, bold: true, margin: [0, 10, 0, 5]
        },
        subTitulo: {
            italic: true,
            alignment: 'center',
            fontSize: 12, margin: [0, 20, 0, 8]
        },
        texto: {
            alignment: 'justify'
        }

    };

    function montarPareceres(pareceres, vm) {
        angular.forEach(vm.resultado.pareceres, function (parecer) {
            pareceres.push(
                {
                    text: parecer.relevancia, style: 'titulo'
                },
                {
                    text: 'Nível - ' + parecer.titulo, style: 'subTitulo'
                },
                {
                    text: parecer.nivel, style: 'texto'
                },
                {
                    text: 'Consequências ', style: 'subTitulo'
                },
                {
                    text: parecer.consequencia, style: 'texto'
                },
                {
                    text: 'Recomendações ', style: 'subTitulo'
                },
                {
                    text: parecer.recomendacao, style: 'texto'
                }
            )
        });
    }

    function imprimir(vm, $filter, $state) {
        var relevancias = [];
        var valoreRelevancias = [];
        angular.forEach(vm.resultado.relevancias, function (relevancia) {
            relevancias.push({text: relevancia.relevancia});
            valoreRelevancias.push({
                text: $filter('number')(relevancia.valor, 2) + '%',
                bold: true,
                alignment: 'right'
            });
        });
        var pareceres = [];
        montarPareceres(pareceres, vm);
        var radarCanvas = document.getElementById('radar');
        var mediaGeralCanvas = document.getElementById('mediaGeral');
        var logoCanvas = document.getElementById('logo-rodape');
        html2canvas(mediaGeralCanvas, {
            onrendered: function (mediaGeralCanvas) {
                html2canvas(logoCanvas, {
                    onrendered: function (logoCanvas) {
                        var data = radarCanvas.toDataURL();
                        var dataMedia = mediaGeralCanvas.toDataURL();
                        var dataLogo = logoCanvas.toDataURL();
                        var docDefinition = {
                                pageSize: 'A4',
                                pageMargins: [40, 60, 40, 60],
                                header: {text: 'MySkills Personal', alignment: 'center', fontSize: 8},
                                footer: function (currentPage, pageCount) {
                                    return [
                                        {
                                            text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
                                            alignment: 'center',
                                            fontSize: 8
                                        },
                                        {
                                            text: 'Autoavaliação realizada em personal.myskills.com.br',
                                            alignment: 'center',
                                            italic: true,
                                            fontSize: 8
                                        }];
                                },
                                content: [
                                    {
                                        columns: [
                                            {
                                                image: dataLogo,
                                                width: 80,
                                                margin: [180, 0, 0, 0]
                                            }, [
                                                {
                                                    text: 'Autoavaliação',
                                                    alignment: 'left',
                                                    fontSize: 10,
                                                    margin: [200, 10, 0, 0]
                                                },
                                                {
                                                    text: vm.resultado.nome,
                                                    alignment: 'left',
                                                    fontSize: 18,
                                                    bold: true,
                                                    margin: [200, 0, 0, 0]
                                                },
                                                {
                                                    text: vm.resultado.email,
                                                    alignment: 'left',
                                                    fontSize: 8,
                                                    margin: [200, 0, 0, 0]
                                                },

                                            ]
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            [
                                                {text: 'Resultado por área', style: 'titulo'},
                                                {
                                                    columns: [
                                                        [relevancias],
                                                        [valoreRelevancias]

                                                    ]
                                                }
                                            ], [
                                                {text: 'Média geral', style: 'titulo'},

                                                {
                                                    image: dataMedia,
                                                    width: 200,
                                                    alignment: 'center'
                                                }

                                            ],

                                        ]

                                    },
                                    {text: 'Radar de Pontuação por Competência', style: 'titulo'},
                                    {
                                        image: data,
                                        width: 450,
                                        margin: [35, 0, 0, 0]
                                    },
                                    {text: 'Parecer', style: 'header', pageBreak: 'before'},
                                    pareceres,
                                    {
                                        image: dataLogo,
                                        width: 100,
                                        alignment: 'center'
                                    },
                                    {text: 'MySkills Personal', style: 'titulo'},
                                    {text: 'Inteligência e Performance', alignment: 'center'}
                                ],
                                styles: styles

                            }
                        ;
                        pdfMake.createPdf(docDefinition).download("myskills.pdf", function () {
                            $state.reload();
                        });
                    }
                });


            }
        });

    }
})();
