(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilProfissionalDetailController', PerfilProfissionalDetailController);

    PerfilProfissionalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PerfilProfissional', 'Pessoa', 'ExperienciaProfissional', 'FormacaoAcademica'];

    function PerfilProfissionalDetailController($scope, $rootScope, $stateParams, previousState, entity, PerfilProfissional, Pessoa, ExperienciaProfissional, FormacaoAcademica) {
        var vm = this;

        vm.perfilProfissional = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:perfilProfissionalUpdate', function(event, result) {
            vm.perfilProfissional = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
