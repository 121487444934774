(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ConfiguracaoIntegracaoDetailController', ConfiguracaoIntegracaoDetailController);

    ConfiguracaoIntegracaoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ConfiguracaoIntegracao'];

    function ConfiguracaoIntegracaoDetailController($scope, $rootScope, $stateParams, previousState, entity, ConfiguracaoIntegracao) {
        var vm = this;

        vm.configuracaoIntegracao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:configuracaoIntegracaoUpdate', function(event, result) {
            vm.configuracaoIntegracao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
