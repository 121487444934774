(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('vaga', {
                parent: 'app',
                url: '/vaga',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vaga/vaga.html',
                        controller: 'VagaController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tipoFormacaoAcademica');
                        $translatePartialLoader.addPart('quantidadeFuncionarios');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('vaga-detail', {
                parent: 'home',
                url: '/vaga/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.vaga.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vaga/vaga-dialog.html',
                        controller: 'VagaDialogController',
                        controllerAs: 'vm',
                    }
                },
                params: {
                    empregador: {
                        value: 'false',
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            empregador: $stateParams.empregador
                        };
                    }],
                    entity: ['$stateParams', 'Vaga', function ($stateParams, Vaga) {
                        return Vaga.get({id: $stateParams.id}).$promise;
                    }],
                }
            })
            .state('vaga-ver', {
                parent: 'vaga',
                url: '/detalhes/{id}?empregador',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.vaga.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vaga/vaga-dialog.html',
                        controller: 'VagaDialogController',
                        controllerAs: 'vm',
                    }
                },
                params: {
                    empregador: {
                        value: 'false',
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            empregador: $stateParams.empregador
                        };
                    }],
                    entity: ['$stateParams', 'Vaga', function ($stateParams, Vaga) {
                        return Vaga.get({id: $stateParams.id}).$promise;
                    }],
                }
            })
            .state('vaga-usuario', {
                parent: 'entity',
                url: '/vagas-usuario',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.vaga.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vaga/vaga-usuario.html',
                        controller: 'VagaUsuarioController',
                        controllerAs: 'vm',
                    }
                },
            });
    }
})();
