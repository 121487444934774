(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PlanoCreditoDetailController', PlanoCreditoDetailController);

    PlanoCreditoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PlanoCredito', '$sce'];

    function PlanoCreditoDetailController($scope, $rootScope, $stateParams, previousState, entity, PlanoCredito, $sce) {
        var vm = this;

        vm.planoCredito = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:planoCreditoUpdate', function(event, result) {
            vm.planoCredito = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
