(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ConfirmController', ConfirmController);

    ConfirmController.$inject = ['$uibModalInstance', 'message'];

    function ConfirmController($uibModalInstance, message) {
        var vm = this;
        vm.message = message;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.dismiss = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirm = function () {
            $uibModalInstance.close(vm.message);
        };

    }

})();
