(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('carrinho-wizard', {
                parent: 'home',
                url: '/carrinho/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Carrinho'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/produto-carrinho/carrinho.html',
                        controller: 'ProdutoCarrinhoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Produto','$stateParams', function (Produto, $stateParams) {
                        return Produto.get({id: $stateParams.id}).$promise;

                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'carrinho',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]

                }

            });

    }
})();
