(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('QueryService', QueryService);

    QueryService.$inject = ['$resource'];

    function QueryService($resource) {
        var resourceUrl = 'api/query/';

        return $resource(resourceUrl, {}, {
            'execute': {method: 'POST', url: 'api/query/execute'},
            'export': {method: 'POST', url: 'api/query/export'},
        });
    }
})();
