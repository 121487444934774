(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('social-network', {
            parent: 'home',
            url: '/social-network/{id}',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/home/social-network/social-network.html',
                    controller: 'SocialNetworkController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Pessoa', function (Pessoa) {
                            return Pessoa.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('home', {}, {reload: false});
                }, function () {
                    $state.go('home');
                });
            }]
        });
    }
})();

