(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ExperienciaProfissionalDetailController', ExperienciaProfissionalDetailController);

    ExperienciaProfissionalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ExperienciaProfissional', 'PerfilProfissional', 'Empregador'];

    function ExperienciaProfissionalDetailController($scope, $rootScope, $stateParams, previousState, entity, ExperienciaProfissional, PerfilProfissional, Empregador) {
        var vm = this;

        vm.experienciaProfissional = entity;
        vm.experienciaProfissional.inicio_tela = entity.inicio;
        vm.experienciaProfissional.fim_tela = entity.fim_tela;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:experienciaProfissionalUpdate', function(event, result) {
            vm.experienciaProfissional = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
