(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilDialogDadosPessoaisController', PerfilDialogDadosPessoaisController);

    PerfilDialogDadosPessoaisController.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Pessoa', 'TipoStatus', '$uibModalInstance'];

    function PerfilDialogDadosPessoaisController($rootScope, $scope, Principal, $state, Pessoa, TipoStatus, $uibModalInstance) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.arquivo = {};
        vm.verificarEstadoCivil = verificarEstadoCivil;
        vm.verificarConjugeMyskills = verificarConjugeMyskills;
        vm.verificarFiliacaoPaiFalecido = verificarFiliacaoPaiFalecido;
        vm.verificarFiliacaoPaiMyskills = verificarFiliacaoPaiMyskills;
        vm.verificarFiliacaoMaeFalecido = verificarFiliacaoMaeFalecido;
        vm.verificarFiliacaoMaeMyskills = verificarFiliacaoMaeMyskills;
        vm.verificarEstruturaFamiliarIrmaos = verificarEstruturaFamiliarIrmaos;
        vm.verificarEstruturaFamiliarFilhos = verificarEstruturaFamiliarFilhos;
        vm.verificarEstruturaFamiliarFilhoMyskills = verificarEstruturaFamiliarFilhoMyskills;
        vm.verificarEstruturaFamiliarSegundoFilhoMyskills = verificarEstruturaFamiliarSegundoFilhoMyskills;

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });


        getAccount();
        loadStatus();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
            };

        }

        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
        }


        function save() {
            vm.isSaving = true;
            Pessoa.updatePerfil(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.isEditing = false;
            $uibModalInstance.close(result);
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function verificarConjugeMyskills(estadoCivil, value) {
            if (estadoCivil && String(estadoCivil) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEstadoCivil(conjugeMyskills, value) {
            if (conjugeMyskills && String(conjugeMyskills) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarFiliacaoPaiFalecido(filiacaoPaiFalecido, value) {
            if (filiacaoPaiFalecido && String(filiacaoPaiFalecido) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarFiliacaoPaiMyskills(filiacaoPaiMyskills, value) {
            if (filiacaoPaiMyskills && String(filiacaoPaiMyskills) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarFiliacaoMaeFalecido(filiacaoMaeFalecido, value) {
            if (filiacaoMaeFalecido && String(filiacaoMaeFalecido) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarFiliacaoMaeMyskills(filiacaoMaeMyskills, value) {
            if (filiacaoMaeMyskills && String(filiacaoMaeMyskills) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEstruturaFamiliarIrmaos(estruturaFamiliarIrmaos, value) {
            if (estruturaFamiliarIrmaos && String(estruturaFamiliarIrmaos) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEstruturaFamiliarFilhos(estruturaFamiliarFilhos, value) {
            if (estruturaFamiliarFilhos && String(estruturaFamiliarFilhos) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEstruturaFamiliarFilhoMyskills(estruturaFamiliarFilhoMyskills, value) {
            if (estruturaFamiliarFilhoMyskills && String(estruturaFamiliarFilhoMyskills) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEstruturaFamiliarSegundoFilhoMyskills(estruturaFamiliarSegundoFilhoMyskills, value) {
            if (estruturaFamiliarSegundoFilhoMyskills && String(estruturaFamiliarSegundoFilhoMyskills) === String(value)) {
                return true;
            }
            return false;
        }
    }
})();
