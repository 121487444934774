(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaSimplesController', PessoaSimplesController);

    PessoaSimplesController.$inject = ['$window', '$scope', '$rootScope', '$state', '$stateParams',
        'previousState', 'entity', 'Pessoa', 'FormacaoAcademica', 'ExperienciaProfissional',
        'Mensagem', 'ConfirmService', 'Principal', 'Questionario', 'Vaga', 'DocumentoPessoal', '$uibModal',
        'Empregador'];

    function PessoaSimplesController($window, $scope, $rootScope, $state, $stateParams,
                                     previousState, entity, Pessoa, FormacaoAcademica, ExperienciaProfissional,
                                     Mensagem, ConfirmService, Principal, Questionario, Vaga, DocumentoPessoal, $uibModal,
                                     Empregador) {
        var vm = this;

        vm.playAudioPerfil = playAudioPerfil;
        vm.previousState = previousState.name;
        vm.pessoa = entity;
        vm.verPerfilPremium = verPerfilPremium;
        vm.imprimirSimples = imprimirSimples;
        vm.calculateDiff = calculateDiff;
        vm.abrirLogin = abrirLogin;

        if (Principal.isAuthenticated()) {
            vm.isAuthenticated = true;

            Principal.hasAuthority("ROLE_EMPREGADOR")
                .then(function (result) {
                    if (result) {
                        Empregador.temPerfil({idPessoa: entity.id}, function (result2) {
                            if (result2 && result2.id) {
                                $state.go("pessoa-premium", {id: entity.id});
                            }
                        });
                    }
                });
        }

        function abrirLogin() {
            $uibModal.open({
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    previousState: function () {
                        return {};
                    }, pagingParams: function () {
                        return {};
                    },
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function () {
                $state.go("pessoa-detail", {"id": vm.pessoa.id}, {reload: true});
            }, function () {
                $state.go("pessoa-detail", {"id": vm.pessoa.id}, {reload: true});
            });
        }

        function calculateDiff(past, fut) {
            var date = new Date(past);
            var currentDate = fut ? new Date(fut) : new Date();
            var days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
            if (days > 365) {
                return Math.floor(days / 365) + " Anos"
            }
            if (days > 30) {
                return Math.floor(days / 30) + " Meses"
            }
            return days + " Dias";
        }

        function playAudio(idDocument, audio) {

            $uibModal.open({
                templateUrl: 'app/entities/audio/audio-detail.html',
                controller: 'AudioDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    audio: function () {
                        return audio
                    }
                }
            }).result.then(function (result) {

            }, function () {

            });

        }

        function playAudioPerfil() {
            playAudio('audio', vm.pessoa.audioApresentacao);
        }

        function verPerfilPremium() {

            ConfirmService.swal({
                title: "Perfil Premium",
                text: "Deseja adquirir o perfil completo de " + vm.pessoa.nome + "? Ao confirmar será debitado o valor de 1 crédito do seu saldo.",
                showCancelButton: true,
                closeOnConfirm: true,
                cancelButtonText: "Cancelar",
                animation: "slide-from-top",
            }, function (isConfirm) {
                if (isConfirm) {
                    Empregador.comprarPerfil({id: vm.pessoa.id}, function (data) {
                        if (data && data.id) {
                            window.open(' #/completo/' + vm.pessoa.id);
                        } else {
                            alert("Não foi possível ver o perfil premium, verifique seu saldo")
                        }
                    }, function (error) {
                        alert("Não foi possível ver o perfil premium, verifique seu saldo")
                    });
                }
            });

        }

        vm.temPCD = function () {
            if (vm.pessoa.atributos) {
                for (var j = 0; j < vm.pessoa.atributos.length; j++) {
                    var atributo = vm.pessoa.atributos[j];
                    if (atributo.valor) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        }

        function imprimirSimples() {
            Pessoa.imprimirSimples(vm.pessoa.id);
        }

    }
})();
