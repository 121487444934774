(function () {
        'use strict';

        angular
            .module('mySkillsPersonalApp')
            .controller('ConfiguracoesGeraisController', ConfiguracoesGeraisController);

        ConfiguracoesGeraisController.$inject = ['$filter', 'ConfiguracoesGeraisService', 'ConfirmService'];

        function ConfiguracoesGeraisController(filter, ConfiguracoesGeraisService, ConfirmService) {
            var vm = this;
            vm.configuracao = {};
            vm.configuracaoGeralURL = {};
            vm.save = save;
            vm.adicionarURL = adicionarURL;
            vm.removerURL = removerURL;
            vm.loadAll = loadAll;
            vm.tipoURL = null;
            vm.itemsURL = [{
                descricao: 'HOME'
            }];

            function save() {
                vm.isSaving = true;
                if (vm.configuracao.id) {
                    ConfirmService.confirm({
                            title: "Salvo!",
                            text: "Registro salvo com sucesso! ",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                    ConfiguracoesGeraisService.update(vm.configuracao, onSaveSuccess, onSaveError);
                } else {
                    ConfirmService.confirm({
                            title: "Salvo!",
                            text: "Registro salvo com sucesso! ",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                    ConfiguracoesGeraisService.save(vm.configuracao, onSaveSuccess, onSaveError);

                }
            }

            function adicionarURL() {
                if (!vm.configuracao) {
                    vm.configuracao = {};
                }
                if (!vm.configuracao.configuracaoGeralURL) {
                    vm.configuracao.configuracaoGeralURL = [];
                }
                vm.configuracaoGeralURL.tipoURL = vm.tipoURL.descricao;
                vm.configuracao.configuracaoGeralURL.push(vm.configuracaoGeralURL);
                vm.configuracaoGeralURL = null;
            }

            function onSaveSuccess(result) {
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }

            function loadAll() {
                ConfiguracoesGeraisService.query({}, function (result) {
                    vm.configuracao = result[0];
                });
            }

            loadAll();

            function removerURL(index) {
                ConfirmService.swal({
                        title: "Atenção",
                        text: "Tem certeza que deseja excluir este item?!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Sim, tenho certeza!!",
                        cancelButtonText: "Não",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            ConfirmService.swal("Removido!", "O registro foi removido com sucesso.", "success");
                            vm.configuracao.configuracaoGeralURL.splice(index, 1);
                        } else {
                            ConfirmService.swal("Cancelado", "O registro não foi removido! :)", "error");
                        }
                    });


            }
        }
    }

)();
