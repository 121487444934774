(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ComprarSaldoController', ComprarSaldoController);

    ComprarSaldoController.$inject = ['$scope', '$state', '$filter', 'Venda', 'Produto', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService', '$timeout', 'entity', 'BuscaCep', 'Empregador'];

    function ComprarSaldoController($scope, $state, $filter, Venda, Produto, ParseLinks,
        AlertService, pagingParams, paginationConstants, ConfirmService, $timeout, entity, BuscaCep, Empregador) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.plano = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.buscaCep = buscaCep;
        vm.clear = clear;
        vm.search = search;


        vm.totalAComprar = totalAComprar;
        vm.formatCardNumber = formatCardNumber;
        vm.formatMesAno = formatMesAno;
        vm.efetivar = efetivar;
        vm.realizarPagamento = realizarPagamento;
        vm.copyToClipboard = copyToClipboard;
        vm.compraSaldoDTO = {};
        vm.empregador = {};
        vm.edicaoEndereco = false;
        vm.isLoading = false;

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.itens = [];
        vm.compraSaldoDTO.plano = vm.plano;
        vm.compraSaldoDTO.payment_method = 'PIX';
        vm.qrCode = "00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/2873f40e-d145-4c27-b658-b1ef0d51cca752040000530398654040.505802BR5914Conta primaria6014RIO DE JANEIRO622905258f61d6c30b714b89b025f54896304C2A5";

        $scope.$on("$destroy", function (event) {
            if (vm.timeout)
                $timeout.cancel(vm.timeout);
        });

        buscarEmpregador();

        function adicionarItem() {


            if (vm.plano) {
                vm.itens.push(
                    {
                        nome: vm.plano.descricao,
                        descricao: vm.plano.quantidade + ' Créditos',
                        valor: vm.plano.valorPlano
                    }
                );
                vm.compraSaldoDTO.valorTotal = vm.plano.valorPlano;
            }
        }

        adicionarItem();

        function buscarEmpregador() {
            Empregador.getByUser({}, function (resp) {
                    console.log("empregador: ", resp);
                    vm.empregador = resp;
                    vm.compraSaldoDTO.endereco = vm.empregador.endereco;
                    vm.compraSaldoDTO.email = vm.empregador.email;
                    vm.compraSaldoDTO.cpfCnpj = vm.empregador.cnpj;
                    vm.compraSaldoDTO.telefone = vm.empregador.telefone;
                },
                function (error) {
                    console.log("erro: ", error);
                });

        }

        function formatCardNumber(cardNumber) {
            if (cardNumber) {
                cardNumber = cardNumber.replace(/[^\d]/g, "");
                cardNumber = cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
                vm.compraSaldoDTO.numeroCartao = cardNumber;
            }
        }

        function formatMesAno(mesAnoCartao) {
            if (mesAnoCartao) {
                mesAnoCartao = mesAnoCartao.replace(/[^\d]/g, "");
                mesAnoCartao = mesAnoCartao.replace(/(\d{2})(\d{2})/, "$1/$2");
                vm.compraSaldoDTO.mesAnoCartao = mesAnoCartao;
            }
        }


        function realizarPagamento() {
            vm.isLoading = true;
            console.log("Tipo pagamento", vm.compraSaldoDTO);
            Produto.realizarPagamento(vm.compraSaldoDTO, onSucesso, onErro);

        }

        function mostrarAlertaSucessoPix() {
            ConfirmService.swal({
                    title: "Pagamento recebido",
                    text: "Pagamento realizado com sucesso.",
                    type: "success",
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Voltar",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function (isConfirm) {


                    if (isConfirm) {
                        redirecionar();
                        ConfirmService.close();
                    } else {
                        $timeout(function () {
                            $state.go('empregador', null, {reload: true});
                        }, 1000);
                        ConfirmService.close();
                    }
                });
        }

        function redirecionar() {
            $timeout(function () {
                $state.go('empregador', null, {reload: true});
            }, 1000);
        }


        function mostrarAlertaSucessoCartao(resp) {
            if (resp.erro) {
                ConfirmService.swal({
                        title: "Erro ao realizar pagamento",
                        text: "Mensagem de erro: " + resp.mensagemErro,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        cancelButtonText: "Voltar",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            ConfirmService.close();
                        } else {
                            ConfirmService.close();
                        }
                    });
            } else {
                ConfirmService.swal({
                        title: "Pagamento realizado com sucesso",
                        text: "Seu pagamento foi processado com sucesso.",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        cancelButtonText: "Voltar",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            redirecionar();
                            ConfirmService.close();
                        } else {
                            ConfirmService.close();
                        }
                    });
            }

        }

        function onSucesso(resp) {
            vm.isLoading = false;
            if (vm.compraSaldoDTO.payment_method != 'PIX') {
                mostrarAlertaSucessoCartao(resp);
            } else {
                vm.compraSaldoDTO = resp;
                consultarQrCode(resp.vendaId);
            }
        }

        function onErro(resp) {
            vm.isLoading = false;
            alert("Erro ao salvar venda" + resp);
        }


        function totalAComprar() {
            var totalCompraSaldo = 0;
            angular.forEach(vm.itens, function (item) {
                totalCompraSaldo += item.valor;
            });
            return totalCompraSaldo;
        }


        function efetivar(solicitacaoSaque) {

            ConfirmService.swal({
                    title: "Atenção",
                    text: "Você tem certeza que deseja efetivar o saque?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1acfb0",
                    confirmButtonText: "Efetivar o valor de " + $filter('currency')(solicitacaoSaque.valor),
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Venda.efetivarSaque({id: solicitacaoSaque.id}, confirmSaque);
                    }
                });

        }

        function consultarQrCode(id) {
            Produto.consultarQrCode({id: id}, function (resp) {
                if (resp.situacao == 'PAID') {
                    vm.compraSaldoDTO.status = resp.situacao;
                    vm.compraSaldoDTO.pago = true;

                    mostrarAlertaSucessoPix();
                } else {
                    vm.timeout = $timeout(function () {
                        consultarQrCode(id);
                    }, 3000);
                }
            }, function (error) {

            });
        }


        /*        function confirmSaque() {
                    loadAll();
                    ConfirmService.swal({
                        title: "Efetivado",
                        text: "O valor foi debitado nas transaçoes do parceiro",
                        timer: 3000,
                        type: "success",
                        showConfirmButton: false,
                    });
                }*/

        /*
                function loadAll() {

                    Venda.getSolicitacoesSaque({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        filtro: vm.searchQuery ? vm.searchQuery : "",
                        sort: sort()
                    }, onSuccess, onError);

                    function sort() {
                        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                        if (vm.predicate !== 'id') {
                            result.push('id');
                        }
                        return result;
                    }

                    function onSuccess(data, headers) {
                        vm.links = ParseLinks.parse(headers('link'));
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.solicitacoes = data;
                        vm.page = pagingParams.page;
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                }*/

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function buscaCep() {
            vm.isSaving = true;

            if (vm.compraSaldoDTO && vm.compraSaldoDTO.endereco && vm.compraSaldoDTO.endereco.cep && vm.compraSaldoDTO.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.compraSaldoDTO.endereco.cep}, onBuscaCepSuccess, onBuscaCepError);
            } else {
                vm.isSaving = false;
            }
        }

        function onBuscaCepError() {
            vm.isSaving = false;
        }


        function onBuscaCepSuccess(result) {
            vm.compraSaldoDTO.endereco.logradouro = result.logradouro;
            vm.compraSaldoDTO.endereco.bairro = result.bairro;
            vm.compraSaldoDTO.endereco.localidade = result.localidade;
            vm.compraSaldoDTO.endereco.uf = result.uf;

            vm.isSaving = false;
        }

        function copyToClipboard() {
            var url = vm.compraSaldoDTO.qrCode;

            if (window.clipboardData && window.clipboardData.setData) {
                // IE specific code path to prevent textarea being shown while dialog is visible.
                return clipboardData.setData("Text", url);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = url;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        }

    }
})();
