(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VideoController', VideoController);

    VideoController.$inject = ['$scope', '$q', 'Principal', 'Pessoa', '$state', 'Upload', '$window', '$uibModalInstance', '$timeout'];

    function VideoController($scope, $q, Principal, Pessoa, $state, Upload, $window, $uibModalInstance, $timeout) {
        var vm = this;


        vm.ok = ok;
        vm.stopRecord = stopRecord;
        vm.startRecord = startRecord;
        vm.deleteRecord = deleteRecord;
        vm.uploadFile = uploadFile;
        vm.clear = clear;
        vm.showPreview = false;
        vm.progressPercentage = 0;
        vm.totalTime = 60000;
        vm.showVideo =false;

        vm.fileUpload = null;
        vm.errorFile = null;

        $timeout(function () {
            vm.video = document.getElementById('video-preview');
        });

        function uploadFile() {
            vm.progressPercentage = 100;
            vm.carregando = false;
            vm.video.src = vm.video.srcObject = null;
            vm.video.muted = false;
            vm.video.volume = 1;
            vm.video.src = URL.createObjectURL(vm.videoFile);
            vm.showVideo =true;
        }


        function countbar(int) {
            vm.progressPercentage += int;
            $scope.$apply();
            if (vm.progressPercentage < 100) {
                setTimeout(function () {
                    countbar(1);
                }, vm.totalTime / 100);
            }
        }

        function deleteRecord() {
            vm.carregando = false;
            vm.progressPercentage = 0;
            vm.videoFile = null;
            vm.showVideo =false;
        }

        function captureCamera(callback) {
            navigator.mediaDevices.getUserMedia({audio: true, video: true}).then(function (camera) {
                callback(camera);
            }).catch(function (error) {
                alert('Ops! Não foi possível identificar uma câmera.');
                deleteRecord();
                console.error(error);
                $scope.$apply();
            });
        }

        function stopRecordingCallback() {
            vm.progressPercentage = 100;
            vm.carregando = false;

            vm.video.src = vm.video.srcObject = null;
            vm.video.muted = false;
            vm.video.volume = 1;
            vm.video.src = URL.createObjectURL(recorder.getBlob());
            vm.videoFile = recorder.getBlob();

            recorder.camera.stop();
            recorder.destroy();
            recorder = null;
        }

        var recorder; // globally accessible

        function startRecord() {
            vm.carregando = true;
            vm.showVideo =true;
            vm.mensagemAoUsuario = "Gravando ...";
            captureCamera(function (camera) {
                vm.video.muted = true;
                vm.video.volume = 0;
                vm.video.srcObject = camera;

                recorder = RecordRTC(camera, {
                    type: 'video'
                });

                recorder.startRecording();

                // release camera on stopRecording
                recorder.camera = camera;

                countbar(1);
                setTimeout(function () {
                    stopRecord();
                }, vm.totalTime)
            });


        };

        function stopRecord() {
            recorder.stopRecording(stopRecordingCallback);
        };

        function ok() {
            vm.carregando = true;
            vm.showVideo =false;
            vm.progressPercentage = 0;
            vm.mensagemAoUsuario = "Salvando ...";
            console.log(" iniciou uma chamada ", vm.videoFile);
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: vm.videoFile,
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                $uibModalInstance.close(resp);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.carregando = false;
                vm.showVideo =true;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });


        }

        function clear() {
            $uibModalInstance.dismiss('close');
        }
    }
})();
