(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilEditStatusAdminController', PerfilEditStatusAdminController);

    PerfilEditStatusAdminController.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Pessoa', 'TipoStatus', '$uibModalInstance', 'entity'];

    function PerfilEditStatusAdminController($rootScope, $scope, Principal, $state, Pessoa, TipoStatus, $uibModalInstance, entity) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.escolaridades = Pessoa.niveisEscolaridades();

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.arquivo = {};
        vm.pessoa = entity;


        loadStatus();


        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
            Pessoa.get({id: entity.id}, function (data){
                vm.pessoa = data;
                vm.pessoa.nascimento_tela = data.nascimento;
            });
        }

        function save() {
            vm.isSaving = true;
            Pessoa.updateAdmin(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.isEditing = false;
            $uibModalInstance.close(result);
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
