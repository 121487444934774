(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('RegistroParceiroController', RegistroParceiroController);


    RegistroParceiroController.$inject = ['$rootScope', '$cookies', '$translate', '$timeout', '$state', 'Auth', 'LoginService', 'Principal', 'ConfirmService', 'pagingParams', 'Parceiro', 'BuscaCep', 'Upload', '$uibModal'];

    function RegistroParceiroController($rootScope, $cookies, $translate, $timeout, $state, Auth, LoginService, Principal, ConfirmService, pagingParams, Parceiro, BuscaCep, Upload, $uibModal) {
        var vm = this;
        vm.authenticationError = {};
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registrarApenasParceria = registrarApenasParceria;
        vm.goLogin = goLogin;
        vm.buscaCep = buscaCep;
        vm.requestResetPassword = requestResetPassword;
        vm.registerAccount = {};
        vm.registerAccount.emailConfirm = '';
        vm.success = null;
        vm.parceiros = null;
        vm.parceiro = null;
        vm.account = null;
        vm.isAuthenticated = false;
        vm.parceiroJaSelecionado = false;
        vm.registerAccount.parceiro = {};

        vm.openFileUpload = openFileUpload;

        vm.idParceiro = pagingParams.idParceiro;

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated();
            vm.account = account;
            verificaSeTemLoginAntesDeConstinuar();
        }, function () {
            verificaSeTemLoginAntesDeConstinuar();
        });


        function verificaSeTemLoginAntesDeConstinuar() {
            if (!vm.account) {
                vm.podeContinuar = false;
            } else {
                vm.podeContinuar = true;
            }
        }


        Parceiro.getParceirosPublicos({}, function (parceiros) {
            vm.parceiros = parceiros;
            angular.forEach(vm.parceiros, function (parceiro) {
                if (parseInt(vm.idParceiro) === parseInt(parceiro.id)) {
                    vm.parceiro = parceiro;
                    vm.parceiroJaSelecionado = true;
                }
            });
        });

        $timeout(function () {
            angular.element('#login').focus();
        });

        function register() {
            //se o parceiro não veio por um link, então ele quer ser o primeiro parceiro(parceiro da myskills)
            //criar o parceiro e o usuário.
            vm.registerAccount.criarParceiro = true;
            if (vm.parceiroJaSelecionado) {
                vm.registerAccount.parceiro.idSuperior = vm.parceiro.id;
            }


            vm.registerAccount.langKey = $translate.use();
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            vm.registerAccount.login = vm.registerAccount.email;
            if (vm.idParceiro) {
                vm.registerAccount.idParceiro = vm.idParceiro;
            }

            if (vm.registerAccount.email !== vm.registerAccount.emailConfirm) {
                ConfirmService.confirm({
                        title: "Ops!",
                        text: "Os emails informados não são iguais, por favor, verifique",
                        confirm: function () {
                        },
                        cancel: function () {
                        }
                    }
                );
                return;
            }

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';

                Auth.login({
                    username: vm.registerAccount.login,
                    password: vm.registerAccount.password,
                }).then(function () {
                    $cookies.putObject("authenticationError", false);
                    $state.go('home');

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is succesful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }


                }).catch(function () {
                    $cookies.putObject("authenticationError", true);
                    $state.go('login');
                });


            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                    vm.errorEmailExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else {
                    vm.error = 'ERROR';
                    lancarDialog("Erro ao tentar salvar o cadastro");
                }
            });

        }

        function registrarApenasParceria() {
            //se o parceiro não veio por um link, então ele quer ser o primeiro parceiro(parceiro da myskills)
            //criar o parceiro e o usuário.
            vm.registerAccount.criarParceiro = true;
            if (vm.parceiroJaSelecionado) {
                vm.registerAccount.parceiro.idSuperior = vm.parceiro.id;
            }

            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            if (vm.idParceiro) {
                vm.registerAccount.idParceiro = vm.idParceiro;
            }

            Parceiro.criarParceiroPorUsuarioAutenciado(vm.registerAccount, function (parceiro) {
                vm.success = 'OK';

                $state.go('parceiro-detail', {id: parceiro.id});

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }


            }, function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                    vm.errorEmailExists = 'ERROR';
                    lancarDialog("Email já está cadastrado no personal, faça o login com o email informado ou recupere sua senha.");
                } else {
                    vm.error = 'ERROR';
                    lancarDialog("Erro ao tentar salvar o cadastro");
                }
            });

        }


        function lancarDialog(texto) {
            ConfirmService.confirm({
                    title: "Atenção.",
                    text: texto,
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

        function goLogin() {
            $state.go('login');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

        function buscaCep() {
            vm.isSaving = true;

            if (vm.registerAccount.parceiro && vm.registerAccount.parceiro.endereco && vm.registerAccount.parceiro.endereco.cep && vm.registerAccount.parceiro.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.registerAccount.parceiro.endereco.cep}, onBuscaCepSuccess, onBuscaCepError);
            } else {
                vm.isSaving = false;
            }
        }

        function onBuscaCepError() {
            vm.isSaving = false;
        }


        function onBuscaCepSuccess(result) {
            vm.registerAccount.parceiro.endereco.logradouro = result.logradouro;
            vm.registerAccount.parceiro.endereco.bairro = result.bairro;
            vm.registerAccount.parceiro.endereco.localidade = result.localidade;
            vm.registerAccount.parceiro.endereco.uf = result.uf;

            vm.isSaving = false;
        }


        function openFileUpload() {
            $uibModal.open({
                templateUrl: 'app/account/registro-parceiro/foto-registro-edit.html',
                controller: 'FotoRegistroController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    cropSize: function () {
                        return {width: 200, height: 200};
                    }
                },
            }).result.then(function (result) {
                console.log("RESULTADO: ", result);
                if (!vm.registerAccount.parceiro) {
                    vm.registerAccount.parceiro = {};
                }
                vm.registerAccount.parceiro.foto = result.data.conteudoFormatado;

            }, function () {
                console.log("VORTOO >>>");
            });
        }

    }
})();
