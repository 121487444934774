(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VideoDetailController', VideoDetailController);

    VideoDetailController.$inject = ['$scope', '$q', '$state',
        '$window', '$uibModalInstance', '$timeout', 'video'];

    function VideoDetailController($scope, $q, $state, $window, $uibModalInstance, $timeout, video) {
        var vm = this;
        vm.clear = clear;

        $timeout(function () {
            vm.video = document.getElementById('video-preview');
            vm.video.src = vm.video.srcObject = null;
            vm.video.muted = false;
            vm.video.volume = 1;
            vm.video.src = video;
        });

        function clear() {
            $uibModalInstance.dismiss('close');
        }
    }
})();
