(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ExperienciaProfissionalController', ExperienciaProfissionalController);

    ExperienciaProfissionalController.$inject = ['$scope', '$state', 'ExperienciaProfissional', 'ExperienciaProfissionalSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Pessoa'];

    function ExperienciaProfissionalController ($scope, $state, ExperienciaProfissional, ExperienciaProfissionalSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Pessoa) {
        var vm = this;
        vm.carregando= false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.baixarAnexo = baixarAnexo;

        loadAll();

        function loadAll () {
            vm.carregando= true;
            if (pagingParams.search) {
                ExperienciaProfissionalSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ExperienciaProfissional.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.experienciaProfissionals = data;
                vm.page = pagingParams.page;
                vm.carregando= false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
                vm.carregando= false;
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: arquivo.contentType});
            return blob;
        }

        function baixarAnexo(arquivoId) {

            if (arquivoId) {
                Pessoa.getAnexo({id: arquivoId}, function (data) {

                    vm.arquivo = data;

                    var fileName = vm.arquivo.nome;
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";

                    var file = getBlobFromBase64(vm.arquivo);
                    var fileURL = window.URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName;
                    a.click();

                });
            }
        }

    }
})();
