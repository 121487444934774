(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ExperienciaProfissionalDialogController', ExperienciaProfissionalDialogController);

    ExperienciaProfissionalDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ExperienciaProfissional', 'PerfilProfissional', 'Empregador', 'Upload', 'cfpLoadingBar'];

    function ExperienciaProfissionalDialogController($http, $timeout, $scope, $stateParams, $uibModalInstance, entity, ExperienciaProfissional, PerfilProfissional, Empregador, Upload, cfpLoadingBar) {
        var vm = this;

        vm.experienciaProfissional = entity;
        if (entity.empregador) {
            vm.cnpj = entity.empregador.cnpj;
        }
        vm.experienciaProfissional.inicio_tela = entity.inicio;
        vm.experienciaProfissional.fim_tela = entity.fim;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.perfilprofissionals = PerfilProfissional.query();
        vm.file = null;
        vm.errorFile = null;
        vm.empregadors = Empregador.query();
        vm.selectEmpregador = selectEmpregador;
        vm.getEmpregadores = entity.empregador;
        vm.progresso = cfpLoadingBar;
        vm.verificarPorteDaEmpresa = verificarPorteDaEmpresa;
        vm.verificarLideravaPessoas = verificarLideravaPessoas;
        vm.verificarTrabalhavaAosSabados = verificarTrabalhavaAosSabados;
        vm.verificarTrabalhavaAosDomingos = verificarTrabalhavaAosDomingos;
        vm.verificarTrabalhavaAosFeriados = verificarTrabalhavaAosFeriados;
        vm.verificarTrabalhavaEmEscalaDeRevezamento = verificarTrabalhavaEmEscalaDeRevezamento;
        vm.verificarMotivoDesligamento = verificarMotivoDesligamento;


        function selectEmpregador($item, $model, $label, $event) {
            if ($model.id) {
                vm.cnpj = $model.cnpj;
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function montarArquivoESalvar(file) {
            //Convert o file blob em base64 com o url do broswer.
            Upload.base64DataUrl(file).then(function (url) {
                var arquivo = {};
                arquivo.nome = file.name;
                var arr = url.split(',');
                var base64 = arr[arr.length - 1];
                arquivo.bytes = base64;
                arquivo.contentType = file.type;
                arquivo.lastModified = new Date();
                vm.experienciaProfissional.arquivo = arquivo;
                saveExperiencia();
            });
        }

        function save() {
            vm.progresso = cfpLoadingBar;
            vm.isSaving = true;
            if (vm.experienciaProfissional.empregador && typeof vm.experienciaProfissional.empregador === 'string') {
                vm.experienciaProfissional.empregador = {
                    nomeFantasia: vm.experienciaProfissional.empregador,
                    cnpj: vm.cnpj
                }
            }
            if (vm.file) {
                montarArquivoESalvar(vm.file);
            } else {
                saveExperiencia();
            }
        }

        function saveExperiencia() {
            if (vm.experienciaProfissional.id !== null) {
                ExperienciaProfissional.update(vm.experienciaProfissional, onSaveSuccess, onSaveError);
            } else {
                ExperienciaProfissional.save(vm.experienciaProfissional, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:experienciaProfissionalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.inicio = false;
        vm.datePickerOpenStatus.fim = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getEmpregadores(param) {
            return $http.get('api/_search/empregadors/', {
                params: {
                    query: param + '*',
                    page: 0,
                    size: 10,
                }
            }).then(function (response) {
                return response.data.map(function (empregador) {
                    return empregador;
                });
            });
        }

        function verificarPorteDaEmpresa(porteDaEmpresa, value) {
            if (porteDaEmpresa && String(porteDaEmpresa) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarLideravaPessoas(lideravaPessoas, value) {
            if (lideravaPessoas && String(lideravaPessoas) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalhavaAosSabados(trabalhavaAosSabados, value) {
            if (trabalhavaAosSabados && String(trabalhavaAosSabados) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalhavaAosDomingos(trabalhavaAosDomingos, value) {
            if (trabalhavaAosDomingos && String(trabalhavaAosDomingos) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalhavaAosFeriados(trabalhavaAosFeriados, value) {
            if (trabalhavaAosFeriados && String(trabalhavaAosFeriados) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalhavaEmEscalaDeRevezamento(trabalhavaEmEscalaDeRevezamento, value) {
            if (trabalhavaEmEscalaDeRevezamento && String(trabalhavaEmEscalaDeRevezamento) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarMotivoDesligamento(motivoDesligamento, value) {
            if (motivoDesligamento && String(motivoDesligamento) === String(value)) {
                return true;
            }
            return false;
        }
    }
})();
