(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .filter('shortName', shortName);


    function shortName() {
        return shortNameFilter;

        function shortNameFilter (input) {
            var name = input;
            var inls = name.match(/\b\w/g) || [];
            inls = ((inls.shift() || '') + (inls.pop() || '')).toUpperCase();
            return inls;
        }
    }

})();
