(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaDialogController', PessoaDialogController);

    PessoaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Pessoa', 'Email', 'Telefone', 'Endereco', 'User'];

    function PessoaDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Pessoa, Email, Telefone, Endereco, User) {
        var vm = this;

        vm.pessoa = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.calculateDiff = calculateDiff;
        vm.save = save;
        vm.emails = Email.query();
        vm.telefones = Telefone.query();
        vm.enderecos = Endereco.query();
        vm.users = User.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function calculateDiff(past, fut) {
            console.log(">>>>>>>>>>>>> ", past, fut)
            var date = past;
            var currentDate = fut ? fut : new Date();

            var days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
            console.log(">>>>>>>>>>>>> days", date, currentDate, days);
            return days;
        }

        function save() {
            vm.isSaving = true;
            if (vm.pessoa.id !== null) {
                Pessoa.update(vm.pessoa, onSaveSuccess, onSaveError);
            } else {
                Pessoa.save(vm.pessoa, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
