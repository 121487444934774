(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ExperienciaProfissionalDeleteController',ExperienciaProfissionalDeleteController);

    ExperienciaProfissionalDeleteController.$inject = ['$uibModalInstance', 'entity', 'ExperienciaProfissional'];

    function ExperienciaProfissionalDeleteController($uibModalInstance, entity, ExperienciaProfissional) {
        var vm = this;

        vm.experienciaProfissional = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExperienciaProfissional.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
