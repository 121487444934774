(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroDetailController', ParceiroDetailController);

    ParceiroDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState',
        'entity', 'Parceiro', 'User', 'pagingParams', 'paginationConstants',
        'ParseLinks', '$state', 'Venda', 'AlertService', '$uibModal', 'ConfirmService', '$window'];

    function ParceiroDetailController($scope, $rootScope, $stateParams, previousState,
        entity, Parceiro, User, pagingParams, paginationConstants,
        ParseLinks, $state, Venda, AlertService, $uibModal, ConfirmService, $window) {
        var vm = this;

        vm.share = share;
        vm.parceiro = entity;
        vm.previousState = previousState.name;

        vm.loadPageUser = loadPageUser;
        vm.loadPageTransacao = loadPageTransacao;
        vm.loadAllUsers = loadAllUsers;
        vm.loadAllTransacoes = loadAllTransacoes;
        vm.solicitarSaldo = solicitarSaldo;
        vm.abrirWhats = abrirWhats;
        vm.loadSubParceiros = loadSubParceiros;

        vm.usersPerPage = 10;
        vm.transacaoPerPage = 10;
        vm.userPage = 1;
        vm.transacaoPage = 1;

        vm.loadAllUsers();
        vm.loadPageTransacao();
        vm.loadSubParceiros();
        vm.tabActive = 0;
        vm.tabs = [
            {icon: "fa fa-user", name: "Usuários", index: 2},
            {icon: "fa fa-comments", name: "Filiados", index: 1},
            {icon: "fa fa-bar-chart", name: "Transações", index: 0},
        ];

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:parceiroUpdate', function (event, result) {
            vm.parceiro = result;
        });

        $scope.$on('$destroy', unsubscribe);


        Venda.getSaldo({id: vm.parceiro.id}, function (data) {
            if (data) {
                vm.saldo = data.saldo;
                vm.qtdTransacoes = data.transacoes;
            }
        });

        function loadSubParceiros() {
            Parceiro.getParceirosPorSuperior({idSuperior: vm.parceiro.id}, function (data) {
                vm.parceiros = data;
            });
        }

        function abrirWhats(telefone) {
            $window.open(" https://wa.me/" + (telefone.startsWith('55') ? telefone : '55' + telefone), "_blank");
        }

        function loadAllTransacoes() {
            Venda.getTransacoesDoParceiro({
                page: vm.transacaoPage - 1,
                size: vm.transacaoPerPage,
                idParceiro: vm.parceiro.id
            }, onSuccessTransacao, onError);
        }

        function loadAllUsers() {
            User.getAllUsersByParceiro({
                page: vm.userPage - 1,
                size: vm.usersPerPage,
                idParceiro: vm.parceiro.id
            }, onSuccessUsers, onError);
        }

        function onSuccessUsers(data, headers) {
            vm.linksUser = ParseLinks.parse(headers('link'));
            vm.totalItemsUser = headers('X-Total-Count');
            vm.queryCountUser = vm.totalItemsUser;
            vm.users = data;
        }

        function onSuccessTransacao(data, headers) {
            vm.linksTransacao = ParseLinks.parse(headers('link'));
            vm.totalItemsTransacao = headers('X-Total-Count');
            vm.queryCountTransacao = vm.totalItemsTransacao;
            vm.transacoes = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPageUser(page) {
            vm.userPage = page;
            loadAllUsers();
        }

        function loadPageTransacao(page) {
            vm.transacaoPage = page;
            loadAllTransacoes();
        }

        function solicitarSaldo() {
            $uibModal.open({
                templateUrl: 'app/entities/parceiro/solicitacao-saque-dialog.html',
                controller: 'SolicitacoSaldoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Parceiro', function (Parceiro) {
                        return vm.parceiro;
                    }]
                    ,
                    saldo: function () {
                        return vm.saldo;
                    }
                }
            }).result.then(function (result) {
                if (result && result.id) {
                    ConfirmService.confirm({
                            title: "Operação Realizada!",
                            text: "Solicitação de saque efetuada",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                } else {
                    ConfirmService.confirm({
                            title: "Operação Não Realizada!",
                            text: "Solicitação de saque ão pode ser efetuada",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                }
            }, function () {
                $state.reload(true);
            });
        }

        function share(link) {
            var text_to_share = link;

            // create temp element
            var copyElement = document.createElement("span");
            copyElement.appendChild(document.createTextNode(text_to_share));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();

            ConfirmService.confirm({
                title: "Operação Realizada",
                text: "Conteúdo copiado com sucesso!",
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

    }
})();
