(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaFiltroController', PessoaFiltroController);

    PessoaFiltroController.$inject = ['$scope', '$state', 'Pessoa', 'PessoaSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Cidade', '$localStorage'];


    function PessoaFiltroController($scope, $state, Pessoa, PessoaSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Cidade, $localStorage) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        //vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;

        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.filtroPessoa = {page: 1, size: 150, offset: 0, itemsPerPage: 150};
        vm.cidades = [];
        vm.calculateAge = calculateAge;


        loadFilter();
        loadAll();

        //findAllCidades()

        function loadAll() {
            updateFilter()
            /*if (pagingParams.search) {
                PessoaSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Pessoa.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }*/
            PessoaSearch.findByFiltro(vm.filtroPessoa, onSuccess, onError)

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pessoas = data;
                //vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function updateFilter() {
            $localStorage.filtro = vm.filtroPessoa.filtro;
            $localStorage.cidade = vm.filtroPessoa.cidade;
            $localStorage.formacao = vm.filtroPessoa.formacao;
            $localStorage.cargo = vm.filtroPessoa.cargo;
        }

        function loadFilter() {
            if ($localStorage.filtro) {
                vm.filtroPessoa.filtro = $localStorage.filtro;
            }
            if ($localStorage.cidade) {
                vm.filtroPessoa.cidade = $localStorage.cidade;
            }
            if ($localStorage.formacao) {
                vm.filtroPessoa.formacao = $localStorage.formacao;
            }
            if ($localStorage.cargo) {
                vm.filtroPessoa.cargo = $localStorage.cargo;
            }
        }

        function findAllCidades() {
            Cidade.query({
                page: pagingParams.page - 1,
                size: 10000,
                sort: ''
            }, function (response) {
                vm.cidades = response;
            }, function (error) {
                console.log("Erro: ", error);
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            /* $state.transitionTo($state.$current, {
                 page: vm.page,
                 sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                 search: vm.currentSearch
             });*/
            vm.filtroPessoa.offset = (vm.filtroPessoa.page -1) * vm.filtroPessoa.itemsPerPage ;
            console.log("OFFSET ", vm.filtroPessoa.offset);
            loadAll()
        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
            vm.filtroPessoa = {};
            updateFilter();
        }

        function calculateAge(birthday) {
            if (birthday) {
                var ageDifMs = Date.now() - new Date(birthday).getTime();
                var ageDate = new Date(ageDifMs); // miliseconds from epoch
                return Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            return null;

        }

    }
})();
