(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroPublicoController', ParceiroPublicoController);

    ParceiroPublicoController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Empregador', '$location'];

    function ParceiroPublicoController($scope, $rootScope, $stateParams, previousState, entity, Empregador, $location) {
        var vm = this;

        vm.share = share;
        vm.parceiro = entity;
        vm.carregarEmpregadores = carregarEmpregadores;
        vm.previousState = previousState.name;
        vm.slickConfig = {
            enabled: true,
            autoplay: true,
            draggable: false,
            dots: true,
            arrows: false,
            autoplaySpeed: 7000,
            method: {},
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1008,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }

            ]
        };
        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:parceiroUpdate', function(event, result) {
            vm.parceiro = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function carregarEmpregadores() {
            Empregador.filtrar({page: 0, pageSize: 10}, function (data, headers) {
                vm.empregadores = data;
            });

            Empregador.count({}, function (data) {
                if (data && data.quantidade) {
                    vm.totalEmpregadores = data.quantidade;
                    vm.totalVagas = data.vagas;
                }
            });

        }
        carregarEmpregadores();
        function share(link) {
            var text_to_share = link;

            // create temp element
            var copyElement = document.createElement("span");
            copyElement.appendChild(document.createTextNode(text_to_share));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();
        }
    }
})();
