(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pre-register', {
            parent: 'account',
            url: '/pre-register',
            data: {
                authorities: [],
                pageTitle: 'register.title',
                // bodyClass: 'testimonials'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/pre-register.html',
                    controller: 'RegisterController',
                    controllerAs: 'vm'
                }
            },
            params: {
                idParceiro: null
            },
            resolve: {
                $uibModalInstance: function() {
                    return null;
                },
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        idParceiro: $stateParams.idParceiro
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    return $translate.refresh();
                }]
            }
        })
            .state('register', {
                parent: 'account',
                url: '/register',
                data: {
                    authorities: [],
                    pageTitle: 'register.title',
                    bodyClass: 'testimonials'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/register/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    idParceiro: null
                },
                resolve: {
                    $uibModalInstance: function() {
                        return null;
                    },
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
