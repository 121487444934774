(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ConfiguracaoIntegracaoDeleteController',ConfiguracaoIntegracaoDeleteController);

    ConfiguracaoIntegracaoDeleteController.$inject = ['$uibModalInstance', 'entity', 'ConfiguracaoIntegracao'];

    function ConfiguracaoIntegracaoDeleteController($uibModalInstance, entity, ConfiguracaoIntegracao) {
        var vm = this;

        vm.configuracaoIntegracao = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ConfiguracaoIntegracao.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
