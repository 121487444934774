(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParecerPessoaController', ParecerPessoaController);

    ParecerPessoaController.$inject = ['$scope', '$rootScope', '$stateParams',
        '$uibModalInstance', 'Empregador', 'user', 'empregadorId', 'ConfirmService'];

    function ParecerPessoaController($scope, $rootScope, $stateParams,
                                     $uibModalInstance, Empregador, user, empregadorId, ConfirmService) {
        var vm = this;

        vm.clear = clear;
        vm.saveParecer = saveParecer;
        vm.selecionarParecer = selecionarParecer;
        vm.novoParecer = novoParecer;
        vm.selecionarParecer = selecionarParecer;
        vm.removerParecer = removerParecer;
        vm.pareceres = [];


        function selecionarParecer(parecer) {
            vm.parecer = parecer;
        }

        function removerParecer(parecer) {
            ConfirmService.swal({
                    title: "Tem certeza que deseja remover esse parecer?",
                    text: "Esta operação não poderá ser desfeita!",
                    type: "warning",
                    cancelButtonText: "Cancelar",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim, quero remover!",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Empregador.deleteObservacoesPessoa({id: parecer.id}, function (data) {
                            init();
                        });
                    }
                })

        }

        function novoParecer() {
            vm.parecer = {
                user: {id: user.id},
                empregador: vm.empregador,
            }
        }

        function init() {
            vm.carregando = true;
            vm.descricao = "";
            Empregador.getObservacoesPessoa({pessoaId: user.id, empregadorId: empregadorId}, function (data) {
                vm.pareceres = data;
                vm.carregando = false;
            }, function (error) {
                vm.carregando = false;
            });
            Empregador.get({id: empregadorId}, function (empregador) {
                vm.empregador = empregador;
            });
            vm.parecer = null;
        }

        function saveParecer() {
            vm.carregando = true;
            if (vm.parecer)
                Empregador.saveObservacoesPessoa(vm.parecer, function (data) {
                    init();
                }, function (error) {
                    init();
                });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        init();

    }
})();
