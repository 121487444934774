(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Empregador', Empregador);

    Empregador.$inject = ['$resource'];

    function Empregador($resource) {
        var resourceUrl = 'api/empregadors/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'filtrar': {url: 'api/filtrar-empregador', method: 'POST', isArray: true},
            'get': {
                method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }, 'create': {
                method: 'POST', url: 'api/criar-empregador'
            },
            'getVagasEmpresa': {url: 'api/empregador-vaga-by-empregador-id', method: 'POST', isArray: true},
            'getObservacoesPessoa': {
                url: 'api/observacao-pessoa-by-user/:pessoaId/:empregadorId',
                method: 'GET',
                isArray: true
            },
            'comunicarCadastro': {url: 'api/comunicar-cadastro/:id', method: 'GET'},
            'imprimirMovimentacao': {url: 'api/imprimir-movimentacao/:id', method: 'GET'},
            'imprimirExtrato': {url: 'api/imprimir-extrato/:id', method: 'GET'},
            'saveObservacoesPessoa': {url: 'api/observacao-pessoa', method: 'POST'},
            'salvarAnexo': {url: 'api/empregador-anexo', method: 'POST'},
            'buscarAnexo': {url: 'api/empregador-anexo-por-empregador/:id', method: 'GET', isArray: true},
            'deleteAnexo': {url: 'api/empregador-anexo/:id', method: 'DELETE'},
            'deleteObservacoesPessoa': {url: 'api/observacao-pessoa/:id', method: 'DELETE'},
            'esconderVagasEmpresa': {url: 'api/esconder-vaga-empregador/:id', method: 'GET'},
            'inativarVagasEmpresa': {url: 'api/inativar-vaga-empregador/:id', method: 'GET'},
            'mudarQuantidadeVagasEmpresa': {url: 'api/quantidade-vaga-empregador/:id/:quantidade', method: 'GET'},
            'adicionarUsuarioAdministrador': {
                url: 'api/adicionar-usuario-empregador',
                method: 'POST'
            },
            'removerUsuarioEmpregador': {
                url: 'api/remover-usuario-empregador',
                method: 'POST'
            },
            'getUsuariosAdministradores': {
                url: 'api/usuarios-empregador/:id',
                method: 'GET',
                isArray: true
            },
            'getMovimentacoes': {
                url: 'api/movimentacoes-por-empregador/:id', method: 'GET', isArray: true
            }, 'getPessoasVaga': {
                url: 'api/pessoas-por-vaga', method: 'POST'
            }, 'savePessoasVaga': {
                url: 'api/save-pessoas-vaga', method: 'POST'
            }, 'tornarParceiro': {
                url: 'api/definir-empregador-parceiro', method: 'POST'
            }, 'vincularParceiro': {
                url: 'api/vincular-empregador-parceiro', method: 'POST'
            }, 'savePessoaEmpresaSimples': {
                url: 'api/save-pessoa-empresa-simples', method: 'POST'
            }, 'getSaldo': {
                url: 'api/get-saldo', method: 'GET', transformResponse: function (data) {
                    if (data) {
                        return {saldo: data};
                    }
                    return data;
                }
            }, 'addSaldo': {
                url: 'api/add-saldo', method: 'GET',
            }, 'addVagasEmpresa': {
                url: 'api/adicionar-vaga-empregador', method: 'GET',
            }, 'addInteresse': {
                url: 'api/add-interesse/:id', method: 'GET',
            }, 'buscarInteresse': {
                url: 'api/buscar-interesse/:id', method: 'GET',
            }, 'buscarEmpregadoresDoParceiro': {
                url: 'api/empregadores-do-parceiro/:id', method: 'POST', isArray: true
            }, 'contarEmpregadoresDoParceiro': {
                url: 'api/contar-do-parceiro/:id', method: 'POST'
            }, 'comprarPerfil': {
                url: 'api/comprar-perfil/:id', method: 'GET',
            }, 'comprarDestaque': {
                url: 'api/comprar-destaque/:destaqueId/:empregadorId', method: 'GET',
            }, 'comprarAvaliacao': {
                url: 'api/comprar-avaliacao/:idPessoa/:idProduto', method: 'GET',
            }, 'temPerfil': {
                url: 'api/tem-perfil/:idPessoa', method: 'GET',
            }, 'temPlanoPagamentoPremium': {
                url: 'api/tem-plano-pagamento/:idEmpregador', method: 'GET',
            }, 'buscarInteressePorEmpresa': {
                url: 'api/buscar-interesse-por-empresa/:id', method: 'GET',
            }, 'transferirCredito': {
                url: 'api/transferir-credito/:de/:para', method: 'GET',
            }, 'count': {
                url: 'api/empregador/contar', method: 'POST',
            }, 'countPremium': {
                url: 'api/empregador/contar-premium/:id', method: 'GET',
            }, 'getByUserId': {
                url: 'api/empregador-by-user-id/:id', method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }, 'getByUser': {
                url: 'api/empregador-by-user', method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getParceirosAgrupados': {
                url: 'api/parceiros-agrupados/:idUsuario', method: 'GET',  isArray: true
            },
            'addParceirosAgrupados': {
                url: 'api/add-parceiros-agrupados', method: 'POST',
            },
            'removerParceirosAgrupados': {
                url: 'api/remover-parceiros-agrupados', method: 'POST',
            },
            'update': {method: 'PUT'},
            'getGeoFromAddress': {
                url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/:address.json?access_token=pk.eyJ1IjoiZ3VzdGF2b3NjYXJwaW5pIiwiYSI6ImNreXcydGc4dTAyZGgycG44ZHVmYXphbWQifQ.qrAPcayDWkkAb_P7X5dWzQ'
            }
        });
    }
})();
