(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('politica', {
            parent: 'app',
            url: '/politica-privacidade',
            data: {
                authorities: [],
                specialClass : 'testimonials'
            },
            views: {
                'navbar@': {

                },
                'content@': {
                    templateUrl: 'app/politica-privacidade/politica.html',
                    controller: 'PoliticaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        ;
    }
})();
