(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('MenuController', MenuController);

    MenuController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function MenuController($state, Auth, Principal, ProfileService, LoginService) {
        var vm = this;
        vm.$state = $state;
        vm.isAuthenticated = Principal.isAuthenticated;

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });


    }
})();
