(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VagaController', VagaController);

    VagaController.$inject = ['$scope', '$state', '$timeout', 'Vaga', '$uibModal', 'paginationConstants', 'Principal', 'Principal', 'ConfirmService'];

    function VagaController($scope, $state, $timeout, Vaga, $uibModal, pagingParams, paginationConstants, Principal, ConfirmService) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.maxResult = 24;
        vm.page = vm.page ? vm.page : 1;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.loadMore = loadMore;
        vm.cleanAndLoadAll = cleanAndLoadAll;
        vm.carregarVagas = carregarVagas;

        vm.carregarFuncoes = carregarFuncoes;
        vm.getFuncoesSelecionadas = getFuncoesSelecionadas;

        vm.carregarSetores = carregarSetores;
        vm.getSetoresSelecionadas = getSetoresSelecionadas;

        vm.getImageVaga = getImageVaga;

        vm.vagasUsuario = Vaga.getVagasUsuario();
        vm.buscarVaga = buscarVaga;

        vm.funcoes = [];
        vm.setores = [];
        vm.filtros = {
            funcoes: pagingParams.funcoes ? pagingParams.funcoes : [],
            setores: pagingParams.setores ? pagingParams.setores : [],
            cidades: pagingParams.cidades ? pagingParams.cidades : [],
            formacoes: pagingParams.formacoes ? pagingParams.formacoes : [],
            sexos: pagingParams.sexos ? pagingParams.sexos : [],
            salarioInicial: pagingParams.salarioInicial ? pagingParams.salarioInicial : null,
            salarioFinal: pagingParams.salarioFinal ? pagingParams.salarioFinal : null,
            filtro: pagingParams.filtro ? pagingParams.filtro : null,
            sort: pagingParams.sort ? pagingParams.sort : null,
            page: vm.page - 1,
            pageSize: pagingParams.pageSize ? pagingParams.pageSize : vm.maxResult
        };

        vm.vagas = [];

        function buscarVaga(vaga) {
            abrirTelaDeVaga(vaga);
        }

        $timeout(function () {
            window.onbeforeunload = function() {window.scrollTo(0,0);}
        });



        function abrirTelaDeVaga(vaga) {
            $uibModal.open({
                templateUrl: 'app/entities/vaga/vaga-dialog.html',
                controller: 'VagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Vaga', function (Vaga) {
                        return Vaga.get({id: vaga.id}).$promise;
                    }]
                }
            });
        }

        function carregarFuncoes() {
            Vaga.getFuncoes({parameter: ""}, function (data) {
                vm.funcoes = data;
            });
        }

        function getFuncoesSelecionadas() {
            var funcoes = "";

            for (var i = 0; i < vm.filtros.funcoes.length; i++) {
                var funcao = JSON.parse(vm.filtros.funcoes[i]);
                funcoes += (funcoes == "" ? "" : ", ") + funcao.descricao;
            }

            return funcoes;
        }

        function carregarSetores() {
            Vaga.getSetores({parameter: ""}, function (data) {
                vm.setores = data;
            });
        }

        function getSetoresSelecionadas() {
            var setores = "";

            for (var i = 0; i < vm.filtros.setores.length; i++) {
                var setor = JSON.parse(vm.filtros.setores[i]);
                setores += (setores == "" ? "" : ", ") + setor.descricao;
            }

            return setores;
        }

        function getImageVaga(vagaSetor) {
            return Vaga.getImageVaga(vagaSetor);
        }

        function carregarVagas() {
            vm.carregando = true;

            var funcoes = [];
            for (var i = 0; i < vm.filtros.funcoes.length; i++) {
                funcoes.push(JSON.parse(vm.filtros.funcoes[i]));
            }

            var setores = [];
            for (var i = 0; i < vm.filtros.setores.length; i++) {
                setores.push(JSON.parse(vm.filtros.setores[i]));
            }

            vm.filtros.funcoes = funcoes;
            vm.filtros.setores = setores;

            Vaga.query(vm.filtros, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.vagas = data;
                vm.carregando = false;
                vm.page = vm.filtros.page + 1;
                vm.totalItems = headers('X-Total-Count');
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.carregando = false;
            }
        }

        function cleanAndLoadAll() {
            vm.page = 1;
            vm.filtros = {
                funcoes: [],
                setores: [],
                cidades: [],
                formacoes: [],
                sexos: [],
                salarioInicial: null,
                salarioFinal: null,
                filtro: null,
                sort: null,
                page: vm.page - 1,
                pageSize: vm.maxResult
            };

            vm.vagas = [];
            carregarVagas();
        }

        function loadMore(page) {
            vm.page = page;
            vm.filtros.page = vm.page -1;
            carregarVagas();
        }

        function transition() {
            vm.filtros = {
                funcoes: vm.filtros.funcoes ? vm.filtros.funcoes : [],
                setores: vm.filtros.setores ? pagingParams.setores : [],
                cidades: vm.filtros.cidades ? pagingParams.cidades : [],
                formacoes: vm.filtros.formacoes ? pagingParams.formacoes : [],
                sexos: vm.filtros.sexos ? pagingParams.sexos : [],
                salarioInicial: vm.filtros.salarioInicial ? pagingParams.salarioInicial : null,
                salarioFinal: vm.filtros.salarioFinal ? pagingParams.salarioFinal : null,
                filtro: vm.filtros.filtro ? pagingParams.filtro : null,
                sort: vm.filtros.sort ? pagingParams.sort : null,
                page: vm.page ? vm.page - 1 : 1,
                pageSize: vm.filtros.pageSize ? vm.filtros.pageSize : vm.maxResult
            };

            carregarVagas();
        }

        carregarFuncoes();
        carregarSetores();
        carregarVagas();
    }
})();
