(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilEditController', PerfilEditController);

    PerfilEditController.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Pessoa', 'TipoStatus', 'pagingParams'];

    function PerfilEditController($rootScope, $scope, Principal, $state, Pessoa, TipoStatus, pagingParams) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.verificarTipoSanguineo = verificarTipoSanguineo;
        vm.verificarNivelEscolaridade = verificarNivelEscolaridade;
        vm.arquivo = {};
        vm.escolaridades = Pessoa.niveisEscolaridades();
        vm.tiposSanguineos = Pessoa.tiposSanguineos();
        vm.message = pagingParams.message;


        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });

        getAccount();
        loadStatus();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);
            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
            }
        }

        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
        }


        function save() {
            vm.isSaving = true;
            Pessoa.updatePerfil(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.isEditing = false;
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
            $state.go('perfil');
        }

        function clear() {
            $state.go('perfil');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function verificarTipoSanguineo(tipoSanguineo, value) {
            if (tipoSanguineo && String(tipoSanguineo) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarNivelEscolaridade(nivelEscolaridade, value) {
            if (nivelEscolaridade && String(nivelEscolaridade) === String(value)) {
                return true;
            }
            return false;
        }
    }
})();
