(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroSuperiorController', ParceiroSuperiorController);

    ParceiroSuperiorController.$inject = ['$scope', 'Principal', 'Parceiro', 'entity', '$window', '$uibModalInstance'];

    function ParceiroSuperiorController($scope, Principal, Parceiro, entity, $window, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.ok = ok;
        vm.parceiro = entity;
        Parceiro.query({page:0, size:999, filtro: ""}, function (data) {
            vm.parceiros = [];
            for (var i = 0; i < data.length; i++) {
                var parceiro = data[i];
                if (parceiro.id !== vm.parceiro.id) {
                    vm.parceiros.push(parceiro);
                }
            }
        });

        function ok() {
            $uibModalInstance.close(vm.parceiro);
        }

        function clear() {
            $uibModalInstance.dismiss('close');

        }

    }
})();
