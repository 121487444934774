(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('CursoController', CursoController);

    CursoController.$inject = ['$scope', '$state', 'Curso', 'CursoSearch', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants', 'ConfirmService', '$uibModal', 'Principal', '$location', 'Parceiro'];

    function CursoController($scope, $state, Curso, CursoSearch, ParseLinks,
        AlertService, pagingParams, paginationConstants, ConfirmService, $uibModal, Principal, $location, Parceiro) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.parceiroId = pagingParams.id;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.verCurso = verCurso;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;


        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated();
            vm.account = account;

        });

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
            });
        }


        carregarParceiro();
        loadAll();

        function loadAll() {

            Curso.query({
                id: vm.parceiroId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cursos = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function verCurso(curso) {
            console.log("<< << <<<< MM M> >> >>> ", vm.account);
            // if (vm.account) {
            $state.go("curso-detail", {"id": curso.id});
            // } else {
            //     ConfirmService.swal({
            //             title: "Você já tem um Perfil MySkills?",
            //             text: "Para adiquirir uma avaliação é necessário um Perfil MySkills!",
            //             type: "warning",
            //             showCancelButton: true,
            //             confirmButtonColor: "#DD6B55",
            //             confirmButtonText: "Cadastrar AGORA",
            //             cancelButtonText: "Já tenho cadastro",
            //             closeOnConfirm: false,
            //             closeOnCancel: false
            //         },
            //         function (isConfirm) {
            //             if (isConfirm) {
            //                 abrirRegistro(curso);
            //                 ConfirmService.close();
            //             } else {
            //                 abrirLogin(curso);
            //                 ConfirmService.close();
            //             }
            //         });
            // }
        }

    }
})();
