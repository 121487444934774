(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VendaDetailController', VendaDetailController);

    VendaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState',
        'entity', 'User', 'pagingParams', 'paginationConstants',
        'ParseLinks', '$state', 'Venda', 'AlertService'];

    function VendaDetailController($scope, $rootScope, $stateParams, previousState,
                                   entity, User, pagingParams, paginationConstants,
                                   ParseLinks, $state, Venda, AlertService) {
        var vm = this;

        vm.venda = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:VendaUpdate', function (event, result) {
            vm.venda = result;
        });

        $scope.$on('$destroy', unsubscribe);

        function onError(error) {
            AlertService.error(error.data.message);
        }

    }
})();
