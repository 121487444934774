(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('SolicitacoSaldoDialogController', SolicitacoSaldoDialogController);

    SolicitacoSaldoDialogController.$inject = ['$timeout', '$scope', '$stateParams',
        '$uibModalInstance', 'entity', 'saldo', 'Venda', 'ConfirmService'];

    function SolicitacoSaldoDialogController($timeout, $scope, $stateParams,
                                             $uibModalInstance, parceiro, saldo, Venda,ConfirmService) {
        var vm = this;

        vm.parceiro = parceiro;
        vm.valor = saldo;
        vm.saldo = saldo;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (!vm.saldo || vm.saldo < vm.valor) {
                ConfirmService.confirm({
                    title: "Atenção",
                    text: "Você não tem saldo suficiente para solicitar um saque",
                        confirm: function () {
                        },
                        cancel: function () {
                        }
                    }
                );

            } else if (vm.parceiro.id !== null) {
                Venda.solicitarSaque({parceiro: vm.parceiro, valor: vm.valor}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:parceiroUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
