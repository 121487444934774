(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorEscolherPlanoController', EmpregadorEscolherPlanoController);

    EmpregadorEscolherPlanoController.$inject = ['$state', 'entity', 'Empregador', 'PlanoCredito', 'previousState'];

    function EmpregadorEscolherPlanoController($state, entity, Empregador, PlanoCredito, previousState) {
        var vm = this;

        vm.empregador = entity;
        vm.clear = clear;
        vm.confirmPlano = confirmPlano;
        vm.planoCreditos = [];
        vm.planosPorTipo = {};

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function carregarPlanos() {
            PlanoCredito.query({}, function (resp) {
                console.log("resp", resp);
                vm.planoCreditos = resp;
                for (var i = 0; i < resp.length; i++) {
                    var plano = resp[i];
                    if (!vm.planosPorTipo[plano.tipoPlano]) {
                        vm.planosPorTipo[plano.tipoPlano] = {tipo: plano.tipoPlano, planos: []};
                    }
                    vm.planosPorTipo[plano.tipoPlano].planos.push(plano);
                }
            }, function (error) {
                console.log("resp", error);
            });
        }

        carregarPlanos();

        function confirmPlano(plano) {
            // $uibModalInstance.close(plano);
            $state.go('comprar-saldo', {id: plano.id}, {reload: true});
            // $state.go('comprar-saldo', null, {reload: true});
        }

        vm.slickConfig = {
            enabled: true,
            autoplay: false,
            draggable: false,
            dots: true,
            arrows: true,
            method: {},
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1008,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }

            ]
        };
    }
})();
