(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('FotoController', FotoController);

    FotoController.$inject = ['$scope', 'Principal', 'Pessoa', '$state', 'entity', 'Upload', '$window', '$uibModalInstance'];

    function FotoController($scope, Principal, Pessoa, $state, entity, Upload, $window, $uibModalInstance) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.clear = clear;
        vm.submit = submit;
        vm.rotateImage = rotateImage;
        vm.ok = ok;
        vm.progressPercentage = null;
        vm.carregando = false;

        vm.fileOriginal = null;
        vm.cropper = {sourceImage: null, croppedImage: null};
        vm.bounds = {left: 0, right: 0, top: 0, bottom: 0};


        function ok() {
            console.log("vm.cropper ", vm.cropper);
            submit()
        }


        function clear() {
            $uibModalInstance.dismiss('close');
        }

        getAccount();

        function getAccount() {

            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account) {
                    vm.carregando = true;
                    Pessoa.getByUser(vm.account.login, findByLoginSuccess);
                }
            });

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                if (vm.pessoa && vm.pessoa.fotoOriginal) {
                    toDataURL(vm.pessoa.fotoOriginal.conteudoFormatado, carregaImagem);
                } else {
                    vm.carregando = false;
                }

            };
        }

        function carregaImagem(base64Img) {
            vm.carregando = false;
            vm.fileOriginal = base64Img;
            vm.cropper.sourceImage = vm.fileOriginal;
        }

        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function rotateImage() {
            vm.carregando = true;
            convertImage(function () {
                vm.carregando = false;
            });
        }

        function convertImage(callback) {
            var img = new Image();
            img.src = vm.cropper.sourceImage;
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.drawImage(img, 0, 0);
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            vm.cropper.sourceImage = canvas.toDataURL();
            callback();
        }


        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:photoChange', result);
            vm.isSaving = false;
            clear();
        }


        function submit() {
            console.log("arquivo antes ", vm.fileOriginal);
            if (vm.cropper.croppedImage) {
                uploadCropped(vm.cropper.croppedImage, vm.cropper.sourceImage);
            }
        };

        // upload on file select or drop
        function uploadCropped(file, original) {
            console.log(" iniciou uma chamada ",  Upload.dataUrltoBlob(file, vm.fileOriginal.name));
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/foto',
                data: {
                    file: Upload.dataUrltoBlob(file, vm.fileOriginal.name),
                    original: Upload.dataUrltoBlob(original, vm.fileOriginal.name)
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                clear();
                $scope.$emit('mySkillsPersonalApp:pessoaUpdate', resp);
                console.log("Emitiu mySkillsPersonalApp:pessoaUpdate");
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
    }
})();
