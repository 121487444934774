(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorEscolherDestaqueController', EmpregadorEscolherDestaqueController);

    EmpregadorEscolherDestaqueController.$inject = ['$uibModalInstance', '$state', 'entity', 'Empregador', 'PlanoDestaque'];

    function EmpregadorEscolherDestaqueController($uibModalInstance, $state, entity, Empregador, PlanoDestaque) {
        var vm = this;

        vm.empregador = entity;
        vm.clear = clear;
        vm.confirmDestaque = confirmDestaque;
        vm.destaqueCreditos = [];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function carregarDestaques() {
            PlanoDestaque.query({}, function (resp) {
                console.log("resp", resp)
                vm.destaqueCreditos = resp;
            }, function (error) {
                console.log("resp", error);
            })
        }

        carregarDestaques();

        function confirmDestaque(destaque) {
            $uibModalInstance.close(destaque);
        }

        vm.slickConfig = {
            enabled: true,
            autoplay: false,
            draggable: false,
            dots: true,
            arrows: true,
            method: {},
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1008,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }

            ]
        };
    }
})();
