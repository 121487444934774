(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('foto', {
                parent: 'perfil',
                url: '/foto/edit/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/foto/foto-edit.html',
                        controller: 'FotoController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Pessoa', function (Pessoa) {
                                console.log("param: ", $stateParams.id);
                                return Pessoa.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('perfil', {}, {reload: true});
                    }, function () {
                        $state.go('perfil', {}, { reload: true });
                    });
                }]
            });
    }
})();
