(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('CamposPersonalizadosController', CamposPersonalizadosController);

    CamposPersonalizadosController.$inject = ['$scope', '$state', 'TipoStatus', 'AtributoGenerico', 'ConfirmService', 'Tutorial'];

    function CamposPersonalizadosController($scope, $state, TipoStatus, AtributoGenerico, ConfirmService, Tutorial) {
        var vm = this;

        vm.colorOptions = {
            format: 'HEX'
        };
        vm.camposPersonalizadoss = [];
        vm.loadAll = loadAll;
        vm.saveStatus = saveStatus;

        vm.saveTutorial = saveTutorial;

        vm.saveAtributoGenerico = saveAtributoGenerico;
        vm.deleteAtributoGenerico = deleteAtributoGenerico;
        vm.selecionarAtributo = selecionarAtributo;

        vm.deleteStatus = deleteStatus;
        vm.deleteTutorial = deleteTutorial;

        vm.selecionarStatus = selecionarStatus;
        vm.selecionarTutorial = selecionarTutorial;

        vm.novoAtributo = novoAtributo;

        vm.tipoStatus = {};
        vm.tutorial = {};

        vm.tiposAtributo = [
            {value: 'EMPREGADOR', descricao: 'Empresa - Cadastro'},
            {value: 'VAGA', descricao: 'Empresa - Vaga'},
            {value: 'INTERESSES', descricao: 'Perfil - Interesses Profissionais'},
            {value: 'DADOS_PESSOAIS', descricao: 'Perfil - Dados Pesoais'},
            {value: 'PCD', descricao: 'Perfil - PCD'},
            {value: 'DADOS_PESSOAIS_FAMILIA', descricao: 'Perfil - Dados Familia'},
            {value: 'DADOS_PESSOAIS_CONJUGE', descricao: 'Perfil - Dados Cônjuge'},
            {value: 'DADOS_PESSOAIS_FILHOS', descricao: 'Perfil - Dados Filhos'}];


        function loadTutoriais() {
            Tutorial.query({page: 0, size: 999}, function (result) {
                vm.tutoriais = result;
            });
        }

        function loadStates() {
            var states = $state.get();
            vm.paginas = [];
            for (var i = 0; i < states.length; i++) {
                var state = states[i];
                if (vm.paginas.indexOf(state.name) < 0)
                    vm.paginas.push(state.name);
            }
        }

        loadStates();

        function loadAtributos() {
            AtributoGenerico.query({page: 0, size: 999}, function (result) {
                vm.atributos = {};
                for (var i = 0; i < result.length; i++) {
                    var atr = result[i];
                    if (!vm.atributos[atr.tipoAtributo]) {
                        vm.atributos[atr.tipoAtributo] = {tipoAtributo: atr.tipoAtributo, atributos: []};
                    }
                    vm.atributos[atr.tipoAtributo].atributos.push(atr);
                }
            });
        }

        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
        }

        function loadAll() {
            loadStatus();
            loadAtributos();
            loadTutoriais();
        }

        loadAll();

        function saveStatus() {
            function postSave() {
                vm.tipoStatus = {};
                vm.mostrarNovoStatus = false;
                loadStatus();
            }

            TipoStatus.save(vm.tipoStatus, postSave);
        }

        function saveTutorial() {
            function postSave() {
                vm.tutorial = {};
                vm.mostrarNovoTutorial = false;
                loadTutoriais();
            }

            Tutorial.save(vm.tutorial, postSave);
        }

        function novoAtributo(tipoAtributo) {
            vm.atributoGenerico = {
                classeCss: 'col-md-4',
                ordem: 1,
                tamanhoMinimo: 2,
                tamanhoMaximo: 255,
                tipoAtributo: tipoAtributo,
                tipoCampo: 'TEXTO',
                permiteRepetir: false
            };
        }

        function saveAtributoGenerico() {

            function postSave() {
                novoAtributo(vm.atributoGenerico.tipoAtributo);
                vm.mostrarAtributo = false;
                loadAll();
            }

            AtributoGenerico.save(vm.atributoGenerico, postSave);
        }

        function deleteStatus(status) {
            TipoStatus.delete({id: status.id}, function () {
                loadStatus();
            });
        }

        function deleteTutorial(tutorial) {
            Tutorial.delete({id: tutorial.id}, function () {
                loadTutoriais();
            });
        }

        function deleteAtributoGenerico(atributo) {

            ConfirmService.swal({
                title: "Remover",
                text: "Tem certeza que deseja remover o atributo " + atributo.descricao,
                showCancelButton: true,
                closeOnConfirm: true,
                cancelButtonText: "Cancelar",
                animation: "slide-from-top",
            }, function (isConfirm) {
                if (isConfirm) {
                    AtributoGenerico.delete({id: atributo.id}, function () {
                        loadAll();
                    });
                }
            });


        }

        function selecionarStatus(status) {
            vm.tipoStatus = status;
            vm.mostrarNovoStatus = true;
        }

        function selecionarAtributo(atributo) {
            vm.atributoGenerico = atributo;
            vm.mostrarAtributo = true;
        }

        function selecionarTutorial(tutorial) {
            vm.tutorial = tutorial;
            vm.mostrarNovoTutorial = true;
        }


    }
})();
