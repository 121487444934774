(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Venda', Venda);

    Venda.$inject = ['$resource'];

    function Venda($resource) {
        var resourceUrl = 'api/vendas/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUltimasVendas': {
                url: 'api/vendas-ultimos-meses',
                method: 'GET',
                isArray: true
            },
            'pagar': {
                url: 'api/vendas-pagar/:id',
                method: 'GET',
            },
            'efetivarSaque': {
                url: 'api/aprovar-saque/:id',
                method: 'GET',
            },
            'solicitarSaque': {
                url: 'api/solicitar-saque',
                method: 'POST',
            },
            'getSolicitacoesSaque': {
                url: 'api/solicitacoes-saque',
                method: 'GET',
                isArray: true
            },
            'getSaldo': {
                url: 'api/saldo-parceiro/:id',
                method: 'GET',
            },
            'getTransacoesDoParceiro': {
                isArray: true,
                url: 'api/transacoes-por-parceiro',
                method: 'GET',
            },
            'getPublico': {
                url: 'api/venda-publico/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
