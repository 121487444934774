(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('login', {
            parent: 'app',
            url: '/login?parceiro&usuario&redirecionar&tipoLogin',
            data: {
                authorities: [],
                bodyClass: 'testimonials'
            },
            views: {
                'navbar@': {},
                'footer@': {},
                'content@': {
                    templateUrl: 'app/components/login/login.html',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                }
            },
            params: {
                parceiro: null,
                usuario: null,
                redirecionar: null,
                tipoLogin : 'PESSOA'
            },
            resolve: {
                $uibModalInstance: function() {
                    return null;
                },
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        parceiro: $stateParams.parceiro,
                        usuario: $stateParams.usuario,
                        redirecionar: $stateParams.redirecionar,
                        tipoLogin: $stateParams.tipoLogin,
                    };
                }],
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        ;
    }
})();
