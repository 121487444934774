(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroComissaoController', ParceiroComissaoController);

    ParceiroComissaoController.$inject = ['$scope', 'Principal', 'Parceiro', '$state', 'entity', '$window', '$uibModalInstance'];

    function ParceiroComissaoController($scope, Principal, Parceiro, $state, entity, $window, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.ok = ok;
        vm.parceiro = entity;
        vm.comissao = entity.comissao;
        vm.superiores = Parceiro.getParceirosPorFilho({idFilho: entity.id})

        function ok() {
            vm.mensagem = null;
            if (vm.comissao <0 || vm.comissao>50) {
                vm.mensagem = "O Comissão é obrigatório e deve estar entre 0 e 50%.";
                return false;
            }
            if (vm.parceiro.superior && vm.parceiro.superior.comissao < vm.comissao) {
                vm.mensagem = "O valor de comissão não pode ser maior que " + vm.parceiro.superior.comissao+"%";
                return false
            }
            $uibModalInstance.close(vm.comissao);
        }

        function clear() {
            $uibModalInstance.dismiss('close');


        }

    }
})();
