(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('SocialNetworkController', SocialNetworkController);

   SocialNetworkController.$inject = ['$scope', '$uibModalInstance', 'entity'];

    function SocialNetworkController($scope, $uibModalInstance, entity) {
        var vm = this;
        vm.clear = clear;
        vm.pessoa = entity;
        vm.copyToClipboard = copyToClipboard;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        function copyToClipboard(url) {
            url = "https://personal.myskills.com.br/#/perfil/"+vm.pessoa.id

            if (window.clipboardData && window.clipboardData.setData) {
                 // IE specific code path to prevent textarea being shown while dialog is visible.
                 return clipboardData.setData("Text", url);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = url;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                     return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                     console.warn("Copy to clipboard failed.", ex);
                     return false;
                 } finally {
                     document.body.removeChild(textarea);
                 }
            }
        }
    }
})();
