(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('PlanoCredito', PlanoCredito);

    PlanoCredito.$inject = ['$resource', 'DateUtils'];

    function PlanoCredito($resource, DateUtils) {
        var resourceUrl = 'api/plano-credito/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                isArray: false,
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.inicio = DateUtils.convertLocalDateFromServer(data.inicio);
                        data.fim = DateUtils.convertLocalDateFromServer(data.fim);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
