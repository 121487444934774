(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ProdutoUsuarioController', ProdutoUsuarioController);

    ProdutoUsuarioController.$inject = ['$scope', '$state', 'Produto', '$uibModalInstance'];

    function ProdutoUsuarioController($scope, $state, Produto, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.produtosUsuario = Produto.getProdutosUsuario();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
