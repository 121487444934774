(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        /*$stateProvider.state('questionario', {
            parent: 'app',
            url: '/auto-avaliacao',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/questionario/questionario.html',
                    controller: 'QuestionarioController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                entity: ['Questionario', function (Questionario) {
                    return Questionario.get().$promise;
                }]
            }
        });*/
        $stateProvider.state('resultado', {
            url: '/resultado-avaliacao/{id}',
            parent: 'app',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/questionario/resultado.html',
                    controller: 'ResultadoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Questionario', function ($stateParams, Questionario) {
                    return Questionario.getResultado({id: $stateParams.id}).$promise;
                }]
            }
        })

        ;
    }
})();
