(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('DocumentoPessoalDeleteController',DocumentoPessoalDeleteController);

    DocumentoPessoalDeleteController.$inject = ['$uibModalInstance', 'entity', 'DocumentoPessoal'];

    function DocumentoPessoalDeleteController($uibModalInstance, entity, DocumentoPessoal) {
        var vm = this;

        vm.documentoPessoal = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DocumentoPessoal.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
