(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilHeaderController', PerfilHeaderController);

    PerfilHeaderController.$inject = ['$scope', 'Principal', '$state', 'Pessoa', '$http'];

    function PerfilHeaderController($scope, Principal, $state, Pessoa, $http) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.baixarCurriculo = baixarCurriculo;
        vm.percentualCadastro = null;
        vm.arquivo = {};

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });

        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            return blob;
        }

        function baixarCurriculo() {


            Pessoa.getCurriculo({}, function (data) {
              /*  console.log(data);
                var newWindow = window.open("", "mywindow", "menubar=1,resizable=1,width=500,height=500");
                newWindow.document.write(data);*/

                 console.log("data ", data);
                 vm.arquivo = data;

                 var fileName = vm.arquivo.nome;
                 var a = document.createElement("a");
                 document.body.appendChild(a);
                 a.style = "display: none";

                 var file = getBlobFromBase64(vm.arquivo);
                 var fileURL = window.URL.createObjectURL(file);
                 a.href = fileURL;
                 a.download = fileName + ".pdf";
                 a.click();

            });
        }

        getAccount();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);
            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
                buscarPercentualCadastro(vm.pessoa.pessoaId);
            };

        }

        function buscarPercentualCadastro(id) {
            console.log("id ", id);
            Pessoa.getPercenutalConcluido({id: id}, findByPercentualCadastroSuccess);
        }

        function findByPercentualCadastroSuccess(percentualCadastro) {
            console.log('Percentual concluido', percentualCadastro);
            vm.percentualCadastro = percentualCadastro;
        }

        function save() {
            vm.isSaving = true;
            Pessoa.updatePerfil(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
            vm.isSaving = false;
            vm.isEditing = false;
            $state.reload();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.$on('mySkillsPersonalApp:mudaFoto', function (event, resp) {
            console.log("Chegou aqui no perfil!!!!", resp)
        });
    }
})();
