(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('solicitao-saque', {
                parent: 'home',
                url: '/solitacao-saque?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Saques'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/solicitacoes-saque.html',
                        controller: 'SolicitacaoSaqueController',
                        controllerAs: 'vm'
                    },
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('comprar-saldo', {
                parent: 'home',
                url: '/comprar-saldo/{id}',
                data: {
                    pageTitle: 'Compra de Saldo'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/comprar-saldo.html',
                        controller: 'ComprarSaldoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'PlanoCredito', function ($stateParams, PlanoCredito) {
                        return PlanoCredito.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('painel-financeiro', {
                parent: 'home',
                url: '/painel-financeiro',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Painel'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/painel-financeiro.html',
                        controller: 'PainelFinannceiroController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('situacaoPagamento');
                        return $translate.refresh();
                    }]
                }
            })
            .state('venda', {
                parent: 'home',
                url: '/venda?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Vendas'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/vendas.html',
                        controller: 'VendaController',
                        controllerAs: 'vm'
                    },
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('situacaoPagamento');
                        return $translate.refresh();
                    }]
                }
            })
            .state('venda-detail', {
                parent: 'home',
                url: '/venda/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.venda.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/venda-detail.html',
                        controller: 'VendaDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        $translatePartialLoader.addPart('situacaoPagamento');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Venda', function ($stateParams, Venda) {
                        return Venda.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'venda',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('venda-publico', {
                parent: 'home',
                url: '/venda-my-skills/{id}',
                data: {
                    authorities: [],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/venda/venda-publico.html',
                        controller: 'VendaPublicoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unidadeFederativa');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Venda', function ($stateParams, Venda) {
                        return Venda.getPublico({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'venda',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('venda-detail.edit', {
                parent: 'venda-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/venda/venda-dialog.html',
                        controller: 'VendaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Venda', function (Venda) {
                                return Venda.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('venda.new', {
                parent: 'venda',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/venda/venda-dialog.html',
                        controller: 'VendaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    nome: null,
                                    uf: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('venda', null, {reload: 'venda'});
                    }, function () {
                        $state.go('venda');
                    });
                }]
            })
            .state('venda.edit', {
                parent: 'venda',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/venda/venda-dialog.html',
                        controller: 'VendaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Venda', function (Venda) {
                                return Venda.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('venda', null, {reload: 'venda'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('venda.delete', {
                parent: 'venda',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/venda/venda-delete-dialog.html',
                        controller: 'VendaDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Venda', function (Venda) {
                                return Venda.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('venda', null, {reload: 'venda'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
