(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('CursoDetailController', CursoDetailController);

    CursoDetailController.$inject = ['$scope', '$timeout','$rootScope', '$stateParams', 'previousState', 'entity', 'Curso', '$sce', 'Principal', 'Pessoa', 'ConfirmService', '$uibModal', '$state', 'participarDireto'];

    function CursoDetailController($scope, $timeout, $rootScope, $stateParams, previousState, entity, Curso, $sce, Principal, Pessoa, ConfirmService, $uibModal, $state, participarDireto) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.curso = entity;
        vm.logar = logar;
        vm.trustedHtml = trustedHtml;
        vm.removerParticipacao = removerParticipacao;
        vm.participar = participar;
        vm.previousState = previousState.name;

        function verificarParticipacao() {
            Curso.participando({id: vm.curso.id}, function (data) {
                vm.curso.participando = data && data.id;
                if (!vm.curso.participando && participarDireto) {
                    participar();
                }
            });
        }

        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated();
            vm.account = account;
            if (vm.account) {
                Pessoa.getPerfilByUser({}, function (pessoa) {
                    vm.pessoa = pessoa;
                    verificarParticipacao();
                });
            }
        });

        function logar() {
            ConfirmService.swal({
                    title: "Você já tem um Perfil Cadastrado?",
                    text: "Para participar de um Curso é necessário ter um Perfil!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Cadastrar AGORA",
                    cancelButtonText: "Já tenho cadastro",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        abrirRegistro();
                        ConfirmService.close();
                    } else {
                        abrirLogin();
                        ConfirmService.close();
                    }
                });
        }


        function abrirLogin() {
            $uibModal.open({
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    previousState: function () {
                        return {};
                    }, pagingParams: function () {
                        return {};
                    },
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function () {
                $state.go("curso-detail", {"id": vm.curso.id, "participar": true});
            }, function () {

            });
        }

        function abrirRegistro() {
            $uibModal.open({
                templateUrl: 'app/account/register/register.html',
                controller: 'RegisterController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    pagingParams: function () {
                        return {};
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                $state.go("curso-detail", {"id": vm.curso.id, "participar": true});
            }, function () {

            });
        }


        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:cursoUpdate', function (event, result) {
            vm.curso = result;
        });

        $scope.$on('$destroy', unsubscribe);


        function participar() {
            ConfirmService.confirm({
                    title: "Confirme a Operação!",
                    text: "Ao continuar você estará se inscrevendo nesse curso.",
                    confirm: function () {

                        Curso.participar({curso: vm.curso, user: {id: vm.pessoa.userId}}, function (data) {
                            ConfirmService.swal({
                                title: "Pronto!",
                                text: "O seu registro foi feito com sucesso!"
                            }, verificarParticipacao);
                        });
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function removerParticipacao() {
            ConfirmService.confirm({
                    title: "Confirme a Operação!",
                    text: "Ao continuar você deixará de participar desse curso.",
                    confirm: function () {
                        Curso.removerParticipacao({id: vm.curso.id}, function () {
                            ConfirmService.swal({
                                title: "Pronto!",
                                text: "O seu registro foi removido com sucesso!"
                            }, verificarParticipacao);
                        });
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

    }
})();
