(function() {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('RedeSocialDeleteController',RedeSocialDeleteController);

    RedeSocialDeleteController.$inject = ['$uibModalInstance', 'entity', 'RedeSocial'];

    function RedeSocialDeleteController($uibModalInstance, entity, RedeSocial) {
        var vm = this;

        vm.redeSocial = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RedeSocial.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
