(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VincularVagaDialogController', VincularVagaDialogController);

    VincularVagaDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Empregador', 'Vaga', 'ConfirmService', 'AtributoGenerico', '$uibModal'];

    function VincularVagaDialogController($timeout, $scope, $state, $stateParams, entity, Empregador, Vaga, ConfirmService, AtributoGenerico, $uibModal) {
        var vm = this;

        vm.empregador = entity;
        vm.adicionar = adicionar;
        vm.carregarVagas = carregarVagas;
        vm.selecionarFuncao = selecionarFuncao;
        vm.selecionarSetor = selecionarSetor;
        vm.removerFuncao = removerFuncao;
        vm.removerSetor = removerSetor;
        vm.filtroFuncao = "";
        vm.filtroSetor = "";
        vm.filtroGeral = "";
        vm.funcoesSelecionadas = [];
        vm.setoresSelecionados = [];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function carregarVagas() {
            vm.carregando = true;
            Vaga.getAllSismples({
                funcoes: vm.funcoesSelecionadas,
                setores: vm.setoresSelecionados,
                filtro: vm.filtroGeral,
            }, function (data) {
                vm.vagas = data;
                vm.carregando = false;
            });
        }

        carregarVagas();

        function carregarFuncoes() {
            Vaga.getFuncoes({parameter: vm.filtroFuncao}, function (data) {
                vm.funcoes = data;
            });
        }

        carregarFuncoes();

        function selecionarFuncao(index) {
            vm.funcoesSelecionadas.push(vm.funcoes[index]);
            vm.funcoes.splice(index, 1);
            carregarVagas();
        }

        function removerFuncao(index) {
            vm.funcoes.push(vm.funcoesSelecionadas[index]);
            vm.funcoesSelecionadas.splice(index, 1);
            carregarVagas();
        }

        function selecionarSetor(index) {
            vm.setoresSelecionados.push(vm.setores[index]);
            vm.setores.splice(index, 1);
            carregarVagas();
        }

        function removerSetor(index) {
            vm.setores.push(vm.setoresSelecionados[index]);
            vm.setoresSelecionados.splice(index, 1);
            carregarVagas();
        }

        function carregarSetores() {
            Vaga.getSetores({parameter: vm.filtroSetor}, function (data) {
                vm.setores = data;
            });
        }

        carregarSetores();


        function adicionar(vaga) {

            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'detalhesVaga.html',
                controller: 'VagaDoEmpregadorController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
            });
            modalInstance.result.then(function (atributos) {
                Empregador.addVagasEmpresa({idEmpregador: vm.empregador.id, idVaga: vaga.id}, function (data) {
                        if (data.id) {
                            var agrupador = {vinculo: data.id, tipo: 'VAGA', atributos: []};
                            for (var i = 0; i < atributos.length; i++) {
                                var atributo = atributos[i];
                                agrupador.atributos.push({
                                    atributo: atributo,
                                    tipoVinculo: agrupador.tipo,
                                    vinculo: agrupador.vinculo,
                                    valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                                });

                            }
                            AtributoGenerico.saveVinculos(agrupador);
                        }

                        $state.go("empregador");
                    },
                    function (error) {
                        ConfirmService.swal({
                            title: "Operação Não Realizada",
                            text: "Essa vaga parece já estar vinculada",
                            timer: 2000,
                            type: "error",
                            showConfirmButton: false
                        });
                    });
            });


        }

    };


    angular
        .module('mySkillsPersonalApp')
        .controller('VagaDoEmpregadorController', VagaDoEmpregadorController);
    VagaDoEmpregadorController.$inject = ['$uibModalInstance'];

    function VagaDoEmpregadorController($uibModalInstance) {
        var vm = this;
        vm.atributos = [];

        vm.ok = function () {
            $uibModalInstance.close(vm.atributos);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };
})();
