(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ComunicarPessoaController', ComunicarPessoaController);

    ComunicarPessoaController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'pagingParams', 'Empregador', 'ParseLinks', 'ConfirmService',
        'AtributoGenerico', 'FormacaoAcademica', 'TipoStatus', '$uibModal', 'Pessoa', '$location', 'Principal', 'ValorLoteEnvioMensagemUsuario'];

    function ComunicarPessoaController($timeout, $scope, $state, $stateParams, pagingParams, Empregador, ParseLinks, ConfirmService,
        AtributoGenerico, FormacaoAcademica, TipoStatus, $uibModal, Pessoa, $location, Principal, ValorLoteEnvioMensagemUsuario) {
        var vm = this;
        vm.page = 1;
        vm.allItemsPerPage = [10, 25, 50, 100, 250, 500];
        vm.itemsPerPage = vm.allItemsPerPage[0];

        vm.pessoasSelecionadas = [];

        vm.carregarPessoas = carregarPessoas;
        vm.limparFiltros = limparFiltros;
        vm.adicionarOrdem = adicionarOrdem;
        vm.limparOrdem = limparOrdem;
        vm.enviarMensagem = enviarMensagem;
        vm.verAlcance = verAlcance;

        vm.imprimir = imprimir;

        vm.getCompetenciasAvaliadas = carregarCompetenciasAvaliadas;
        vm.adicionarRemoverCompetencia = adicionarRemoverCompetencia;
        vm.adicionarRemoverTodasCompetencia = adicionarRemoverTodasCompetencia;
        vm.getValorCompetenciaPessoa = getValorCompetenciaPessoa;
        vm.filtrarCompetencias = pagingParams.filtrarCompetencias && pagingParams.filtrarCompetencias === 'true';

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        function imprimir(pessoa) {
            Pessoa.imprimirSimples(pessoa.pessoaId);
        }

        vm.ordens = [
            {descricao: 'Nome', value: 'nome'},
            {
                descricao: 'Cidade',
                value: 'premium'
            },
            {descricao: 'Ultima Alteracao', value: '-atualizadoEm'},
            {descricao: 'Disponibilidade', value: 'situacao'},
            {descricao: 'Idade', value: '-nascimento'}];

        vm.order = ['premium', '-atualizadoEm'];

        function adicionarOrdem(ordem) {
            if (vm.order.indexOf(ordem.value) >= 0) {
                vm.order.splice(vm.order.indexOf(ordem.value), 1);
            } else {
                vm.order.push(ordem.value);
            }
        }

        function getValorCompetenciaPessoa(pessoa, competencia) {
            for (var i = 0; i < pessoa.resultadoCompetencias.length; i++) {
                var comp = pessoa.resultadoCompetencias[i];
                if (comp.id == competencia) {
                    return comp;
                }
            }
            return {};
        }

        function adicionarRemoverTodasCompetencia(agrupador) {
            agrupador.selecionada = !agrupador.selecionada;

            for (var i = 0; i < agrupador.competencias.length; i++) {
                var compentenciasAvaliada = agrupador.competencias[i];
                adicionarRemoverCompetencia(compentenciasAvaliada);
            }
            carregarPessoas();
        }

        function adicionarRemoverCompetencia(competencia) {
            competencia.selecionada = !competencia.selecionada;
            if (vm.filtros.competencias.indexOf(competencia.id) >= 0) {
                vm.filtros.competencias.splice(vm.filtros.competencias.indexOf(competencia.id), 1);
            } else {
                vm.filtros.competencias.push(competencia.id);
            }
        }

        function limparOrdem() {
            vm.order = [];
        }

        function recuperaValoresDosEnvios() {
            ValorLoteEnvioMensagemUsuario.query({}, function (data) {
                vm.valoresEnvio = data;
                for (var i = 0; i < data.length; i++) {
                    var valorEnvio = data[i];
                    if (valorEnvio.tipoEnvio == 'WHATSAPP') {
                        vm.valorWhats = valorEnvio;
                    }
                    if (valorEnvio.tipoEnvio == 'EMAIL') {
                        vm.valorEmail = valorEnvio;
                    }
                }
            });
        }

        function limparFiltros() {
            vm.filtros = {
                apenasInteressados: pagingParams.apenasInteressados,
                apenasComprados: pagingParams.apenasComprados,
                empregadorId: pagingParams.empregadorId,
                cursoId: pagingParams.cursoId,
                situacao: null,
                competencias: []
            };
        }

        function carregarCompetenciasAvaliadas() {
            Pessoa.getCompetenciasAvaliadas({}, function (data) {
                vm.compentenciasAvaliadas = data;
            });
        }

        function carregarPessoas() {
            vm.carregando = true;
            vm.filtros.classificacao = null;
            vm.pessoasSelecionadas = [];
            Pessoa.contarPessoas(vm.filtros, function (data) {
                vm.totalPessoas = data.quantidade;
                vm.carregando = false;
                ConfirmService.swal({
                        title: "Consulta Realizada",
                        text: vm.totalPessoas > 0 ? (vm.totalPessoas + " Pessoas se enquadram nos filtros") : "Nenhuma Pessoa foi encontrar com os filtros informados",
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        showCancelButton: false,
                    }
                );
                if (vm.valorEmail) {
                    vm.valorEnvioEmail = Math.ceil((vm.totalPessoas / vm.valorEmail.quantidade) * vm.valorEmail.valor);
                }
                if (vm.valorWhats) {
                    vm.valorEnvioWhats = Math.ceil((vm.totalPessoas / vm.valorWhats.quantidade) * vm.valorWhats.valor);
                }

            }, function (err) {
                vm.carregando = false;
            });

        }

        function buscarSaldo() {
            Empregador.getSaldo({}, function (data) {
                vm.saldo = data.saldo;
            });
        }

        function buscarPlanoPagamentoPremium() {
            vm.temPlanoPagamentoPremium = false;
            Empregador.temPlanoPagamentoPremium({idEmpregador: pagingParams.empregadorId}, function (data) {
                vm.temPlanoPagamentoPremium = data.qntPlanoPagamentoPremium;
            });
        }


        function enviarMensagem(tipo) {
            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'comunicar.dialog.html',
                controller: 'ComunicarPessoasController',
                controllerAs: 'vm',
                resolve: {
                    filtros: vm.filtros,
                    tipo: function () {
                        return tipo;
                    },
                    valor: function () {
                        return tipo == 'EMAIL' ? vm.valorEmail : vm.valorWhats;
                    }
                }
            }).result.then(function (value) {
                ConfirmService.swal({
                        title: "Transmissão sendo enviada!",
                        text: "Consulte o andamento da aba de transmissões",
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        showCancelButton: false,
                    }, function () {
                        Principal.hasAuthority("ROLE_ADMIN")
                            .then(function (result) {
                                if (result) {
                                    $state.go('empregador-edit', {id: pagingParams.empregadorId});
                                } else {
                                    $state.go('empregador');
                                }
                            });
                    }
                );
            }, function () {

            });
        }

        function verAlcance() {
            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'pessoas.dialog.html',
                controller: 'VisualiarAlcancePessoasPessoasController',
                controllerAs: 'vm',
                resolve: {
                    filtros: vm.filtros,
                    totalItens: function () {
                        return vm.totalPessoas;
                    }
                }
            }).result.then(function (value) {

            }, function () {

            });
        }

        buscarSaldo();
        buscarPlanoPagamentoPremium();


        function loadStatus(callback) {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
                callback();
            });
        }

        function loadFormacao() {
            FormacaoAcademica.getTipoFormacao({}, function (data) {
                vm.tiposFormacao = data;
            });
        }

        loadStatus(carregarTudo);

        function carregarTudo() {
            loadFormacao();
            limparFiltros();
            recuperaValoresDosEnvios();
            if (vm.filtrarCompetencias) {
                carregarCompetenciasAvaliadas();// aqui ja carrega as pessoas filtrando
            } else {
                // carregarPessoas();
            }
        }
    }


    angular
        .module('mySkillsPersonalApp')
        .controller('ComunicarPessoasController', ComunicarPessoasController);

    ComunicarPessoasController.$inject = ['$uibModalInstance', 'filtros', 'Pessoa', 'tipo', 'valor'];

    function ComunicarPessoasController($uibModalInstance, filtros, Pessoa, tipo, valor) {
        var vm = this;
        vm.filtros = filtros;
        vm.valor = valor;
        vm.tipo = tipo;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.salvar = function () {
            if (tipo == 'EMAIL') {
                Pessoa.enviarMensagemPessoasEmail(vm.filtros, function (data) {
                    $uibModalInstance.close();
                });
            } else {
                Pessoa.enviarMensagemPessoasWhats(vm.filtros, function (data) {
                    $uibModalInstance.close();
                });
            }
        };

    }


    angular
        .module('mySkillsPersonalApp')
        .controller('VisualiarAlcancePessoasPessoasController', VisualiarAlcancePessoasPessoasController);

    VisualiarAlcancePessoasPessoasController.$inject = ['$uibModalInstance', 'filtros', 'totalItens', 'Pessoa'];

    function VisualiarAlcancePessoasPessoasController($uibModalInstance, filtros, totalItens, Pessoa) {
        var vm = this;
        vm.filtros = filtros;
        vm.page = 1;
        vm.filtros.page = 0;
        vm.filtros.pageSize = 20;
        vm.totalItens = totalItens;
        vm.loadMore = loadMore;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function loadMore() {
            vm.filtros.page = vm.page + 1;
            carregar();
        }

        function carregar() {
            vm.carregando = true;
            Pessoa.getPessoasFiltroSimples(vm.filtros, onSuccess, onError);
        }

        function onError() {
            vm.carregando = false;
        }

        function onSuccess(data, headers) {
            vm.pessoas = data;
            vm.carregando = false;
        }


        carregar();

    }
})();
