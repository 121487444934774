(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'getAllUsersByParceiro': {
                url: 'api/users-by-parceiro',
                method: 'GET',
                isArray: true
            },
            'redefineSenhaIgualLogin': {
                url: 'api/redefine-senha-igual-login/:login',
                method: 'GET'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'deleteByUser': {url: 'api/users-delete/:login', method: 'DELETE'}
        });

        return service;
    }
})();
