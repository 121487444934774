(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('FilialEmpregadorController', FilialEmpregadorController);

    FilialEmpregadorController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Endereco', 'BuscaCep'];

    function FilialEmpregadorController($timeout, $scope, $stateParams, $uibModalInstance, entity, Endereco, BuscaCep) {
        var vm = this;

        vm.endereco = entity;
        vm.clear = clear;
        vm.save = save;
        vm.buscaCep = buscaCep;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            $uibModalInstance.close(vm.endereco);
            vm.isSaving = false;
        }

        function onBuscaCepError() {
            vm.isSaving = false;
        }

        function onBuscaCepSuccess(result) {
            vm.endereco.logradouro = result.logradouro;
            vm.endereco.bairro = result.bairro;
            vm.endereco.localidade = result.localidade;
            vm.endereco.uf = result.uf;
            
            vm.isSaving = false;
        }

        function buscaCep() {
            vm.isSaving = true;
            if (vm.endereco.cep && vm.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.endereco.cep}, onBuscaCepSuccess, onBuscaCepError);
            }
        }

    }
})();
