(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('AudioDetailController', AudioDetailController);

    AudioDetailController.$inject = ['$scope', '$q', '$state',
        '$window', '$uibModalInstance', '$timeout', 'audio'];

    function AudioDetailController($scope, $q, $state, $window, $uibModalInstance, $timeout, audio) {
        var vm = this;
        vm.clear = clear;

        $timeout(function () {
            vm.audio = document.getElementById('audio');
            vm.audio.muted = false;
            vm.audio.volume = 1;
            vm.audio.src = audio;
        });

        function clear() {
            $uibModalInstance.dismiss('close');
        }
    }
})();
