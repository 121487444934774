(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilDialogInteressesController', PerfilDialogInteressesController);

    PerfilDialogInteressesController.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Pessoa', 'TipoStatus', '$uibModalInstance'];

    function PerfilDialogInteressesController($rootScope, $scope, Principal, $state, Pessoa, TipoStatus, $uibModalInstance) {

        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.save = save;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.arquivo = {};
        vm.verificarDisponibilidadeTrabalho = verificarDisponibilidadeTrabalho;
        vm.verificarCondicoesDeTrabalho = verificarCondicoesDeTrabalho;
        vm.verificarDisponibilidadeTempoForaDeCasa = verificarDisponibilidadeTempoForaDeCasa;
        vm.verificarDisponibilidadeTurnoTrabalho = verificarDisponibilidadeTurnoTrabalho;
        vm.verificarDisponibilidadeEscalaRevezamento = verificarDisponibilidadeEscalaRevezamento;
        vm.verificarPreferenciaParaTrabalhar = verificarPreferenciaParaTrabalhar;
        vm.verificarFormatoDeVinculo = verificarFormatoDeVinculo;
        vm.verificarPreferenciaCondicoesDeTrabalho = verificarPreferenciaCondicoesDeTrabalho;
        vm.verificarTrabalharEmOutrosPaises = verificarTrabalharEmOutrosPaises;
        vm.verificarTrabalharEmOutrosEstados = verificarTrabalharEmOutrosEstados;
        vm.verificarTrabalharEmOutrasCidades = verificarTrabalharEmOutrasCidades;
        vm.verificarTrabalharMudancaDeCidade = verificarTrabalharMudancaDeCidade;
        vm.verificarTrabalharMorarEmAlojamento = verificarTrabalharMorarEmAlojamento;
        vm.verificarTrabalharAlemDoHorario = verificarTrabalharAlemDoHorario;
        vm.verificarPossuiVeiculo = verificarPossuiVeiculo;
        vm.verificarVeiculoUsarParaTrabalhar = verificarVeiculoUsarParaTrabalhar;
        vm.verificarPossuiMoto = verificarPossuiMoto;
        vm.verificarMotoUsarParaTrabalhar = verificarMotoUsarParaTrabalhar;
        vm.verificarAreasParaTrabalho = verificarAreasParaTrabalho;
        vm.verificarEsforcoFisico = verificarEsforcoFisico;

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });


        getAccount();
        loadStatus();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
            };

        }

        function loadStatus() {
            TipoStatus.query(function (result) {
                vm.tiposStatus = result;
            });
        }


        function save() {
            vm.isSaving = true;
            Pessoa.updatePerfil(vm.pessoa, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.isEditing = false;
            $uibModalInstance.close(result);
            $scope.$emit('mySkillsPersonalApp:pessoaUpdate', result);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.nascimento = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function verificarDisponibilidadeTrabalho(disponibilidadeTrabalho, value) {
            if (disponibilidadeTrabalho && String(disponibilidadeTrabalho) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarCondicoesDeTrabalho(condicoesDeTrabalho, value) {
            if (condicoesDeTrabalho && String(condicoesDeTrabalho) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarDisponibilidadeTempoForaDeCasa(disponibilidadeTempoForaDeCasa, value) {
            if (disponibilidadeTempoForaDeCasa && String(disponibilidadeTempoForaDeCasa) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarDisponibilidadeTurnoTrabalho(disponibilidadeTurnoTrabalho, value) {
            if (disponibilidadeTurnoTrabalho && String(disponibilidadeTurnoTrabalho) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarDisponibilidadeEscalaRevezamento(disponibilidadeEscalaRevezamento, value) {
            if (disponibilidadeEscalaRevezamento && String(disponibilidadeEscalaRevezamento) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarPreferenciaParaTrabalhar(preferenciaParaTrabalhar, value) {
            if (preferenciaParaTrabalhar && String(preferenciaParaTrabalhar) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarFormatoDeVinculo(formatoDeVinculo, value) {
            if (formatoDeVinculo && String(formatoDeVinculo) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarPreferenciaCondicoesDeTrabalho(preferenciaCondicoesDeTrabalho, value) {
            if (preferenciaCondicoesDeTrabalho && String(preferenciaCondicoesDeTrabalho) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharEmOutrosPaises(trabalharEmOutrosPaises, value) {
            if (trabalharEmOutrosPaises && String(trabalharEmOutrosPaises) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharEmOutrosEstados(trabalharEmOutrosEstados, value) {
            if (trabalharEmOutrosEstados && String(trabalharEmOutrosEstados) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharEmOutrasCidades(trabalharEmOutrasCidades, value) {
            if (trabalharEmOutrasCidades && String(trabalharEmOutrasCidades) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharMudancaDeCidade(trabalharMudancaDeCidade, value) {
            if (trabalharMudancaDeCidade && String(trabalharMudancaDeCidade) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharMorarEmAlojamento(trabalharMorarEmAlojamento, value) {
            if (trabalharMorarEmAlojamento && String(trabalharMorarEmAlojamento) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarTrabalharAlemDoHorario(trabalharAlemDoHorario, value) {
            if (trabalharAlemDoHorario && String(trabalharAlemDoHorario) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarPossuiVeiculo(possuiVeiculo, value) {
            if (possuiVeiculo && String(possuiVeiculo) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarVeiculoUsarParaTrabalhar(veiculoUsarParaTrabalhar, value) {
            if (veiculoUsarParaTrabalhar && String(veiculoUsarParaTrabalhar) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarPossuiMoto(possuiMoto, value) {
            if (possuiMoto && String(possuiMoto) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarMotoUsarParaTrabalhar(motoUsarParaTrabalhar, value) {
            if (motoUsarParaTrabalhar && String(motoUsarParaTrabalhar) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarAreasParaTrabalho(areasParaTrabalho, value) {
            if (areasParaTrabalho && String(areasParaTrabalho) === String(value)) {
                return true;
            }
            return false;
        }

        function verificarEsforcoFisico(esforcoFisico, value) {
            if (esforcoFisico && String(esforcoFisico) === String(value)) {
                return true;
            }
            return false;
        }

    }
})();
