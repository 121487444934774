(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroDialogController', ParceiroDialogController);

    ParceiroDialogController.$inject = ['$timeout', '$scope',
        '$stateParams', '$state', 'entity', 'Parceiro',
        '$uibModal', 'previousState', 'BuscaCep', '$http', 'Pessoa', 'ConfirmService'];

    function ParceiroDialogController($timeout, $scope,
        $stateParams, $state, entity, Parceiro,
        $uibModal, previousState, BuscaCep, $http, Pessoa, ConfirmService) {
        var vm = this;

        vm.parceiro = entity;
        vm.clear = clear;
        vm.save = save;
        vm.buscaCep = buscaCep;
        vm.openFileUpload = openFileUpload;
        vm.getPessoas = getPessoas;
        vm.selectPessoa = selectPessoa;
        vm.adicionarUsuario = adicionarUsuario;
        vm.removerUser = removerUser;
        vm.calbackImagemPrincipal = calbackImagemPrincipal;
        vm.calbackImagemHeader = calbackImagemHeader;
        vm.calbackImagemFooter = calbackImagemFooter;
        vm.pessoaSelecionada;
        vm.usuarioSelecionado;
        vm.users = [];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            if (previousState) {
                $state.go(previousState.name, previousState.params, {reload: true});
            } else {
                $state.go('parceiro', {reload: true});
            }

        }

        function buscaCep() {
            vm.isSaving = true;

            if (vm.parceiro && vm.parceiro.endereco && vm.parceiro.endereco.cep && vm.parceiro.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.parceiro.endereco.cep}, onBuscaCepSuccess, onBuscaCepError);
            } else {
                vm.isSaving = false;
            }
        }

        function onBuscaCepError() {
            vm.isSaving = false;
        }


        function onBuscaCepSuccess(result) {
            vm.parceiro.endereco.logradouro = result.logradouro;
            vm.parceiro.endereco.bairro = result.bairro;
            vm.parceiro.endereco.localidade = result.localidade;
            vm.parceiro.endereco.uf = result.uf;

            vm.isSaving = false;
        }

        function instanciaParceiro() {
            if (!vm.parceiro) {
                vm.parceiro = {};
            }
        }

        function calbackImagemPrincipal(result) {
            instanciaParceiro();
            if (!vm.parceiro.arquivo) {
                vm.parceiro.arquivo = {};
            }
            vm.parceiro.arquivo.bytes = result.data.conteudoFormatado;
        }

        function calbackImagemHeader(result) {
            instanciaParceiro();
            if (!vm.parceiro.logoHeader) {
                vm.parceiro.logoHeader = {};
            }
            vm.parceiro.logoHeader.bytes = result.data.conteudoFormatado;
        }

        function calbackImagemFooter(result) {
            instanciaParceiro();
            if (!vm.parceiro.logoFooter) {
                vm.parceiro.logoFooter = {};
            }
            vm.parceiro.logoFooter.bytes = result.data.conteudoFormatado;
        }

        function openFileUpload(calback, cropSize) {
            $uibModal.open({
                templateUrl: 'app/account/registro-parceiro/foto-registro-edit.html',
                controller: 'FotoRegistroController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    cropSize: function() {
                        return cropSize;
                    }
                },
            }).result.then(function (result) {
                console.log("RESULTADO: ", result);
                calback(result);
            }, function () {
                console.log("VORTOO >>>");
            });
        }

        function save() {
            vm.isSaving = true;
            if (vm.parceiro.id !== null) {
                Parceiro.update(vm.parceiro, onSaveSuccess, onSaveError);
            } else {
                Parceiro.save(vm.parceiro, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:parceiroUpdate', result);
            vm.isSaving = false;
            clear();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getPessoas(param) {
            return $http.get('api/_search/pessoas/', {
                params: {
                    query: param,
                    page: 0,
                    size: 10,
                }
            }).then(function (response) {
                return response.data.map(function (pessoa) {
                    return pessoa;
                });
            });
        }

        function selectPessoa($item, $model, $label, $event) {
            if ($model.id) {
                console.log('user selecionado : ', $model);
                Pessoa.getUserByPessoaId({id: $model.id}, function (response) {
                    console.log("response: ", response);
                    var userAdmin = {};
                    userAdmin.idParceiro = vm.parceiro.id;
                    userAdmin.idUsuario = response.id;
                    userAdmin.usuario = response.firstName;
                    userAdmin.email = response.email;

                    vm.usuarioSelecionado = userAdmin;

                }, function (error) {
                    console.log("erro:", error);
                });
                //$state.go("pessoa-detail", {id: $model.id});
            }
        }

        function removerUser(user, index) {
            Parceiro.removerParceiroAdministrador(user, function (data) {
                vm.users.splice(index, 1);
            }, function (error) {
                console.log("erro: ", error);
                if (error.status === 409) {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "O Parceiro não pode ficar sem administrador.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );

                } else {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "Erro ao remover o usuário do parceiro.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                }
            });
        }

        function adicionarUsuario(usuario) {

            Parceiro.adicionarParceiroAdministrador(usuario, function (data) {
                vm.users.push(usuario);
                vm.pessoaSelecionada = null;
            }, function (error) {
                console.log("erro: ", error);
                if (error.status === 409) {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "O usuário " + usuario.usuario + " já é administrador de outro parceiro.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                } else {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "Erro ao vincular o usuário " + usuario.usuario + " como administrador do parceiro.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                }
            });
        }

        function carregarUsuarios() {
            Parceiro.getAdiministradoresParceiro({id: vm.parceiro.id}, function (data) {
                vm.users = data;
            });
        }

        carregarUsuarios();


    }
})();
