(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .factory('ConfirmService', ConfirmService);


    ConfirmService.$inject = ['$uibModal'];

    function ConfirmService($uibModal) {


        return {
            confirm: confirm,
            swal: swal,
            confirmInput: confirmInput,
            close: close,
        };

        function close() {

        }

        function swal(message, finish) {
            $uibModal.open({
                templateUrl: 'app/components/alert/confirm.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                size: 'md',
                resolve: {
                    message: function () {
                        return {
                            title: message.title,
                            text: message.text,
                            confirmText: message.confirmButtonText,
                            cancelText: message.cancelButtonText
                        };
                    }
                }
            }).result.then(function () {
                finish(true);
            }, function () {
                finish(false);
            });
        }

        function confirm(message) {
            $uibModal.open({
                templateUrl: 'app/components/alert/confirm.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                size: 'md',
                resolve: {
                    message: function () {
                        return {
                            title: message.title,
                            text: message.text,
                            confirmText: message.confirmText,
                            cancelText: message.cancelText
                        };
                    }
                }
            }).result.then(function () {
                message.confirm();
            }, function () {
                message.cancel();
            });
        }


        function confirmInput(message) {
            $uibModal.open({
                templateUrl: 'app/components/alert/confirm.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                size: 'md',
                resolve: {
                    message: function () {
                        return {
                            confirmInput: true,
                            title: message.title,
                            text: message.text,
                            confirmText: message.confirmText,
                            cancelText: message.cancelText
                        };
                    }
                }
            }).result.then(function (result) {
                message.confirm(result);
            }, function () {
                message.cancel();
            });
        }
    }
})();
